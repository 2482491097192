/* CSS Document */

/* 
Created on   : 01/09/2021.
Theme Name   : Deski-Saas & Software React Template
Version      : 1.0.
Developed by : (ib-themes21@gmail.com) / (https://themeforest.net/user/ib-themes)
Primary use:  ib-themes
   
*/
/*   01 - Loading Transition  */
/*   02 - Global style  */
/*   03 - Prefix  */
/*   04 - Section Title */
/*   05 - Theme Button */
/*   06 - Pagination  */
/*   07 - Theme Menu  */
/*   08 - Theme Hero Banner/One  */
/*   09 - Fancy Feature One  */
/*   10 - TFancy Feature Two  */
/*   11 - Useable Tools  */
/*   12 - Fancy Feature Three  */
/*   13 - Fancy Short Banner One  */
/*   14 - Faq Classic   */
/*   15 - Footer One  */
/*   16 - Fancy Hero One  */
/*   17 - Fancy Text block One  */
/*   18 - Counter Info Standard  */
/*   19 - Fancy Text block Two   */
/*   20 - Fancy Text block Three */
/*   21 - Team Section One */
/*   22 - Feature Blog One */
/*   23 - Fancy Text block Four  */
/*   24 - Fancy Text block Five  */
/*   25 - Feature Blog Two  */
/*   26 - Contact Us Light */
/*   27 - Theme Hero Banner/Two  */
/*   28 - Fancy Feature Two   */
/*   29 - Fancy Text block Six   */
/*   30 - Counter With Icon One   */
/*   31 - Fancy Text block Seven   */
/*   32 - Fancy Text block Eight   */
/*   33 - Useable Tools Two   */
/*   34 - Client Feedback Slider One   */
/*   35 - Fancy Short Banner Three   */
/*   36 - Footer Style Two   */
/*   37 - Fancy Hero Two   */
/*   38 - Fancy Text block Nine  */
/*   39 - Fancy Text block Ten   */
/*   40 - Fancy Feature Five   */
/*   41 - Team Section Two   */
/*   42 - Fancy Hero Three   */
/*   43 - Fancy Text block Eleven   */
/*   44 - Fancy Feature Six   */
/*   45 - Fancy Text block Twelve   */
/*   46 - Fancy Feature Seven   */
/*   47 - Fancy Text Block Thirteen   */
/*   48 - Fancy Text Block Fourteen   */
/*   49 - Pricing Section One   */
/*   50 - Fancy Text Block Fifteen   */
/*   51 - Fancy Hero Four   */
/*   52 - Pricing Section Two   */
/*   53 - FAQ Section   */
/*   54 - Contact Style Two   */
/*   55 - Faqs   */
/*   56 - Login/Sign Up   */
/*   57 - Blog Pages  */
/*   58 - Theme Hero Banner/Three  */
/*   59 - Fancy Feature Eight   */
/*   60 - Fancy Text block Sixteen   */
/*   61 - Fancy Feature Eight   */
/*   62 - Client Feedback Slider Two   */
/*   63 - Useable Tools / Three  */
/*   64 - Fancy Short Banner Four   */
/*   65 - Footer Style Three   */
/*   66 - Fancy Hero Five   */
/*   67 - Fancy Text block Seventeen   */
/*   68 - Fancy Text block Eighteen   */
/*   69 - Team Section Three  */
/*   70 - Map Area One    */
/*   71 - Documentation  */
/*   72 - Theme Hero Banner/Four   */
/*   73 - Fancy Feature Eight   */
/*   74 - Fancy Text block Nineteen   */
/*   75 - Fancy Feature Nine   */
/*   76 - Pricing Section Three   */
/*   77 - Client Feedback Slider Three   */
/*   78 - Faq Section Three   */
/*   79 - Fancy Short Banner Five   */

/*========================================================================================

*************************** Start Styling Your theme from here ***************************

==========================================================================================*/
/*** 

====================================================================
  Loading Transition
====================================================================

 ***/
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #fff;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}
/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1.5s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(101, 92, 151, 0.06%);
  border-top-color: #655c97;
  border-bottom-color: #655c97;
  height: 170px;
  margin: 0 auto 45px auto;
  width: 170px;
}
/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #655c97;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 70px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  color: rgba(101, 92, 151, 0.14%);
  position: relative;
  font-size: 70px;
  line-height: 70px;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
/* Animación del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}
/*==================== Click Top ====================*/
.scroll-top {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: #000;
  transition: all 0.3s ease-in-out;
  img {
    margin: auto;
  }
}
.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
/*------------- Global Prefix -------------------*/
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: var(--blue-dark);
  border-radius: 0;
}
:root {
  --text-color: #73737b;
  --text-light: #888888;
  --heading: #101621;
  --blue-dark: #6f55ff;
  --red-light: #fd6a5e;
  --yellow-deep: #ffb840;
  --blue-light: #3bb0d7;
  --purple-blue: #655c97;
  --vin-red: #973c56;
  scroll-behavior: smooth;
}
::selection {
  background-color: var(--blue-dark);
  color: #fff;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  position: relative;
  line-height: 1.7em;
  color: var(--text-color);
}
/*______________________ Global style ___________________*/
.main-page-wrapper {
  overflow: hidden;
  padding-top: 150px;
}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  color: var(--heading);
  font-weight: normal;
}
.h1,
h1 {
  font-size: 80px;
  line-height: 1.1em;
}
.h2,
h2 {
  font-size: 52px;
  line-height: 1.1em;
}
.h3,
h3 {
  font-size: 36px;
}
.h4,
h4 {
  font-size: 22px;
}
.h5,
h5 {
  font-size: 20px;
}
.h6,
h6 {
  font-size: 15px;
}
.font-Roboto {
  font-family: "Roboto", sans-serif !important;
}
.font-Open Sans {
  font-family: "Open Sans", sans-serif !important;
}
.font-slab {
  font-family: "Roboto Slab", serif !important;
}
.font-gordita {
  font-family: "Roboto", sans-serif !important;
}
.font-recoleta {
  font-family: "Recoleta" !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #acaeb3;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #acaeb3;
}
::placeholder {
  color: #acaeb3;
}
.cs-screen {
  position: absolute;
  margin-top: -5px;
}
.illustration-holder {
  position: relative;
}
.shapes {
  position: absolute;
  z-index: -1;
}
.dark-style {
  background: #0f1123;
  color: #afafaf;
}
.dark-style .h1,
.dark-style h1,
.dark-style .h2,
.dark-style h2,
.dark-style .h3,
.dark-style h3,
.dark-style .h4,
.dark-style h4,
.dark-style .h5,
.dark-style h5,
.dark-style .h6,
.dark-style h6 {
  color: #fff;
}
.gr-bg-one {
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
}
.hover-reverse-gr-bg-one {
  position: relative;
  z-index: 1;
}
.hover-reverse-gr-bg-one:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(270.01deg, #f548a0 0.01%, #f57c35 100%);
  border-radius: 6px;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  z-index: -1;
}
.hover-reverse-gr-bg-one:hover:before {
  opacity: 1;
}
/*-------------- Prefix Classes ----------------*/
.tran3s {
  transition: all 0.3s ease-in-out;
}
.tran4s {
  transition: all 0.4s ease-in-out;
}
.tran5s {
  transition: all 0.5s ease-in-out;
}
.tran6s {
  transition: all 0.6s ease-in-out;
}
/*^^^^^^^^^^^^^^^^^^^^^ Section Title ^^^^^^^^^^^^^^^^^^^^^^^^^*/
.title-style-one h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 58px;
  line-height: 1.15em;
}
.title-style-one h6 {
  font-size: 20px;
  color: #bababa;
  padding-bottom: 15px;
}
.title-style-one p {
  font-size: 22px;
  line-height: 1.58em;
  color: #2a2a2a;
  padding-top: 25px;
}
.title-style-two p {
  text-transform: uppercase;
  font-size: 16px;
  color: #8a8a8a;
  letter-spacing: 1.12px;
  padding-bottom: 7px;
}
.title-style-two h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 58px;
  line-height: 1.15em;
}
.title-style-two span {
  position: relative;
}
.title-style-two span img {
  position: absolute;
  left: 0;
  z-index: -1;
}
.title-style-two .sub-text {
  font-size: 24px;
  line-height: 1.5em;
  color: #6d6d6d;
  padding-top: 15px;
}
.title-style-three p {
  text-transform: uppercase;
  font-size: 20px;
  color: #adb1b5;
  letter-spacing: 1.12px;
  padding-bottom: 17px;
}
.title-style-three h6 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #adb1b5;
  padding-bottom: 15px;
}
.title-style-three h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 54px;
  line-height: 1.18em;
}
.title-style-three span {
  position: relative;
}
.title-style-three span img {
  position: absolute;
  left: 0;
  z-index: -1;
}
.title-style-four h6 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.55);
  padding-bottom: 15px;
}
.title-style-four h2 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.15em;
  color: #2a2a2a;
}
.title-style-four span {
  position: relative;
  z-index: 1;
}
.title-style-four span img {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.title-style-five h2 {
  font-family: "Roboto Slab", serif;
  font-size: 54px;
  line-height: 1.25em;
}
.title-style-five h2 span {
  position: relative;
  z-index: 1;
}
.title-style-five h2 span:before {
  content: "";
  width: 100%;
  height: 15px;
  position: absolute;
  background: rgba(241, 193, 83, 0.45);
  left: 0;
  bottom: 12px;
  z-index: -1;
}
.title-style-five h6 {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.55);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 12px;
}
.title-style-six h2 {
  font-family: "Recoleta";
  font-weight: normal;
  font-size: 72px;
  line-height: 1.27em;
  color: #000;
}
.dark-style .title-style-six h2 {
  color: #fff;
}
.title-style-six h2 span {
  color: #ff2759;
  text-decoration: underline;
  text-decoration-thickness: 4px;
}
.title-style-six h6 {
  font-size: 18px;
  text-transform: uppercase;
  color: #8a8a8a;
  padding-bottom: 20px;
  letter-spacing: 1.8px;
}
.title-style-six .text-xs {
  font-size: 22px;
  line-height: 1.65em;
  padding-top: 30px;
}
.dark-style .title-style-six .text-xs {
  color: rgba(255, 255, 255, 0.54);
}
.title-style-seven h2 {
  font-size: 64px;
  font-weight: 500;
  line-height: 1.32em;
}
.title-style-seven h2 span {
  position: relative;
  display: inline-block;
}
.title-style-seven h2 span:before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}
.title-style-seven p {
  font-size: 22px;
  color: #000;
  padding-top: 22px;
}
.title-style-eight h2 {
  font-size: 68px;
  font-weight: 700;
  line-height: 1.26em;
}
.title-style-eight h2 span {
  display: inline-block;
  position: relative;
}
.title-style-eight h2 span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.title-style-eight p {
  font-size: 22px;
  color: #000;
  padding-top: 40px;
}
.title-style-eight h6 {
  font-size: 16px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.63);
  padding-bottom: 20px;
}
.title-style-nine h6 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8a8a8a;
  padding-bottom: 25px;
}
.title-style-nine h2 {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.34em;
}
.title-style-nine h2 span {
  display: inline-block;
  position: relative;
}
.title-style-nine h2 span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.title-style-nine p {
  font-size: 20px;
  color: #6a6b72;
  padding-top: 28px;
}
.title-style-ten h2 {
  font-size: 68px;
  font-weight: 500;
  line-height: 1.27em;
}
.title-style-ten p {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
}
.title-style-eleven h2 {
  font-size: 58px;
  font-weight: 500;
  line-height: 1.29em;
}
.title-style-ten h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.53);
  padding-bottom: 20px;
}
/*^^^^^^^^^^^^^^^^^^^^^ Theme Button ^^^^^^^^^^^^^^^^^^^^^^^^^*/
body .theme-btn-one {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  background: var(--blue-dark);
  padding: 0 42px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-one.btn-lg {
  padding: 0 50px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: 1.14px;
}
body .theme-btn-one:hover {
  background: #ffba12;
}
body .theme-btn-two {
  font-family: "gilroy-semibold";
  font-size: 18px;
  color: #fff;
  line-height: 48px;
  border: 2px solid var(--red-light);
  background: var(--red-light);
  padding: 0 42px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-two:hover {
  background: transparent;
  color: var(--red-light);
}
body .theme-btn-three {
  font-family: "gilroy-semibold";
  font-size: 18px;
  color: var(--red-light);
  line-height: 51px;
  border-radius: 30px;
  border: 2px solid var(--red-light);
  padding: 0 32px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-three:hover {
  background: var(--red-light);
  color: #fff;
}
body .theme-btn-four {
  font-size: 17px;
  color: var(--blue-dark);
  border: 2px solid var(--blue-dark);
  border-radius: 5px;
  line-height: 46px;
  padding: 0 35px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-four:hover {
  background: var(--blue-dark);
  color: #fff;
}
body .theme-btn-five {
  font-family: "Roboto", sans-serif;
  line-height: 56px;
  padding: 0 35px;
  text-align: center;
  color: #fff;
  background: var(--purple-blue);
  position: relative;
  z-index: 1;
}
body .theme-btn-five:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--vin-red);
  z-index: -1;
  transition: all 0.3s ease-in-out;
  transform: scale(0, 1);
  transform-origin: 0 100%;
}
body .theme-btn-five:hover:before {
  transform: scale(1, 1);
}
body .theme-btn-six {
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
  color: #000;
  transition: all 0.3s ease-in-out;
  background: #000;
  padding: 0 30px;
  color: #fff;
}
body .theme-btn-six.lg {
  padding: 0 45px;
}
body .theme-btn-six:hover {
  background: var(--blue-light);
  color: #fff;
}
body .theme-btn-seven {
  font-weight: 500;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  background: #000;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-seven:hover {
  background: #ff2759;
}
body .theme-btn-eight {
  font-weight: 500;
  font-size: 16px;
  line-height: 58px;
  padding: 0 40px;
  border-radius: 5px;
  background: #2d2d2d;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-eight:hover {
  background: #ffeaa0;
  color: #212121;
}
body .theme-btn-nine {
  line-height: 58px;
  border-radius: 50px;
  color: #fff;
  padding: 0 34px;
  text-align: center;
  background: #262626;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-nine:hover {
  background: #eb5e2a;
}
/*-------------------- Pagination ------------------*/
.page-pagination-one li a {
  font-family: "Roboto", sans-serif !important;
  background: #fff;
  width: 40px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 17px;
  color: #515151;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  transition: all 0.3s ease-in-out;
}
.page-pagination-one li a .fa {
  font-size: 22px;
}
.page-pagination-one li:first-child a {
  border-radius: 5px 0 0 5px;
}
.page-pagination-one li:last-child a {
  border-radius: 0 5px 5px 0;
}
.page-pagination-one li a:hover,
.page-pagination-one li a.active {
  background: var(--blue-dark);
  color: #fff;
}
.blog-v3 .page-pagination-one li a:hover,
.blog-v3 .page-pagination-one li a.active {
  background: var(--red-light);
  color: #fff;
}
.page-pagination-two ul li {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  color: #bababa;
  margin: 0 11px;
}
.page-pagination-two ul li:first-child a {
  transform: rotate(180deg);
}
.page-pagination-two ul li:hover a,
.page-pagination-two ul li.active a {
  color: #000;
}
.page-pagination-two ul li.arrow {
  font-size: 35px;
  color: #000;
}
.page-pagination-two ul li:nth-child(7) a {
  color: #000;
}
/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 30px 70px;
  transition: all 0.4s ease-out;
}
.theme-main-menu.bg-none {
  background: transparent;
}
.theme-main-menu.sticky-menu.fixed {
  z-index: 9999;
  padding-top: 0;
  padding-bottom: 0;
  background: #fff;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
.theme-main-menu .logo a {
  display: block;
}
.theme-menu-one .logo img {
  max-width: 120px;
}
.theme-menu-one .right-button-group a {
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 45px;
  text-transform: uppercase;
  text-align: center;
  color: var(--heading);
  transition: all 0.3s ease-in-out;
}
.theme-menu-one .right-button-group .signIn-action:hover {
  color: var(--blue-dark);
}
.theme-menu-one .right-button-group .signUp-action {
  width: 155px;
  color: #fff;
  background: var(--blue-dark);
  box-shadow: 0px 15px 40px rgba(161, 146, 250, 0.35);
  margin-left: 35px;
}
.theme-menu-one .right-button-group .signUp-action:hover {
  background: #ffba12;
  box-shadow: 0px 15px 40px rgba(255, 186, 18, 0.3);
}
.theme-menu-one .right-button-group {
  margin-left: 100px;
}
/*---------------------Theme Hero Banner/One ---------------*/
.hero-banner-one {
  position: relative;
}
.hero-banner-one .hero-upper-container {
  position: relative;
  padding: 60px 0 165px;
  text-align: center;
  z-index: 5;
}
.hero-banner-one .hero-heading span {
  position: relative;
  color: var(--blue-dark);
}
.hero-banner-one .hero-heading span:before {
  content: "";
  width: 96%;
  height: 12px;
  border-radius: 6px;
  background: var(--blue-dark);
  opacity: 0.16;
  position: absolute;
  bottom: 12px;
  left: 8px;
  z-index: -1;
}
.hero-banner-one .hero-sub-heading {
  font-size: 20px;
  padding: 20px 0 50px;
}
.hero-banner-one .subscription-form {
  max-width: 610px;
  margin: 0 auto;
  position: relative;
}
.hero-banner-one .subscription-form input {
  width: 100%;
  height: 58px;
  border: 1px solid #e2e2e2;
  border-radius: 30px;
  padding: 0 170px 0 30px;
  font-size: 16px;
}
.hero-banner-one .subscription-form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--blue-dark);
  border-radius: 0 30px 30px 0;
  color: #fff;
  font-size: 17px;
  width: 165px;
  transition: all 0.3s ease-in-out;
}
.hero-banner-one .subscription-form button:hover {
  background: #ffba12;
}
.hero-banner-one .sing-in-call {
  font-size: 16px;
  padding-top: 18px;
}
.hero-banner-one .sing-in-call a {
  color: var(--blue-dark);
  transition: all 0.25s ease-in-out;
}
.hero-banner-one .sing-in-call a:hover {
  text-decoration: underline;
}
.hero-banner-one [class*="icon-box"] {
  position: absolute;
  background: #fff;
  border-radius: 50%;
}
.hero-banner-one [class*="icon-box"] img {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.hero-banner-one .icon-box-one {
  width: 58px;
  height: 58px;
  box-shadow: 0px 20px 50px rgba(31, 36, 44, 0.07);
  top: 9%;
  left: 17%;
  /*animation: jumpTwo 3.5s infinite linear;*/
}
.hero-banner-one .icon-box-one img {
  width: 29px;
}
.hero-banner-one .icon-box-two {
  width: 90px;
  height: 90px;
  box-shadow: 10px 20px 50px rgba(31, 36, 44, 0.07);
  top: 32%;
  left: 5%;
  animation: jumpTwo 4s infinite linear;
}
.hero-banner-one .icon-box-two img {
  width: 42px;
}
.hero-banner-one .icon-box-three {
  width: 70px;
  height: 70px;
  box-shadow: 10px 25px 70px rgba(31, 36, 44, 0.07);
  top: 45%;
  left: 20%;
  animation: jumpThree 3.5s infinite linear;
}
.hero-banner-one .icon-box-three img {
  width: 30px;
}
.hero-banner-one .icon-box-four {
  width: 75px;
  height: 75px;
  box-shadow: 10px 30px 60px rgba(31, 36, 44, 0.07);
  bottom: 12%;
  left: 10%;
  animation: jumpThree 3s infinite linear;
}
.hero-banner-one .icon-box-four img {
  width: 40px;
}
.hero-banner-one .icon-box-five {
  width: 58px;
  height: 58px;
  box-shadow: 0px 20px 50px rgba(31, 36, 44, 0.07);
  top: 9%;
  right: 17%;
  animation: jumpTwo 3.5s infinite linear;
}
.hero-banner-one .icon-box-five img {
  width: 35px;
}
.hero-banner-one .icon-box-six {
  width: 90px;
  height: 90px;
  box-shadow: 10px 20px 50px rgba(31, 36, 44, 0.07);
  top: 32%;
  right: 5%;
  animation: jumpTwo 4s infinite linear;
}
.hero-banner-one .icon-box-six img {
  width: 54px;
}
.hero-banner-one .icon-box-seven {
  width: 70px;
  height: 70px;
  box-shadow: 10px 25px 70px rgba(31, 36, 44, 0.07);
  top: 45%;
  right: 20%;
  animation: jumpThree 3.5s infinite linear;
}
.hero-banner-one .icon-box-seven img {
  width: 26px;
}
.hero-banner-one .icon-box-eight {
  width: 75px;
  height: 75px;
  box-shadow: 10px 30px 60px rgba(31, 36, 44, 0.07);
  bottom: 12%;
  right: 10%;
  animation: jumpThree 3s infinite linear;
}
.hero-banner-one .icon-box-eight img {
  width: 30px;
}
.hero-banner-one [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.hero-banner-one [class*="bubble"]:before {
  content: "";
  border-radius: 50%;
  position: absolute;
}
.hero-banner-one .bubble-one {
  width: 120px;
  height: 120px;
  left: 4%;
  top: 15%;
  animation: rotated 12s infinite linear;
}
.hero-banner-one .bubble-one:before {
  width: 6px;
  height: 6px;
  background: #fac3ff;
  top: 0;
  left: 50%;
}
.hero-banner-one .bubble-two {
  width: 160px;
  height: 160px;
  top: 30%;
  left: 15%;
  animation: rotated 17s infinite linear;
}
.hero-banner-one .bubble-two:before {
  width: 10px;
  height: 10px;
  background: #8fe9e0;
  top: 50%;
  right: 100%;
}
.hero-banner-one .bubble-three {
  width: 120px;
  height: 120px;
  bottom: 33%;
  left: 4%;
  animation: rotated 12s infinite linear;
}
.hero-banner-one .bubble-three:before {
  width: 6px;
  height: 6px;
  background: #ffd5ad;
  bottom: 0;
  left: 50%;
}

.hero-banner-one .bubble-four {
  width: 120px;
  height: 120px;
  right: 4%;
  top: 15%;
  animation: rotated 12s infinite linear;
}
.hero-banner-one .bubble-four:before {
  width: 6px;
  height: 6px;
  background: #8fe9e1;
  top: 0;
  left: 50%;
}
.hero-banner-one .bubble-five {
  width: 160px;
  height: 160px;
  top: 30%;
  right: 15%;
  animation: rotated 17s infinite linear;
}
.hero-banner-one .bubble-five:before {
  width: 10px;
  height: 10px;
  background: #ffd5ad;
  top: 50%;
  right: 100%;
}
.hero-banner-one .bubble-six {
  width: 120px;
  height: 120px;
  bottom: 33%;
  right: 4%;
  animation: rotated 12s infinite linear;
}
.hero-banner-one .bubble-six:before {
  width: 6px;
  height: 6px;
  background: #fac3ff;
  bottom: 0;
  left: 50%;
}
/*------------------ Fancy Feature One --------------*/
.fancy-feature-one {
  background-image: url(../images/assets/dot-bg-01.svg);
  background-repeat: no-repeat;
  background-position: center 230px;
  background-size: cover;
  position: relative;
  z-index: 5;
}
.fancy-feature-one .feature-img-area {
  display: inline-block;
  position: relative;
  z-index: 5;
  padding-right: 125px;
}
.fancy-feature-one .feature-img-area:before {
  content: "";
  position: absolute;
  left: 35px;
  right: 160px;
  background: #fff;
  height: 200px;
  top: -25px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.04);
  z-index: -1;
}
.fancy-feature-one .feature-img-area:after {
  content: "";
  position: absolute;
  left: 80px;
  right: 205px;
  background: #fff;
  height: 200px;
  top: -50px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.04);
  z-index: -2;
}
.fancy-feature-one .feature-img-area .screen-one {
  width: 51%;
  right: -13%;
  bottom: -46%;
  z-index: 1;
  animation: jumpTwo 3s infinite linear;
}
.fancy-feature-one .feature-img-area .screen-two {
  width: 38%;
  right: 0;
  top: 33%;
}
.fancy-feature-one .feature-img-area .screen-three {
  width: 32%;
  left: 21.5%;
  top: 47%;
}
.fancy-feature-one .feature-img-area .screen-four {
  width: 32%;
  left: 35%;
  top: 61%;
}
.fancy-feature-one .feature-img-area .screen-five {
  width: 25%;
  right: 19.5%;
  bottom: 16%;
  z-index: 0;
}
.fancy-feature-one .block-style-one {
  margin-top: 240px;
}
.block-style-one .inner-container {
  position: relative;
}
.block-style-one .icon-box {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
  border-width: 5px;
  border-style: solid;
  margin: 0 auto;
  cursor: pointer;
}
.block-style-one .icon-box:after {
  content: "\f10a";
  font-family: "Flaticon";
  position: absolute;
  top: -50px;
  left: 59%;
  transform: translateX(-50%);
  font-size: 26px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.block-style-one .icon-box:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  border-width: 10px;
  border-style: solid;
  z-index: -1;
}
.block-style-one .block-meta-data:hover .icon-box:before {
  animation: hvr-ripple-out 2.75s infinite linear;
}
.block-style-one .block-meta-data:nth-child(1) .icon-box {
  border-color: #ff6080;
  color: #ff6080;
}
.block-style-one .block-meta-data:nth-child(1) .icon-box:before {
  border-color: #ffd8e2;
}
.block-style-one .block-meta-data:nth-child(2) .icon-box {
  border-color: #02e7e8;
  color: #02e7e8;
}
.block-style-one .block-meta-data:nth-child(2) .icon-box:before {
  border-color: #c6fff6;
}
.block-style-one .block-meta-data:nth-child(3) .icon-box {
  border-color: #936dff;
  color: #936dff;
}
.block-style-one .block-meta-data:nth-child(3) .icon-box:before {
  border-color: #e3ddf4;
}
.block-style-one .block-meta-data:nth-child(4) .icon-box {
  border-color: #ffba12;
  color: #ffba12;
}
.block-style-one .block-meta-data:nth-child(4) .icon-box:before {
  border-color: #ffe0b8;
}
.block-style-one .icon-box img {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.block-style-one .block-meta-data p {
  font-size: 20px;
  padding: 25px 0 80px;
  position: relative;
}
.block-style-one .block-meta-data p:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  background: #fff;
  transform: rotate(45deg);
  left: 45%;
  bottom: 25px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.block-style-one .block-meta-data .hover-content {
  font-size: 15px;
  border: 1px solid #e9e9e9;
  border-radius: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.block-style-one .block-meta-data .hover-content span {
  position: relative;
  z-index: 5;
  display: block;
  padding: 3px 20px;
}
.block-style-one .block-meta-data:hover .hover-content,
.block-style-one .block-meta-data:hover p:before,
.block-style-one .block-meta-data:hover .icon-box:after {
  opacity: 1;
  visibility: visible;
}
.block-style-one .block-meta-data .line-dot-container {
  position: relative;
}
.block-style-one .block-meta-data .line-dot-container:before {
  content: "";
  position: absolute;
  right: -187px;
  top: 42px;
  width: 160px;
  height: 1px;
  border: 1px dashed rgba(151, 151, 151, 0.35);
}
.block-style-one .block-meta-data:last-child .line-dot-container:before {
  display: none;
}
/*------------------ Fancy Feature Two --------------*/
.block-style-two .img-holder {
  position: relative;
}
.block-style-two {
  margin: 0 -60px;
}
.block-style-two > [class*="col-"] {
  padding: 0 60px;
}
.block-style-two .text-wrapper h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  padding-bottom: 15px;
}
.block-style-two .text-wrapper h3 {
  font-size: 36px;
  line-height: 1.25em;
  padding-bottom: 26px;
}
.block-style-two .text-wrapper a {
  font-size: 16px;
  color: var(--blue-dark);
  margin-top: 18px;
}
.block-style-two .text-wrapper a:hover {
  text-decoration: underline;
}
.block-style-two .text-wrapper .quote {
  border-top: 1px solid #ececec;
  margin-top: 22px;
  padding-top: 20px;
}
.block-style-two .text-wrapper .quote blockquote {
  font-size: 15px;
  padding-bottom: 22px;
  line-height: 25px;
}
.block-style-two .text-wrapper .quote img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.block-style-two .text-wrapper .quote .info-meta {
  padding-left: 15px;
}
.block-style-two .text-wrapper .quote .info-meta h5 {
  font-size: 16px;
  font-weight: 500;
}
.block-style-two .text-wrapper .quote .info-meta span {
  font-size: 16px;
  color: #7034ff;
  display: block;
}
.block-style-two .img-holder-one .screen-one {
  z-index: -5;
  top: -19%;
  right: 15%;
  max-width: inherit;
}
.block-style-two .img-holder-one .dot-shape {
  z-index: -1;
  top: 32%;
  right: 37%;
  width: 60%;
}
.block-style-two [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.block-style-two [class*="bubble"]:before {
  content: "";
  border-radius: 50%;
  position: absolute;
}
.block-style-two .img-holder-one .bubble-one {
  width: 120px;
  height: 120px;
  left: 23%;
  top: -3%;
  animation: rotated 14s infinite linear;
}
.block-style-two .img-holder-one .bubble-one:before {
  width: 8px;
  height: 8px;
  background: #ae78ff;
  top: 0;
  left: 50%;
}
.block-style-two .img-holder-one .bubble-two {
  width: 160px;
  height: 160px;
  top: 28%;
  left: -21%;
  animation: rotatedTwo 25s infinite linear;
}
.block-style-two .img-holder-one .bubble-two:before {
  width: 17px;
  height: 17px;
  background: #ffb15f;
  top: 50%;
  right: 100%;
}
.block-style-two .img-holder-one .bubble-three {
  width: 120px;
  height: 120px;
  bottom: -7%;
  left: -10%;
  animation: rotated 15s infinite linear;
}
.block-style-two .img-holder-one .bubble-three:before {
  width: 7px;
  height: 7px;
  background: #ff53b3;
  bottom: 0;
  left: 50%;
}

.block-style-two .img-holder-one .bubble-four {
  width: 120px;
  height: 120px;
  right: 27%;
  bottom: -13%;
  animation: rotatedTwo 17s infinite linear;
}
.block-style-two .img-holder-one .bubble-four:before {
  width: 12px;
  height: 12px;
  background: #2eeaed;
  bottom: 0;
  left: 50%;
}
.block-style-two .img-holder-one .progress-line-one {
  width: 16%;
  height: 0.95%;
  background: #03c4c6;
  border-radius: 4px;
  right: 28%;
  top: 33.5%;
}
.block-style-two .img-holder-one .progress-line-two {
  width: 16%;
  height: 0.85%;
  background: #ff3793;
  border-radius: 4px;
  left: 10%;
  top: 52.6%;
}
.block-style-two .img-holder-two .screen-one {
  top: -6%;
  right: -29%;
  z-index: 1;
  animation: jumpTwo 3s infinite linear;
}
.block-style-two .img-holder-two .screen-two {
  bottom: 1%;
  right: -97%;
  z-index: -5;
  max-width: inherit;
}
.block-style-two .img-holder-two .dot-shape {
  z-index: -1;
  top: 44%;
  right: 38%;
  width: 69%;
}
.block-style-two .img-holder-two .bubble-one {
  width: 120px;
  height: 120px;
  left: 15%;
  top: -8%;
  animation: rotated 14s infinite linear;
}
.block-style-two .img-holder-two .bubble-one:before {
  width: 7px;
  height: 7px;
  background: #ffb651;
  top: 0;
  left: 50%;
}
.block-style-two .img-holder-two .bubble-two {
  width: 160px;
  height: 160px;
  top: -31%;
  right: -71%;
  animation: rotatedTwo 25s infinite linear;
}
.block-style-two .img-holder-two .bubble-two:before {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  top: 50%;
  right: 100%;
}
.block-style-two .img-holder-two .bubble-three {
  width: 100px;
  height: 100px;
  top: 47%;
  right: -58%;
  animation: rotated 17s infinite linear;
}
.block-style-two .img-holder-two .bubble-three:before {
  width: 8px;
  height: 8px;
  background: #4be8da;
  bottom: 0;
  left: 50%;
}

.block-style-two .img-holder-two .bubble-four {
  width: 130px;
  height: 130px;
  left: 51%;
  bottom: -12%;
  animation: rotatedTwo 15s infinite linear;
}
.block-style-two .img-holder-two .bubble-four:before {
  width: 13px;
  height: 13px;
  background: #a49bff;
  bottom: 0;
  left: 50%;
}

.block-style-two .img-holder-three .screen-one {
  bottom: -42%;
  left: -30%;
  z-index: 1;
  width: 79%;
  animation: jumpTwo 3s infinite linear;
}
.block-style-two .img-holder-three .screen-two {
  top: -26%;
  left: -71%;
  z-index: -5;
  max-width: inherit;
}
.block-style-two .img-holder-three .dot-shape {
  z-index: -1;
  top: 44%;
  right: 14%;
  width: 58%;
}
.block-style-two .img-holder-three .bubble-one {
  width: 95px;
  height: 95px;
  left: 24%;
  top: -12%;
  animation: rotated 14s infinite linear;
}
.block-style-two .img-holder-three .bubble-one:before {
  width: 8px;
  height: 8px;
  background: #6aee90;
  top: 0;
  left: 50%;
}
.block-style-two .img-holder-three .bubble-two {
  width: 160px;
  height: 160px;
  top: 9%;
  left: -27%;
  animation: rotatedTwo 25s infinite linear;
}
.block-style-two .img-holder-three .bubble-two:before {
  width: 15px;
  height: 15px;
  background: #ff6cc4;
  top: 50%;
  right: 100%;
}
.block-style-two .img-holder-three .bubble-three {
  width: 100px;
  height: 100px;
  bottom: -5%;
  left: -42%;
  animation: rotated 17s infinite linear;
}
.block-style-two .img-holder-three .bubble-three:before {
  width: 7px;
  height: 7px;
  background: #51fcff;
  bottom: 0;
  left: 50%;
}

.block-style-two .img-holder-three .bubble-four {
  width: 130px;
  height: 130px;
  left: 14%;
  bottom: -42%;
  animation: rotatedTwo 15s infinite linear;
}
.block-style-two .img-holder-three .bubble-four:before {
  width: 14px;
  height: 14px;
  background: #ffcd8b;
  bottom: 0;
  left: 50%;
}
/*------------------ Useable Tools  --------------*/
.useable-tools-section.bg-shape {
  position: relative;
  z-index: 5;
  background-image: url(../images/shape/7.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  padding: 500px 0 350px;
}
.useable-tools-section.bg-color {
  background: var(--blue-dark);
  padding: 130px 0 120px;
}
.useable-tools-section h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding-bottom: 15px;
  color: #fff;
}
.useable-tools-section.bg-transparent h6 {
  color: rgba(0, 0, 0, 0.63);
}
.useable-tools-section.bg-transparent h2 {
  color: #2a2a2a;
  margin: 0;
}
.useable-tools-section h2 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.2em;
  color: #fff;
  margin: 20px 0 120px;
}
.useable-tools-section .sub-text {
  text-align: center;
  font-size: 22px;
  line-height: 1.59em;
  color: #000;
}
.useable-tools-section .img-box a {
  display: block;
  height: 100%;
}
.useable-tools-section .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.useable-tools-section .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15.436px 30.294px 50px 0px rgba(103, 43, 246, 0.5);
  width: 175px;
  height: 175px;
  margin: 0 auto 25px;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 575px) {
    margin: 0 auto 15px;
  }
}
.useable-tools-section.bg-transparent .img-box {
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}
.useable-tools-section-three .logo-wrapper.brand-bg-white .logo {
  background: #ffffff !important;
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.0696193);
}
.useable-tools-section .img-box:hover {
  transform: scale(1.1);
}
.useable-tools-section .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-g {
  width: 197px;
  height: 197px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.useable-tools-section [class*="bubble"]:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  background: #9a71ff;
}
.useable-tools-section .bubble-one {
  width: 120px;
  height: 120px;
  left: 43%;
  top: 20%;
  animation: rotated 14s infinite linear;
}
.useable-tools-section .bubble-one:before {
  width: 6px;
  height: 6px;
  top: 0;
  right: 50%;
}
.useable-tools-section .bubble-two {
  width: 140px;
  height: 140px;
  right: 20%;
  top: 14%;
  animation: rotatedTwo 14s infinite linear;
}
.useable-tools-section .bubble-two:before {
  width: 12px;
  height: 12px;
  top: 0;
  left: 50%;
}
.useable-tools-section .bubble-three {
  width: 95px;
  height: 95px;
  left: 10%;
  top: 44%;
  animation: rotated 14s infinite linear;
}
.useable-tools-section .bubble-three:before {
  width: 10px;
  height: 10px;
  top: 100%;
  left: 50%;
}
.useable-tools-section .bubble-four {
  width: 95px;
  height: 95px;
  right: 10%;
  top: 48%;
  animation: rotatedTwo 14s infinite linear;
}
.useable-tools-section .bubble-four:before {
  width: 8px;
  height: 8px;
  top: 0;
  left: 50%;
}
.useable-tools-section .bubble-five {
  width: 95px;
  height: 95px;
  right: 56%;
  bottom: 8%;
  animation: rotated 14s infinite linear;
}
.useable-tools-section .bubble-five:before {
  width: 8px;
  height: 8px;
  top: 0;
  left: 50%;
}
/*-------------------- Fancy Feature Three -------------------*/
.counter-info-classic {
  max-width: 445px;
  height: 442px;
  position: relative;
  z-index: 5;
  margin-top: 100px;
}
.counter-box-one {
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.counter-box-one.color-one {
  background: rgba(0, 166, 255, 0.65);
  width: 195px;
  height: 195px;
  top: 40px;
  left: 40px;
  z-index: -2;
}

.counter-box-one.color-two {
  background: rgba(140, 39, 255, 0.55);
  width: 154px;
  height: 154px;
  top: 136px;
  left: 248px;
  z-index: 2;
}
.counter-box-one.color-three {
  background: rgba(255, 173, 58, 0.72);
  width: 212px;
  height: 212px;
  top: 200px;
  left: 110px;
  z-index: 1;
}
.counter-box-one .number {
  color: #fff;
  font-size: 52px;
}
.counter-box-one p {
  font-size: 20px;
  color: #fff;
}
.counter-info-classic [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.counter-info-classic .bubble-one {
  width: 8px;
  height: 8px;
  background: #7dec72;
  top: 0;
  left: 62%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .bubble-two {
  width: 22px;
  height: 22px;
  background: #ffb56a;
  top: 15%;
  left: 63%;
  animation: scale-up-one 4s infinite linear;
}
.counter-info-classic .bubble-three {
  width: 8px;
  height: 8px;
  background: #88d9ff;
  top: 16%;
  right: 11%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .bubble-four {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  bottom: 24%;
  right: 13%;
  animation: scale-up-one 4s infinite linear;
}
.counter-info-classic .bubble-five {
  width: 8px;
  height: 8px;
  background: #77f4f5;
  bottom: 20%;
  right: 5%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .bubble-six {
  width: 26px;
  height: 26px;
  background: #a595ff;
  bottom: 32%;
  left: 0;
  animation: scale-up-one 4s infinite linear;
}
.counter-info-classic .bubble-seven {
  width: 9px;
  height: 9px;
  background: #66e19e;
  bottom: 40%;
  left: 14%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .dot-shape {
  z-index: -5;
  top: 35px;
  right: 0;
}
.clients-feedback-classic {
  position: relative;
  padding: 20px 0 0 90px;
  z-index: 5;
}
.clients-feedback-classic:before {
  content: url(../images/shape/9.svg);
  position: absolute;
  top: -120px;
  right: -185px;
  z-index: -1;
}
.clients-feedback-classic .feedback-wrapper {
  background: #fff;
  position: relative;
  box-shadow: 0 20px 80px rgba(49, 62, 103, 0.07);
  border-radius: 5px;
  padding: 54px 64px 48px;
  margin-bottom: 108px;
}
.clients-feedback-classic .feedback-wrapper:before {
  content: url(../images/icon/05.svg);
  position: absolute;
  bottom: 55px;
  right: 60px;
  filter: invert(0.1);
}
.clients-feedback-classic .feedback-wrapper p {
  padding-bottom: 30px;
}
.clients-feedback-classic .feedback-wrapper .media-meta {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: -35px;
  z-index: 1;
}
.clients-feedback-classic .feedback-wrapper .name {
  font-family: "gilroy-semibold";
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
}
.clients-feedback-classic .feedback-wrapper .disg-info {
  display: block;
  font-size: 16px;
  color: #939393;
}
.clients-feedback-classic .feedback-wrapper:last-child {
  margin-bottom: -100px;
}
/*------------------ Fancy Short Banner One ----------------*/
.fancy-short-banner-one {
  position: relative;
  z-index: 3;
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 235px 0 170px;
}
.fancy-short-banner-one.space-fix {
  padding: 170px 0 170px;
}
.fancy-short-banner-one:before,
.fancy-short-banner-one:after {
  content: url(../images/shape/10.svg);
  position: absolute;
  opacity: 0.9;
}
.fancy-short-banner-one:before {
  top: -133px;
  left: 0;
  animation: jumpTwo 4s infinite linear;
}
.fancy-short-banner-one:after {
  bottom: -133px;
  right: 0;
  animation: jumpThree 4s infinite linear;
}

.fancy-short-banner-one .form-wrapper {
  padding-left: 65px;
}
.fancy-short-banner-one .form-wrapper form {
  position: relative;
}
.fancy-short-banner-one .form-wrapper form input {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  width: calc(100% - 180px);
  border: none;
  border-bottom: 2px solid #545454;
  height: 50px;
  background: transparent;
}
.fancy-short-banner-one .form-wrapper form button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 150px;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  background: var(--blue-dark);
  box-shadow: 0 15px 30px rgba(139, 110, 209, 0.3);
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-one .form-wrapper form button:hover {
  background: #ffba12;
  box-shadow: 0px 15px 30px rgba(255, 186, 18, 0.2);
}
.fancy-short-banner-one .form-wrapper p {
  font-size: 16px;
  padding-top: 10px;
}
.fancy-short-banner-one .form-wrapper p a {
  color: var(--blue-dark);
}
.fancy-short-banner-one .form-wrapper p a:hover {
  text-decoration: underline;
}
.fancy-short-banner-one [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-short-banner-one .bubble-one {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  top: 13%;
  left: 41%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-short-banner-one .bubble-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  top: 29%;
  right: 11%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-one .bubble-three {
  width: 20px;
  height: 20px;
  background: #ffba65;
  bottom: -10px;
  right: 29%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-short-banner-one .bubble-four {
  width: 8px;
  height: 8px;
  background: #6aee90;
  bottom: 22%;
  left: 42%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-one .bubble-five {
  width: 25px;
  height: 25px;
  background: #b183ff;
  bottom: -12px;
  left: 12%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-short-banner-one .bubble-six {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  top: 32%;
  left: 14%;
  animation: scale-up-three 4s infinite linear;
}
/*----------------------- Faq Classic ----------------------*/
.faq-classic.with-bg {
  background: #f7faff;
  padding: 120px 0 250px;
  z-index: 5;
  position: relative;
}
.faq-classic.with-bg .shape-one {
  top: 13%;
  right: 4%;
}
.faq-classic.with-bg .shape-two {
  top: 36%;
  right: 11%;
}
.faq-classic.with-bg .shape-three {
  top: 63%;
  right: 0;
}
.faq-classic.with-bg .shape-four {
  top: 63%;
  left: 0%;
}
.faq-classic.with-bg .shape-five {
  top: 41%;
  left: 5%;
}
.faq-classic.with-bg .shape-six {
  top: 14%;
  left: 0;
}
.faq-classic .card {
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(90, 111, 155, 0.05);
  margin-bottom: 20px;
  border-radius: 0;
  border: none;
}
.faq-classic .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.faq-classic .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 20px 15px 20px 70px;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.faq-classic .card .card-header button:before {
  content: "+";
  position: absolute;
  font-size: 24px;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.faq-classic .card .card-body {
  padding: 0 50px 20px 70px;
}
.faq-classic .card .card-body p {
  font-size: 17px;
}
.faq-classic .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}
.faq-classic .card:last-child .card-body {
  border: none;
}
/*------------------ Footer One --------------*/
.footer-bg-wrapper {
  position: relative;
  padding-top: 230px;
}
.footer-bg-wrapper:before {
  content: url(../images/shape/11.svg);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.footer-bg-wrapper [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.footer-bg-wrapper .bubble-one {
  width: 25px;
  height: 25px;
  background: #ff6cc4;
  top: 4%;
  left: 26%;
  animation: scale-up-one 4s infinite linear;
}
.footer-bg-wrapper .bubble-two {
  width: 14px;
  height: 14px;
  background: #ffbe50;
  top: 48%;
  left: 10%;
  animation: scale-up-one 4s infinite linear;
}
.footer-bg-wrapper .bubble-three {
  width: 7px;
  height: 7px;
  background: #04e8f4;
  bottom: 18%;
  left: 20%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-two .content-wrapper {
  position: relative;
}
.fancy-short-banner-two .content-wrapper:before {
  content: url(../images/shape/12.svg);
  position: absolute;
  top: -117px;
  right: -117px;
  z-index: -1;
  opacity: 0.9;
  animation: jumpTwo 5s infinite linear;
}
.fancy-short-banner-two .bg-wrapper {
  position: relative;
  overflow: hidden;
  background: #7034ff;
  box-shadow: 0 20px 60px 0 rgba(104, 103, 255, 0.2);
  border-radius: 5px;
  padding: 50px 55px;
  z-index: 1;
}
.fancy-short-banner-two h2 {
  font-size: 36px;
  line-height: 1.16em;
  color: #fff;
  max-width: 560px;
}
.fancy-short-banner-two a {
  width: 178px;
  line-height: 54px;
  background: #fff;
  border-radius: 5px;
  color: var(--blue-dark);
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-two a:hover {
  background: #ffba12;
  color: #fff;
}
.fancy-short-banner-two [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-short-banner-two .bubble-one {
  width: 13px;
  height: 13px;
  background: rgba(216, 216, 216, 0.14);
  top: 27%;
  left: 60%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-short-banner-two .bubble-two {
  width: 6px;
  height: 6px;
  background: rgba(216, 216, 216, 0.14);
  top: 62%;
  right: 28%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-two .bubble-three {
  width: 5px;
  height: 5px;
  background: rgba(216, 216, 216, 0.14);
  bottom: 37%;
  right: 51%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-two .bubble-four {
  width: 245px;
  height: 245px;
  background: rgba(216, 216, 216, 0.14);
  top: -103px;
  right: -70px;
  animation: jello 3s infinite linear;
}
.fancy-short-banner-two .bubble-five {
  width: 68px;
  height: 68px;
  background: rgba(216, 216, 216, 0.14);
  bottom: -24px;
  left: -28px;
  animation: jello 3s infinite linear;
}
.theme-footer-one {
  position: relative;
}
.theme-footer-one:before {
  content: url(../images/assets/ils_01.svg);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.theme-footer-one .top-footer [class*="col-"] {
  margin-bottom: 30px;
}
.theme-footer-one .top-footer .footer-title {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  padding-bottom: 25px;
}
.theme-footer-one .top-footer .footer-list ul li a {
  font-size: 16px;
  line-height: 38px;
  color: #727272;
  transition: all 0.3s ease-in-out;
}
.theme-footer-one .top-footer .footer-list ul li a:hover {
  color: var(--blue-dark);
}
.theme-footer-one .top-footer .address-list ul li a {
  font-size: 16px;
  color: #727272;
  margin-bottom: 10px;
}
.theme-footer-one .top-footer .address-list ul li a:hover {
  text-decoration: underline;
}
.theme-footer-one .top-footer .address-list ul li a.mobile-num {
  font-size: 20px;
  color: #030303;
}
.theme-footer-one .bottom-footer-content ul li {
  display: inline-block;
}
.theme-footer-one .bottom-footer-content ul li a {
  font-size: 16px;
  color: #727272;
  margin-left: 20px;
}
.theme-footer-one .bottom-footer-content ul li a:hover {
  color: #000;
}
.theme-footer-one .bottom-footer-content p {
  font-size: 15px;
  color: #6a6a6a;
}
.theme-footer-one .bottom-footer-content {
  padding: 26px 0 30px;
}
.theme-footer-one .bottom-footer-content ul {
  padding-right: 97px;
}
/*--------------------- Fancy Hero One --------------------*/
.fancy-hero-one {
  position: relative;
  z-index: 5;
  text-align: center;
  padding: 75px 0 105px;
}
.fancy-hero-one h2 {
  font-size: 80px;
  line-height: 1.1em;
}
.fancy-hero-one p {
  font-size: 24px;
  line-height: 1.75em;
  padding: 15px 25px 0;
}
.fancy-hero-one .page-title {
  font-size: 18px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.63);
  letter-spacing: 1.4px;
}
.fancy-hero-one .search-form {
  max-width: 720px;
  height: 70px;
  margin: 55px auto 0;
  position: relative;
}
.fancy-hero-one .search-form input {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 0 95px 0 38px;
}
.fancy-hero-one .search-form button {
  width: 80px;
  background: var(--blue-dark);
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  z-index: 5;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.fancy-hero-one .search-form button img {
  margin: 0 auto;
}
.fancy-hero-one .search-form button:hover {
  background: var(--yellow-deep);
}
.fancy-hero-one [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-hero-one [class*="bubble"]:before {
  content: "";
  border-radius: 50%;
  position: absolute;
}
.fancy-hero-one .bubble-one {
  width: 120px;
  height: 120px;
  left: 4%;
  top: 15%;
  animation: rotated 12s infinite linear;
}
.fancy-hero-one .bubble-one:before {
  width: 6px;
  height: 6px;
  background: #fac3ff;
  top: 0;
  left: 50%;
}
.fancy-hero-one .bubble-two {
  width: 160px;
  height: 160px;
  top: 30%;
  left: 15%;
  animation: rotated 17s infinite linear;
}
.fancy-hero-one .bubble-two:before {
  width: 10px;
  height: 10px;
  background: #8fe9e0;
  top: 50%;
  right: 100%;
}
.fancy-hero-one .bubble-three {
  width: 120px;
  height: 120px;
  bottom: 33%;
  left: 4%;
  animation: rotatedTwo 12s infinite linear;
}
.fancy-hero-one .bubble-three:before {
  width: 6px;
  height: 6px;
  background: #ffd5ad;
  bottom: 0;
  left: 50%;
}

.fancy-hero-one .bubble-four {
  width: 120px;
  height: 120px;
  right: 5%;
  top: 13%;
  animation: rotated 12s infinite linear;
}
.fancy-hero-one .bubble-four:before {
  width: 6px;
  height: 6px;
  background: #8fe9e1;
  top: 0;
  left: 50%;
}
.fancy-hero-one .bubble-five {
  width: 160px;
  height: 160px;
  top: 30%;
  right: 7%;
  animation: rotated 17s infinite linear;
}
.fancy-hero-one .bubble-five:before {
  width: 10px;
  height: 10px;
  background: #ffd5ad;
  top: 50%;
  right: 100%;
}
.fancy-hero-one .bubble-six {
  width: 120px;
  height: 120px;
  bottom: 33%;
  right: 4%;
  animation: rotatedTwo 12s infinite linear;
}
.fancy-hero-one .bubble-six:before {
  width: 6px;
  height: 6px;
  background: #fac3ff;
  bottom: 0;
  left: 50%;
}
/*------------------- Fancy Text block One ----------------*/
.fancy-text-block-one {
  position: relative;
  z-index: 3;
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 55px 0;
}
.fancy-text-block-one:before,
.fancy-text-block-one:after {
  content: url(../images/shape/10.svg);
  position: absolute;
  opacity: 0.9;
}
.fancy-text-block-one:before {
  top: -133px;
  left: 0;
  animation: jumpTwo 4s infinite linear;
}
.fancy-text-block-one:after {
  bottom: -133px;
  right: 0;
  animation: jumpThree 4s infinite linear;
}
.fancy-text-block-one .quote-wrapper {
  padding-left: 25px;
}
.fancy-text-block-one .quote-wrapper p {
  font-family: "gilroy-semibold";
  font-size: 20px;
  color: #000000;
  padding-bottom: 21px;
}
.fancy-text-block-one .quote-wrapper p span {
  color: #ffb147;
}
.fancy-text-block-one .quote-wrapper blockquote {
  font-size: 40px;
  line-height: 1.5em;
  color: #181818;
  position: relative;
  margin-bottom: 43px;
}
.fancy-text-block-one .quote-wrapper blockquote:before {
  content: url(../images/icon/06.svg);
  position: absolute;
  left: -92px;
  top: 11px;
}
.fancy-text-block-one .quote-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
}
.fancy-text-block-one .quote-wrapper h6 span {
  font-weight: normal;
  color: #9c9c9c;
  font-size: 18px;
}
.fancy-text-block-one [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-text-block-one .bubble-one {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  top: 15%;
  left: 42%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-one .bubble-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  top: 29%;
  right: 8%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-text-block-one .bubble-three {
  width: 20px;
  height: 20px;
  background: #ffba65;
  bottom: 7%;
  right: 26%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-one .bubble-four {
  width: 8px;
  height: 8px;
  background: #6aee90;
  bottom: 29%;
  left: 43%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-text-block-one .bubble-five {
  width: 25px;
  height: 25px;
  background: #b183ff;
  bottom: 7%;
  left: 9%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-one .bubble-six {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  top: 31%;
  left: 11%;
  animation: scale-up-three 4s infinite linear;
}
/*----------------- Counter Info Standard --------------*/
.counter-info-standard {
  border-bottom: 1px solid #efefef;
}
.counter-box-two {
  text-align: center;
  margin-bottom: 30px;
}
.counter-box-two .number {
  font-size: 80px;
  color: var(--blue-dark);
}
.counter-box-two em {
  font-size: 20px;
  display: block;
  color: #8e8e8e;
  font-style: normal;
}
.counter-box-two p {
  font-size: 28px;
  line-height: 1.35em;
  color: #212121;
  padding-top: 20px;
}
/*---------------- Fancy Text block Two -------------*/
.fancy-text-block-two .fancy_img_media {
  border-radius: 8px;
  margin-left: auto;
}
/*---------------- Fancy Text block Three -------------*/
.fancy-text-block-three {
  position: relative;
  z-index: 1;
  padding-bottom: 110px;
}
.fancy-text-block-three:before {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 16%;
  left: 0;
  background: #fafaff;
  z-index: -2;
}
.fancy-text-block-three .feature-content {
  padding-bottom: 60px;
}
.fancy-text-block-three .feature-content.light-bg {
  background: #5e43f0;
  border-radius: 5px 0 0 5px;
}
.fancy-text-block-three .feature-content.dark-bg {
  background: #462ec5;
  border-radius: 0 5px 5px 0;
}
.fancy-text-block-three .feature-content .header {
  padding: 65px 20px 55px 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.fancy-text-block-three .feature-content .header h3 {
  font-family: "gilroy-semibold";
  color: #fff;
  font-size: 50px;
}
.fancy-text-block-three .feature-content .header p {
  font-size: 20px;
  line-height: 1.5em;
  color: #fff;
  padding-top: 18px;
}
.fancy-text-block-three .feature-content .feature-list {
  padding: 50px 20px 0 55px;
}
.fancy-text-block-three .feature-content .feature-list .icon-box {
  width: 55px;
  height: 55px;
  background: #fff;
  border-radius: 50%;
}
.fancy-text-block-three .feature-content .feature-list .text-meta {
  padding-left: 20px;
  width: calc(100% - 55px);
}
.fancy-text-block-three .feature-content .feature-list h4 {
  font-family: "Open Sans";
  font-size: 22px;
  color: #fff;
}
.fancy-text-block-three .feature-content .feature-list p {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 7px;
}
.fancy-text-block-three .slogan {
  text-align: center;
  font-size: 40px;
  line-height: 1.38em;
  padding-top: 80px;
  color: #000;
}
.fancy-text-block-three [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-text-block-three .bubble-one {
  width: 15px;
  height: 15px;
  background: #ff6cc4;
  top: 27%;
  right: 10%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-three .bubble-two {
  width: 7px;
  height: 7px;
  background: #f5a623;
  top: 70%;
  right: 10%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-text-block-three .bubble-three {
  width: 20px;
  height: 20px;
  background: #b183ff;
  bottom: 16%;
  left: 9%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-three .bubble-four {
  width: 8px;
  height: 8px;
  background: #6aee90;
  top: 40%;
  left: 8%;
  animation: scale-up-three 4s infinite linear;
}
/*----------------- Team Section One --------------*/
.team-section-one .controls {
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}
.team-section-one .controls .control {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  display: inline-block;
  position: relative;
  margin: 0 25px;
  padding-bottom: 20px;
  color: #7f7f7f;
}
.team-section-one .controls .control:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--blue-dark);
  left: 0;
  bottom: -1px;
  border-radius: 3px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.team-section-one .controls .react-tabs__tab--selected .control {
  color: var(--blue-dark);
}
.team-section-one .controls .react-tabs__tab--selected .control:before {
  opacity: 1;
  width: 100%;
}
.team-section-one .mixitUp-container {
  text-align: center;
}
.team-section-one .team-member {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.team-section-one .team-member:hover {
  transform: translateY(-5px);
}
.team-section-one .team-member img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 0 auto;
}
.team-section-one .team-member h4 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  padding: 25px 0 6px;
}
.team-section-one .team-member strong {
  color: #a5a5a5;
  font-weight: normal;
}
.team-section-one .mix,
.team-section-one .gap {
  width: calc(100% / 4 - (((4 - 1) * 1rem) / 4));
  display: inline-block;
}
.team-section-one .mix {
  margin-bottom: 100px;
}
/*-------------- Feature Blog One -------------*/
.feature-blog-one .header .title-style-one {
  max-width: 560px;
}
.feature-blog-one .row [class*="col-"] {
  display: flex;
}
.feature-blog-one .post-meta {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  margin-bottom: 50px;
  padding: 20px 20px 80px;
  position: relative;
  width: 100%;
  transition: 0.3s ease-in-out;
}
.feature-blog-one .post-meta .image-meta {
  width: 100%;
  border-radius: 5px;
}
.feature-blog-one .post-meta .tag {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: rgba(10, 10, 10, 0.54);
  padding: 25px 0 3px;
}
.feature-blog-one .post-meta .title {
  font-family: "Open Sans";
  font-size: 30px;
  line-height: 1.2em;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.feature-blog-one .post-meta {
  &:hover {
    transform: translateY(-10px);
  }
  a.title {
    &:hover {
      color: var(--blue-dark);
    }
  }
}
.feature-blog-one .post-meta .read-more {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  &:hover {
    color: var(--blue-dark);
  }
}
.feature-blog-one .post-meta .read-more i {
  font-size: 22px;
}
/*---------------- Fancy Text block Four -------------*/
.fancy-text-block-four {
  background: #fcf7ef;
  position: relative;
  z-index: 5;
}
.fancy-text-block-four:before {
  content: url(../images/shape/13.svg);
  position: absolute;
  top: -48px;
  left: 0;
  animation: jumpTwo 4s infinite linear;
}
.fancy-text-block-four:after {
  content: url(../images/shape/14.svg);
  position: absolute;
  bottom: -140px;
  right: 0;
  animation: jumpThree 4s infinite linear;
}
.fancy-text-block-four .wrapper {
  position: relative;
  z-index: 1;
}
.fancy-text-block-four .wrapper:before {
  content: url(../images/assets/ils_02.svg);
  position: absolute;
  top: -219px;
  right: -253px;
  z-index: -1;
  animation: jumpTwo 10s infinite linear;
}
.block-style-three {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.02);
  padding: 25px 40px 70px 50px;
  margin-top: 35px;
}
.block-style-three .icon {
  height: 75px;
}
.block-style-three h4 {
  font-family: "Open Sans";
  font-size: 22px;
  padding: 25px 0 20px;
}
.block-style-three p {
  font-size: 17px;
  line-height: 1.64em;
}
/*---------------- Fancy Text block Five -------------*/
.fancy-text-block-five {
  position: relative;
  z-index: 5;
}
.fancy-text-block-five:before {
  content: url(../images/assets/ils_03.svg);
  position: absolute;
  top: 150px;
  left: 7%;
  z-index: -1;
  animation: jumpTwo 10s infinite linear;
}
.block-style-four {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 25px 30px 20px 40px;
  margin-top: 35px;
  transition: all 0.3s ease-in-out;
}
.block-style-four:hover {
  box-shadow: 0 25px 60px rgba(12, 28, 65, 0.05);
  border-color: #fff;
  transform: translateY(-5px);
}
.block-style-four .icon {
  height: 75px;
}
.block-style-four h4 {
  font-family: "Open Sans";
  font-size: 24px;
  padding: 9px 0 20px;
}
.block-style-four p {
  font-size: 17px;
  line-height: 1.64em;
}
.block-style-four a {
  font-size: 30px;
  color: #303030;
  margin-top: 30px;
}
.block-style-four:hover a {
  color: var(--blue-dark);
}
/*-------------- Feature Blog Two -------------*/
.feature-blog-two {
  background: #f2fbfd;
}
.feature-blog-two .header .title-style-one {
  max-width: 560px;
}
.feature-blog-two .row [class*="col-"] {
  display: flex;
}
.feature-blog-two .post-meta {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  margin-top: 35px;
  padding: 20px 20px 80px;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.feature-blog-two .post-meta:hover {
  transform: translateY(-10px);
}
.feature-blog-two .post-meta .image-meta {
  width: 100%;
  border-radius: 5px;
}
.feature-blog-two .post-meta .title {
  font-family: "Open Sans";
  font-size: 30px;
  line-height: 1.2em;
  color: #000;
  transition: all 0.3s ease-in-out;
  margin-top: 50px;
}
.feature-blog-two .post-meta:hover .title {
  color: var(--blue-dark);
}
.feature-blog-two .post-meta .read-more {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: rgba(10, 10, 10, 0.54);
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  transition: all 0.2s ease-in-out;
}
.feature-blog-two .post-meta .read-more i {
  font-size: 22px;
  color: var(--blue-dark);
}
.feature-blog-two .post-meta .read-more:hover {
  color: var(--blue-dark);
}
/*------------------- Contact Us Light --------------*/
.contact-us-light {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  position: relative;
  z-index: 1;
}
.contact-us-light:before {
  content: url(../images/shape/15.svg);
  position: absolute;
  left: 0;
  bottom: -140px;
}
.contact-us-light [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.contact-us-light .bubble-one {
  width: 30px;
  height: 30px;
  background: #ffdfdf;
  left: 39%;
  top: -15px;
  z-index: 2;
  animation: scale-up-one 4s infinite linear;
}
.contact-us-light .bubble-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  top: 14%;
  right: 8%;
  animation: scale-up-three 4s infinite linear;
}
.contact-us-light .bubble-three {
  width: 8px;
  height: 8px;
  background: #6aee90;
  top: 32%;
  left: 44%;
  animation: scale-up-three 4s infinite linear;
}
.contact-us-light .bubble-four {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  left: 11%;
  top: 15%;
  animation: scale-up-three 4s infinite linear;
}
.contact-us-light .address-info {
  text-align: center;
  margin-bottom: 50px;
}
.contact-us-light .address-info .icon {
  height: 82px;
}
.contact-us-light .address-info .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.contact-us-light .address-info .title {
  font-size: 18px;
  color: #8e8e8e;
  padding: 30px 0 5px;
}
.contact-us-light .address-info p {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
}
.contact-us-light .address-info ul li a {
  font-size: 22px;
  margin: 5px 15px;
  color: rgba(0, 0, 0, 0.52);
  transition: all 0.2s ease-in-out;
}
.contact-us-light .address-info ul li a:hover {
  color: var(--blue-dark);
}
.form-style-light {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(14, 49, 42, 0.05);
  padding: 100px 100px 95px;
  margin-top: 90px;
}
.form-style-light .input-group-meta {
  height: 55px;
  position: relative;
}
.form-style-light .input-group-meta.lg {
  height: 220px;
}
.form-style-light .input-group-meta input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 16px;
  border: solid 1px #d6d6d6;
  padding: 0 52px 0 20px;
  color: var(--heading);
  background: transparent;
  font-family: "Roboto", sans-serif;
}
.form-style-light .input-group-meta textarea {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 16px;
  color: var(--heading);
  border: solid 1px #d6d6d6;
  resize: none;
  padding: 20px;
  font-family: "Roboto", sans-serif;
}
.form-style-light .input-group-meta input:focus {
  border-color: var(--blue-dark);
}
.form-style-light .input-group-meta label {
  font-size: 16px;
  padding: 0 6px;
  font-weight: normal;
  position: absolute;
  left: 20px;
  line-height: 10px;
  top: -5px;
  z-index: 1;
  background: #fff;
}
.form-style-light .input-group-meta .placeholder_icon {
  position: absolute;
  line-height: 55px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  text-align: center;
  z-index: 1;
  color: rgba(0, 0, 0, 0.54);
  font-size: 17px;
  cursor: pointer;
}
.form-style-light .input-group-meta .placeholder_icon img {
  position: relative;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
}
.form-style-light .input-group-meta .valid-sign {
  opacity: 1;
  visibility: visible;
}
.form-style-light .input-group-meta input:valid + .valid-sign {
  opacity: 0;
  visibility: hidden;
}
#contact-form .form-group .help-block {
  position: absolute;
  left: 0;
  bottom: -24px;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  padding: 0 15px;
  border-radius: 3px;
  background: #dc3545;
  box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15);
}
#contact-form .form-group .help-block li {
  position: relative;
}
#contact-form .form-group .help-block li:before {
  content: "";
  font-family: "font-awesome";
  position: absolute;
  top: -12px;
  left: 0;
  color: #dc3545;
}

/*=======================================================================
                              CUSTOMER SUPPORT                
=========================================================================*/
.theme-main-menu > div {
  position: relative;
}
.theme-menu-two .logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-two .right-widget {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-two .user-login-button li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 43px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-menu-two .user-login-button li a:hover {
  color: var(--red-light);
}
.theme-menu-two .user-login-button li .signUp-action {
  border: 1px solid rgba(255, 122, 81, 0.4);
  background: rgba(255, 104, 40, 0.07);
  padding: 0 45px;
  border-radius: 22px;
  color: var(--red-light);
  margin-left: 25px;
}
.theme-menu-two .user-login-button li .signUp-action:hover {
  background: var(--red-light);
  color: #fff;
}
.theme-menu-two .language-button-group li a {
  font-size: 16px;
  font-weight: 500;
  color: #b7b7b7;
  margin: 0 4px;
}
.theme-menu-two .language-button-group li a.active {
  color: #000;
}
.theme-menu-two .language-button-group {
  margin-right: 80px;
}
/*---------------------Theme Hero Banner/Two ---------------*/
.hero-banner-two {
  padding: 90px 0 110px;
  position: relative;
  z-index: 1;
}
.hero-banner-two .container {
  max-width: 1490px;
  position: relative;
  padding: 0 40px;
}
.hero-banner-two .hero-text-wrapper h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 78px;
  line-height: 1.11em;
}
.hero-banner-two .hero-text-wrapper h1 span {
  position: relative;
}
.hero-banner-two .hero-text-wrapper h1 span img {
  left: 0;
  width: 100%;
}
.hero-banner-two .hero-text-wrapper .sub-text {
  font-size: 28px;
  line-height: 1.5em;
  color: #565657;
  padding: 40px 60px 75px 0;
}
.hero-banner-two .hero-text-wrapper form {
  max-width: 550px;
  height: 70px;
  position: relative;
}
.hero-banner-two .hero-text-wrapper form input {
  border: 2px solid #040404;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 0 200px 0 30px;
}
.hero-banner-two .hero-text-wrapper form button {
  position: absolute;
  right: 10px;
  top: 8px;
  bottom: 8px;
  background: var(--red-light);
  width: 184px;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.hero-banner-two .hero-text-wrapper form button:hover {
  background: var(--yellow-deep);
}
.hero-banner-two .hero-text-wrapper .list-item li {
  font-size: 15px;
  color: #141518;
  position: relative;
  padding-left: 22px;
  margin-right: 13px;
}
.hero-banner-two .hero-text-wrapper .list-item.lg li {
  font-size: 19px;
  line-height: 1.57em;
  position: relative;
  padding-left: 22px;
  margin-bottom: 15px;
}
.hero-banner-two .hero-text-wrapper .list-item li:before {
  content: url(../images/icon/19.svg);
  position: absolute;
  left: 5px;
  top: -1px;
}
.hero-banner-two .illustration-holder .shape-one {
  z-index: 1;
  top: 7%;
  left: -3%;
  width: 51.8%;
  animation: jumpTwo 8s infinite linear;
}
.hero-banner-two .illustration-holder .shape-two {
  z-index: 1;
  top: 40%;
  left: -11%;
  width: 54.6%;
  animation: jumpThree 8s infinite linear;
}
.hero-banner-two .illustration-holder .shape-three {
  z-index: 1;
  top: 45%;
  right: -5%;
  width: 53.2%;
  animation: jumpTwo 8s infinite linear;
}
.hero-banner-two .trusted-companies p {
  font-size: 20px;
  color: #000;
  padding-bottom: 50px;
}
.hero-banner-two .trusted-companies p span {
  font-size: 1.6em;
  font-weight: 500;
  color: #ff6559;
  text-decoration: underline;
}
/*.hero-banner-two .trusted-companies .slider-arrow li {
  width: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  font-size: 24px;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}
.hero-banner-two .trusted-companies .slider-arrow li:first-child i {transform: rotate(180deg); display: inline-block;}
.hero-banner-two .trusted-companies .slider-arrow li:hover {
  opacity: 1;
  transform: scale(1);
}*/
/*---------------------- Fancy Feature Two -------------------*/
.fancy-feature-four {
  padding: 0 15px;
}
.fancy-feature-four .inner-content {
  position: relative;
}
.fancy-feature-four .bg-wrapper {
  max-width: 1460px;
  margin: 0 auto;
  position: relative;
  background: #fff6ec;
  z-index: 1;
  padding: 100px 0 190px;
}
.fancy-feature-four .bg-wrapper:before {
  content: "";
  width: 100%;
  height: 28px;
  background: url(../images/shape/17.svg) no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: -25px;
}
.block-style-five {
  background: #fff;
  transition: all 0.3s ease-in-out;
  padding: 46px 30px 50px 48px;
  margin-top: 30px;
  border-radius: 5px;
}
.block-style-five:hover {
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.04);
  transform: translateY(-5px);
}
.block-style-five .icon {
  height: 72px;
}
.block-style-five .icon img {
  max-height: 100%;
}
.block-style-five .title {
  font-family: "Open Sans";
  font-size: 20px;
  margin: 24px 0 29px;
  display: inline-block;
  position: relative;
}
.block-style-five .title:before {
  content: "";
  width: 100%;
  height: 3px;
  background: var(--yellow-deep);
  border-radius: 2px;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.block-style-five p {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
}
.fancy-feature-four .shape-right {
  right: -16px;
  top: 30%;
}
.fancy-feature-four .shape-left {
  left: -8px;
  top: 62%;
}
.fancy-feature-four .shape-one {
  top: -20px;
  left: -64px;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-four .shape-two {
  bottom: -85px;
  right: -104px;
  animation: rotated 50s infinite linear;
}
/*---------------------- Fancy Text block Six ---------------*/
.fancy-text-block-six {
  position: relative;
}
.fancy-text-block-six .illustration-holder .shape-one {
  right: -20%;
  top: 29%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-six .img-meta-container {
  position: absolute;
  right: 0;
  top: 23px;
  max-width: 42%;
}
.fancy-text-block-six .img-meta-container .shape-one {
  top: -7%;
  left: -4%;
  animation: rotated 18s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-two {
  top: -11%;
  left: 26%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-three {
  top: -6%;
  right: 31%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-four {
  top: 44%;
  left: -7%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-five {
  bottom: -15%;
  left: -10%;
  animation: rotated 50s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-six {
  bottom: -11%;
  left: 28%;
  animation: rotated 20s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-seven {
  bottom: -12%;
  left: 57%;
  animation: jumpTwo 5s infinite linear;
}
.accordion-style-two {
  .card {
    background: #fff;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    &:not(:last-child) {
      border-bottom: 2px solid #000 !important;
    }
  }
}
.accordion-style-two .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-two .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 22px 70px 22px 0;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  line-height: 1.4em;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.accordion-style-two .card .card-header button:before,
.accordion .card .card-header button:before {
  content: "";
  font-family: "font-awesome";
  position: absolute;
  font-size: 28px;
  right: 0;
  left: auto;
  top: 30%;
  transition: 0.3s ease-in-out;
}
/*.accordion-style-two .card .card-header button[data-toggle="collapse"].collapsed:before {content: "";}*/
.accordion-style-two .card .card-body {
  padding: 0 70px 30px 0;
}
.accordion-style-two .card .card-body p {
  font-size: 20px;
  line-height: 1.55em;
}
.accordion-style-two .card:last-child {
  border: none;
}
/*------------------ Counter With Icon One --------------*/
.counter-with-icon-one .border-style {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 58px 0 90px;
}
.counter-box-three {
  text-align: center;
  margin-top: 40px;
}
.counter-box-three .icon {
  height: 62px;
  display: inline-block;
}
.counter-box-three .number {
  font-size: 38px;
  padding: 5px 0 3px;
}
.counter-box-three p {
  font-weight: 300;
  font-size: 24px;
  color: #000;
}
/*------------------- Fancy Text block Seven ----------------*/
.fancy-text-block-seven .bg-wrapper {
  max-width: 1460px;
  margin: 0 auto;
  position: relative;
  background: url(../images/shape/bg.svg) no-repeat top center;
  background-size: cover;
  z-index: 1;
  padding: 42px 0 70px;
}
.fancy-text-block-seven .bg-wrapper.no-bg {
  background: none;
}
.fancy-text-block-seven .img-holder {
  position: relative;
}
.fancy-text-block-seven .quote-wrapper blockquote {
  font-size: 42px;
  line-height: 1.35em;
  color: #000;
  padding: 22px 0 30px;
}
.fancy-text-block-seven .quote-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
}
.fancy-text-block-seven .quote-wrapper h6 span {
  display: block;
  font-size: 18px;
  color: #9eadba;
  font-weight: normal;
  padding-top: 8px;
}
.fancy-text-block-seven .shape-one {
  top: 13%;
  left: 5%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-seven .shape-two {
  top: 21%;
  right: 8%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-seven .shape-three {
  bottom: 12%;
  right: 8%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-seven .shape-four {
  bottom: 47%;
  left: 45%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-seven .shape-five {
  bottom: 12%;
  left: 8%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-seven .shape-six {
  top: 14%;
  left: -6%;
  z-index: 1;
  animation: jumpThree 5s infinite linear;
}
/*---------------- Fancy Text block Eight ---------------*/
.block-style-six {
  position: relative;
}
.block-style-six:nth-child(even):before {
  content: "";
  position: absolute;
  width: 100%;
  height: 13px;
  left: 0;
  bottom: 0;
  background: url(../images/shape/line-shape-4.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.block-style-six .text-details .title {
  font-size: 42px;
  line-height: 1.26em;
  padding: 24px 0 34px;
}
.block-style-six .text-details .text-meta {
  font-size: 22px;
  line-height: 1.54em;
  padding-bottom: 65px;
}
.block-style-six .text-details .quote-wrapper {
  background: #f4f9fc;
  border-radius: 5px;
  padding: 35px 50px 25px 35px;
  position: relative;
}
.block-style-six .text-details .quote-wrapper .quote-icon {
  width: 57px;
  height: 57px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 27px;
  top: -29px;
}
.block-style-six .text-details .quote-wrapper blockquote {
  font-size: 18px;
  line-height: 1.55em;
  color: #0d0d0d;
}
.block-style-six .text-details .name {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 24px;
  line-height: 1.44em;
}
.block-style-six .text-details .name strong {
  font-weight: 500;
  color: #191717;
}
.block-style-six .illustration-one .shape-one {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: -7%;
  right: 41%;
  animation: jumpThree 5s infinite linear;
}
.block-style-six .illustration-one .shape-two {
  width: 14px;
  height: 14px;
  background: #f77a56;
  top: 14%;
  right: -10%;
  animation: jumpTwo 5s infinite linear;
}
.block-style-six .illustration-one .shape-three {
  width: 7px;
  height: 7px;
  background: #f2dad5;
  bottom: 29%;
  right: -5%;
  animation: jumpThree 5s infinite linear;
}
.block-style-six .illustration-one .shape-four {
  width: 10px;
  height: 10px;
  background: #f77a56;
  bottom: -2%;
  right: 51%;
  animation: jumpTwo 5s infinite linear;
}
.block-style-six .illustration-one .shape-five {
  width: 7px;
  height: 7px;
  background: #f2dad5;
  top: 45%;
  left: 5%;
  animation: jumpThree 5s infinite linear;
}
.block-style-six .illustration-one .shape-six {
  top: 17%;
  left: 6%;
  animation: jumpTwo 8s infinite linear;
}
.block-style-six .illustration-one .shape-seven {
  top: 42%;
  right: -3%;
  animation: rotatedTwo 35s infinite linear;
}
.block-style-six .illustration-two .shape-one {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: -5%;
  left: 22%;
  animation: jumpThree 5s infinite linear;
}
.block-style-six .illustration-two .shape-two {
  width: 8px;
  height: 8px;
  background: #f2dad5;
  top: 63%;
  right: 12%;
  animation: jumpTwo 5s infinite linear;
}
.block-style-six .illustration-two .shape-three {
  width: 6px;
  height: 6px;
  background: #f77a56;
  bottom: -11%;
  right: 44%;
  animation: jumpThree 5s infinite linear;
}
.block-style-six .illustration-two .shape-four {
  width: 10px;
  height: 10px;
  background: #f77a56;
  bottom: 39%;
  left: -10%;
  animation: jumpTwo 5s infinite linear;
}
.block-style-six .illustration-two .shape-five {
  top: 9%;
  left: -3%;
  animation: rotatedTwo 35s infinite linear;
}
.block-style-six .illustration-two .shape-six {
  bottom: 3%;
  right: 20%;
  animation: jumpTwo 8s infinite linear;
}
/*-------------------- Useable Tools Two --------------------*/
.useable-tools-section-two.bg-shape {
  padding: 0 15px;
}
.useable-tools-section-two.bg-shape .bg-wrapper {
  max-width: 1460px;
  margin: 0 auto;
  background: #fdf3e7;
  position: relative;
  padding: 108px 0 0;
  z-index: 1;
}
.useable-tools-section-two.bg-shape .bg-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 15px;
  background-image: url(../images/shape/37.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  top: -13px;
  left: 0;
}
.useable-tools-section-two.bg-shape .bg-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 24px;
  background-image: url(../images/shape/38.svg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -23px;
  left: 0;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shapes {
  z-index: 1;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-one {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: 6%;
  left: 11%;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-two {
  width: 10px;
  height: 10px;
  background: #f77a56;
  top: 10%;
  right: 11%;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-three {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: 45%;
  right: 24%;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-four {
  width: 8px;
  height: 8px;
  background: #f77a56;
  top: 43%;
  left: 16%;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-two .icon-wrapper ul {
  margin: 0 -15px;
  position: relative;
  z-index: 1;
  transform: translateY(58px);
}
.useable-tools-section-two .icon-wrapper ul li {
  padding: 0 15px;
  float: left;
  width: 11.11111%;
}
.useable-tools-section-two .icon-wrapper ul li:nth-child(odd) {
  margin-top: 50px;
}
.useable-tools-section-two .icon-wrapper ul li:nth-child(4),
.useable-tools-section-two .icon-wrapper ul li:nth-child(6) {
  margin-top: 110px;
}
.useable-tools-section-two .icon-wrapper ul li .icon-box {
  background: #fff;
  border-radius: 10px;
  height: 100px;
  margin: 20px 0;
  cursor: pointer;
}
.useable-tools-section-two .theme-btn-two {
  border-radius: 5px;
}
/*--------------- Client Feedback Slider One -----------------*/
.client-feedback-slider-one {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}
.client-feedback-slider-one .shapes-holder {
  position: absolute;
  top: 60px;
  left: -35px;
  width: 40%;
}
.client-feedback-slider-one .shapes-holder .title-style-two {
  position: absolute;
  right: 0;
  top: 43%;
  transform: translateY(-50%);
  z-index: 5;
}
.client-feedback-slider-one .shapes-holder .shape-one {
  z-index: 1;
  top: -11%;
  right: 21%;
  animation: jumpTwo 8s infinite linear;
}
.client-feedback-slider-one .shapes-holder [class*="cp-img"] {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
}
.client-feedback-slider-one .shapes-holder .cp-img-one {
  width: 60px;
  height: 60px;
  top: 13%;
  left: 26%;
}
.client-feedback-slider-one .shapes-holder .cp-img-two {
  width: 75px;
  height: 75px;
  top: 46%;
  left: 13%;
}
.client-feedback-slider-one .shapes-holder .cp-img-three {
  width: 50px;
  height: 50px;
  bottom: 7%;
  left: 25%;
}
.client-feedback-slider-one .shapes-holder .cp-img-four {
  width: 80px;
  height: 80px;
  bottom: 22%;
  right: 21%;
}
.client-feedback-slider-one .feedback-meta {
  position: relative;
  padding-top: 138px;
}
.client-feedback-slider-one .feedback-meta .watermark {
  font-size: 160px;
  color: #f9fafc;
  position: absolute;
  top: 0;
  left: 0;
}
.client-feedback-slider-one .clientSliderOne p {
  font-size: 24px;
  line-height: 1.87em;
  padding: 52px 0 60px;
}
.client-feedback-slider-one .clientSliderOne .c_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.client-feedback-slider-one .clientSliderOne .info {
  padding-left: 20px;
}
.client-feedback-slider-one .clientSliderOne .info strong {
  font-weight: 500;
  font-size: 24px;
  color: #252525;
  display: block;
}
.client-feedback-slider-one .clientSliderOne .info span {
  color: #8a8a8a;
}
.client-feedback-slider-one .slider-arrow li {
  width: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 15px;
  font-size: 24px;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}
.client-feedback-slider-one .slider-arrow li:first-child i {
  transform: rotate(180deg);
  display: inline-block;
}
.client-feedback-slider-one .slider-arrow li:hover {
  opacity: 1;
  transform: scale(1);
}
/*----------------- Fancy Short Banner Three -------------------*/
.fancy-short-banner-three .bg-wrapper {
  background: url(../images/shape/bg2.svg) no-repeat;
  background-size: cover;
  border-radius: 40px;
  padding: 82px 50px 89px;
  position: relative;
}
.fancy-short-banner-three .bg-wrapper:before {
  content: url(../images/shape/43.svg);
  position: absolute;
  top: -124px;
  right: -104px;
  z-index: -1;
}
.fancy-short-banner-three .title-style-one h2 {
  font-size: 48px;
}
.fancy-short-banner-three .form-wrapper {
  padding-left: 65px;
}
.fancy-short-banner-three .form-wrapper form {
  position: relative;
  height: 70px;
  background: #fff;
  border-radius: 7px;
}
.fancy-short-banner-three .form-wrapper form input {
  font-style: italic;
  font-size: 18px;
  width: 100%;
  border: none;
  height: 100%;
  padding: 0 150px 0 25px;
  background: transparent;
}
.fancy-short-banner-three .form-wrapper form button {
  position: absolute;
  right: 8px;
  bottom: 8px;
  top: 8px;
  width: 150px;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  background: var(--red-light);
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-three .form-wrapper form button:hover {
  background: #ffba12;
  box-shadow: 0px 15px 30px rgba(255, 186, 18, 0.2);
}
.fancy-short-banner-three .form-wrapper p {
  font-size: 16px;
  padding-top: 10px;
}
.fancy-short-banner-three .form-wrapper p a {
  color: #000;
}
.fancy-short-banner-three .form-wrapper p a:hover {
  text-decoration: underline;
}
/*----------------- Footer Style Two --------------*/
.theme-footer-two {
  position: relative;
  overflow: hidden;
}
.theme-footer-two:before {
  content: url(../images/shape/44.svg);
  position: absolute;
  bottom: -10px;
  left: 11%;
  z-index: -1;
}
.theme-footer-two:after {
  content: " ";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #f77a56;
  bottom: 19%;
  right: 30%;
  z-index: -1;
}
.theme-footer-two .top-footer [class*="col-"] {
  margin-bottom: 30px;
}
.theme-footer-two .top-footer .footer-title {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  padding-bottom: 25px;
}
.theme-footer-two .top-footer .footer-list ul li a {
  font-size: 16px;
  line-height: 38px;
  color: #727272;
  transition: all 0.3s ease-in-out;
}
.theme-footer-two .top-footer .footer-list ul li a:hover {
  color: var(--red-light);
}
.theme-footer-two .top-footer .address-list ul.info li a {
  font-size: 16px;
  color: #727272;
  margin-bottom: 10px;
}
.theme-footer-two .top-footer .address-list ul.info li a:hover {
  text-decoration: underline;
}
.theme-footer-two .top-footer .address-list ul.info li a.mobile-num {
  font-size: 20px;
  color: #030303;
}
.theme-footer-two .top-footer .address-list .social-icon a {
  font-size: 22px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.55);
}
.theme-footer-two .top-footer .address-list .social-icon a:hover {
  color: #000;
}
.theme-footer-two .bottom-footer-content ul li {
  display: inline-block;
}
.theme-footer-two .bottom-footer-content ul li a {
  font-size: 16px;
  color: #727272;
  margin-left: 20px;
}
.theme-footer-two .bottom-footer-content ul li a:hover {
  color: #000;
}
.theme-footer-two .bottom-footer-content p {
  font-size: 15px;
  color: #6a6a6a;
}
.theme-footer-two .bottom-footer-content {
  padding: 26px 0 30px;
}
.theme-footer-two .bottom-footer-content ul {
  padding-right: 97px;
}
/*--------------------- Fancy Hero Two --------------------*/
.fancy-hero-two {
  padding: 0 70px;
}
.fancy-hero-two .bg-wrapper {
  background: url(../images/shape/bg3.svg) no-repeat center;
  background-size: cover;
  border-radius: 20px;
  padding: 108px 0 110px;
  text-align: center;
}
.fancy-hero-two .page-title {
  font-size: 24px;
  color: #b1b1b1;
}
.fancy-hero-two .heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 80px;
  line-height: 1.1em;
  padding: 0 0 38px;
}
.fancy-hero-two .sub-heading {
  font-size: 24px;
  line-height: 1.41em;
  color: #2a2a2a;
}
/*------------------ Fancy Text block Nine ----------------*/
.fancy-text-block-nine {
  position: relative;
}
.fancy-text-block-nine .text-meta {
  font-size: 24px;
  line-height: 1.66em;
  padding-top: 40px;
  text-align: center;
  color: #515151;
}
.fancy-text-block-nine .shape-one {
  width: 10px;
  height: 10px;
  background: #ffdbd0;
  top: 5%;
  right: 13%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-nine .shape-two {
  width: 6px;
  height: 6px;
  background: #f77a56;
  bottom: 9%;
  right: 13%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-nine .shape-three {
  width: 8px;
  height: 8px;
  background: #ffdbd0;
  bottom: 9%;
  left: 13%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-nine .shape-four {
  width: 11px;
  height: 11px;
  background: #ff7052;
  top: 12%;
  left: 13%;
  animation: jumpThree 5s infinite linear;
}
/*------------------ Fancy Text block Ten ----------------*/
.fancy-text-block-ten .container {
  max-width: 1300px;
  padding: 0 15px;
}
.fancy-text-block-ten p {
  font-size: 42px;
  line-height: 1.35em;
  color: #000000;
  padding: 25px 0 50px;
}
.fancy-text-block-ten .name {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  position: relative;
  padding-left: 28px;
}
.fancy-text-block-ten .name span {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: normal;
}
.fancy-text-block-ten .name:before {
  content: "";
  width: 15px;
  height: 3px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 14px;
}
.fancy-text-block-ten .img-meta {
  border-radius: 5px;
  margin: 0 auto;
}
.fancy-text-block-ten .img-gallery {
  position: relative;
}
.fancy-text-block-ten .img-gallery:before {
  content: url(../images/shape/45.svg);
  position: absolute;
  top: -14%;
  right: -5%;
  z-index: 1;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-ten .img-gallery:after {
  content: url(../images/shape/46.svg);
  position: absolute;
  bottom: -12%;
  left: -12%;
  z-index: -1;
  animation: rotatedTwo 50s infinite linear;
}
/*-------------------- Fancy Feature Five -------------------*/
.fancy-feature-five {
  padding: 0 70px;
}
.fancy-feature-five .bg-wrapper {
  background: #f2f7ff;
  border-radius: 10px;
  padding: 120px 0 140px;
  position: relative;
  z-index: 5;
}
.block-style-seven {
  padding: 40px 22px 0;
  text-align: center;
}
.block-style-seven .icon {
  height: 80px;
}
.block-style-seven .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.block-style-seven .feature-info {
  color: rgba(0, 0, 0, 0.63);
  padding: 30px 0 7px;
}
.block-style-seven p {
  font-size: 28px;
  line-height: 1.35em;
  color: #000;
}
.fancy-feature-five .shape-one {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: 14%;
  right: 15%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-five .shape-two {
  width: 12px;
  height: 12px;
  background: #f77a56;
  top: 58%;
  right: 6%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-five .shape-three {
  width: 7px;
  height: 7px;
  background: #f2dad5;
  top: 84%;
  left: 7%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-five .shape-four {
  width: 8px;
  height: 8px;
  background: #f77a56;
  top: 27%;
  left: 8%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-five .shape-five {
  bottom: 0;
  right: 0;
}
/*------------------ Team Section Two --------------*/
.team-section-two {
  position: relative;
}
.team-section-two .shape-one {
  width: 11px;
  height: 11px;
  background: #f77a56;
  right: 11%;
  top: 42%;
  animation: jumpThree 5s infinite linear;
}
.team-section-two .shape-two {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  right: 11%;
  top: 87%;
  animation: jumpThree 5s infinite linear;
}
.team-section-two .shape-three {
  width: 10px;
  height: 10px;
  background: #f77a56;
  left: 13%;
  top: 75%;
  animation: jumpTwo 5s infinite linear;
}
.team-section-two .shape-four {
  width: 8px;
  height: 8px;
  background: #f2dad5;
  left: 12%;
  top: 24%;
  animation: jumpThree 5s infinite linear;
}
.team-section-two .team-member {
  text-align: center;
  margin-top: 50px;
  width: 100%;
}
.team-section-two .team-member img {
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.team-section-two .team-member:hover img {
  transform: translateY(8px);
}
.team-section-two .team-member .name {
  font-size: 24px;
  font-weight: 500;
  color: #2a2a2a;
  padding: 35px 0 0;
}
.team-section-two .team-member .position {
  font-size: 16px;
  color: rgba(42, 42, 42, 0.54);
}
/*---------------- Fancy Hero Three --------------*/
.fancy-hero-three {
  background: #fff7ef;
  position: relative;
  padding: 225px 0 180px;
  z-index: 5;
  text-align: center;
}
.fancy-hero-three:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 24px;
  background: url(../images/shape/48.svg) no-repeat center;
  background-size: cover;
  left: 0;
  bottom: -20px;
}
.fancy-hero-three.bg-transparent:before {
  display: none;
}
.fancy-hero-three .shape-one {
  width: 6px;
  height: 6px;
  background: #f77a56;
  top: 29%;
  right: 27%;
  animation: jumpThree 5s infinite linear;
}
.fancy-hero-three .shape-two {
  width: 12px;
  height: 12px;
  background: #ffdbd0;
  top: 59%;
  right: 12%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-hero-three .shape-three {
  width: 8px;
  height: 8px;
  background: #f77a56;
  top: 84%;
  right: 25%;
  animation: jumpThree 5s infinite linear;
}
.fancy-hero-three .shape-four {
  width: 10px;
  height: 10px;
  background: #ffdbd0;
  top: 79%;
  left: 21%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-hero-three .shape-five {
  width: 6px;
  height: 6px;
  background: #f77a56;
  top: 50%;
  left: 12%;
  animation: jumpThree 5s infinite linear;
}
.fancy-hero-three .shape-six {
  width: 11px;
  height: 11px;
  background: #ff7052;
  top: 27%;
  left: 21%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-hero-three .heading {
  font-family: "gilroy-black";
  font-size: 80px;
  line-height: 1.1em;
  padding: 0 0 38px;
}
.fancy-hero-three .sub-heading {
  font-size: 24px;
  line-height: 1.41em;
  color: #2a2a2a;
}
.fancy-hero-three .trial-button {
  border-radius: 6px;
}
/*---------------- Fancy Text block Eleven -----------------*/
.fancy-text-block-eleven .text-wrapper {
  padding: 50px 0 0 65px;
}
.fancy-text-block-eleven .text-wrapper p {
  font-size: 22px;
  line-height: 1.81em;
}
.fancy-text-block-eleven .img-gallery {
  position: relative;
  display: inline-block;
}
.fancy-text-block-eleven .img-gallery .overlay-img {
  position: absolute;
  right: -130px;
  top: -70px;
  z-index: 1;
}
.fancy-text-block-eleven .img-gallery .shape-one {
  top: -13%;
  left: -16%;
}
.fancy-text-block-eleven .img-gallery .shape-two {
  bottom: -19%;
  right: -21%;
  animation: rotatedTwo 50s infinite linear;
}
.fancy-text-block-eleven .img-gallery .shape-three {
  bottom: -9%;
  left: -16%;
  animation: jumpThree 5s infinite linear;
}
/*--------------- Fancy Feature Six -------------*/
.fancy-feature-six {
  padding: 0 15px;
}
.fancy-feature-six .bg-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 110px 0 210px;
  background: url(../images/shape/bg4.svg) no-repeat top center;
  background-size: cover;
  position: relative;
  z-index: 5;
}
.fancy-feature-six .bg-wrapper .shape-one {
  width: 16px;
  height: 16px;
  background: #f96f60;
  z-index: 1;
  top: 5px;
  left: 14%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-two {
  width: 12px;
  height: 12px;
  background: #f96f60;
  top: 36%;
  right: 4%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-three {
  width: 7px;
  height: 7px;
  background: #f96f60;
  top: 68%;
  right: 4%;
  opacity: 0.25;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-four {
  width: 10px;
  height: 10px;
  background: #f96f60;
  top: 91%;
  left: 7%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-five {
  width: 7px;
  height: 7px;
  background: #f96f60;
  top: 31%;
  left: 4%;
  opacity: 0.25;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-six {
  top: 78%;
  right: 2%;
  animation: rotatedTwo 50s infinite linear;
}
/*----------------- Fancy Text block Twelve -------------------*/
.fancy-text-block-twelve .text-wrapper .sub-text {
  font-size: 22px;
  line-height: 1.81em;
  padding-top: 40px;
}
.fancy-text-block-twelve .img-gallery {
  display: inline-block;
  padding: 30px 45px 0 25px;
  position: relative;
}
.fancy-text-block-twelve .img-gallery .shape-one {
  top: -7%;
  left: -10%;
}
.fancy-text-block-twelve .img-gallery .shape-two {
  bottom: -13%;
  right: -4%;
  animation: jumpTwo 5s infinite linear;
}
.block-style-eight {
  background: #fff;
  transition: all 0.3s ease-in-out;
  padding: 20px 15px 25px 32px;
  margin-top: 30px;
  border: 2px solid #dce4e8;
  border-radius: 5px;
}
.block-style-eight:hover {
  border-color: #000;
}
.block-style-eight .title {
  font-family: "Open Sans";
  font-size: 20px;
  margin: 0 0 24px;
  display: inline-block;
  position: relative;
}
.block-style-eight .title:before {
  content: "";
  width: 100%;
  height: 3px;
  background: var(--yellow-deep);
  border-radius: 2px;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.block-style-eight p {
  line-height: 1.72em;
  color: #000;
}
/*----------------- Fancy Feature Seven --------------*/
.fancy-feature-seven {
  background: #ecf6ff;
  padding: 170px 0 150px;
  position: relative;
  z-index: 1;
}
.fancy-feature-seven:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 45px;
  background: url(../images/shape/58.svg) no-repeat top center;
  background-size: cover;
  left: 0;
  top: -42px;
}
.fancy-feature-seven:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 23px;
  background: url(../images/shape/59.svg) no-repeat top center;
  background-size: cover;
  left: 0;
  bottom: -20px;
}
.block-style-nine {
  position: relative;
}
.block-style-nine .text-wrapper h6 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.63);
  text-transform: uppercase;
  letter-spacing: 1px;
}
.block-style-nine .text-wrapper .title {
  font-size: 42px;
  line-height: 1.28em;
  padding: 5px 0 30px;
}
.block-style-nine .text-wrapper p {
  font-size: 20px;
  line-height: 1.8em;
  color: rgba(14, 14, 14, 0.8);
}
.block-style-nine:nth-child(1) {
  padding-bottom: 30px;
}
.block-style-nine:nth-child(1):before {
  content: url(../images/shape/55.svg);
  position: absolute;
  left: 33%;
  bottom: -14%;
}
.block-style-nine:nth-child(2) {
  padding-bottom: 30px;
}
.block-style-nine:nth-child(2):before {
  content: url(../images/shape/56.svg);
  position: absolute;
  right: 33%;
  bottom: -26%;
}
.block-style-nine:nth-child(3) {
  padding-bottom: 60px;
}
.block-style-nine:nth-child(3):before {
  content: url(../images/shape/57.svg);
  position: absolute;
  left: 36%;
  bottom: -16%;
}
/*------------------ Fancy Text Block Thirteen ---------------*/
.fancy-text-block-thirteen .text-wrapper p {
  font-size: 24px;
  line-height: 1.7em;
  padding-top: 45px;
}
/*------------------ Fancy Text Block Fourteen ---------------*/
.fancy-text-block-fourteen .text-wrapper .sub-text {
  font-size: 24px;
  line-height: 1.58em;
  color: #0e0e0e;
  padding-top: 40px;
}
.fancy-text-block-fourteen .text-wrapper .name {
  font-family: "Open Sans";
  font-size: 24px;
  color: #000;
  position: relative;
  padding-left: 28px;
  margin-top: 40px;
}
.fancy-text-block-fourteen .text-wrapper .name span {
  font-family: "gilroy-semibold";
  color: #8a8a8a;
}
.fancy-text-block-fourteen .text-wrapper .name:before {
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 13px;
}
.fancy-text-block-fourteen .img-holder {
  position: relative;
  padding: 0 50px 0 60px;
}
.fancy-text-block-fourteen .img-holder .shape-one {
  right: 0;
  top: -10%;
}
.fancy-text-block-fourteen .img-holder .shape-two {
  left: -1%;
  top: -12%;
  z-index: 1;
  animation: jumpTwo 5s infinite linear;
}
/*------------------- Pricing Section One ------------------*/

.pricing-nav-one {
  margin: 40px 0 20px;
  border: none;
  &.pricing-custom-nav-one {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -70px;
    z-index: 5;
    @media only screen and (max-width: 991px) {
      top: -35px;
    }
  }
}
.pricing-nav-one .nav-item {
  margin: 0;
}
.pricing-nav-one .nav-item .nav-link,
.pricing-nav-one .react-tabs__tab {
  font-family: "Roboto", sans-serif;
  line-height: 50px;
  border: 2px solid var(--blue-dark);
  padding: 0 15px;
  width: 172px;
  text-align: center;
  cursor: pointer;
  color: var(--blue-dark);
  transition: all 0.3s ease-in-out;
}

.pricing-nav-one .nav-item:nth-child(2) .nav-link,
.pricing-nav-one #react-tabs-2 {
  border-radius: 0 3px 3px 0;
}
.pricing-nav-one .nav-item .nav-link.active,
.pricing-nav-one .react-tabs__tab.react-tabs__tab--selected {
  background: var(--blue-dark);
  color: #fff;
}
.pricing-section-one .offer-text {
  text-align: center;
  color: var(--blue-dark);
}
.pricing-section-one .pricing-table-area {
  position: relative;
  margin-top: -40px;
  padding-bottom: 130px;
}
.pricing-section-one .pricing-table-area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 78%;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(45deg, #fffbf2, #edfffd);
}
.pricing-section-one .pricing-table-area .shape-one {
  left: 0;
  top: 7%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-one .pricing-table-area .shape-two {
  right: 0;
  bottom: -140px;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-one .pr-table-wrapper {
  background: #fff;
  box-shadow: 0 -10px 40px rgba(60, 67, 113, 0.05);
  padding: 35px 0 25px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.pricing-section-one .pr-table-wrapper:hover {
  transform: translateY(-5px);
}
.pricing-section-one .pr-table-wrapper .pack-name {
  font-family: "gilroy-semibold";
  font-size: 18px;
  text-transform: uppercase;
  color: #202020;
  letter-spacing: 2.1px;
}
.pricing-section-one .pr-table-wrapper .price {
  font-size: 60px;
  color: #000;
  padding: 20px 0 27px;
}
.pricing-section-one .pr-table-wrapper .price sup {
  font-size: 25px;
  top: -21px;
}
.pricing-section-one .pr-table-wrapper .icon {
  margin: 28px auto 20px;
  height: 102px;
}
.pricing-section-one .pr-table-wrapper .bill-cycle {
  font-size: 17px;
  color: #464646;
}
.pricing-section-one .pr-table-wrapper .pr-feature {
  text-align: left;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 46px 0 36px 20px;
  margin: 19px 0 33px;
}
.pricing-section-one .pr-table-wrapper .pr-feature li {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 42px;
  color: #464646;
  padding-left: 30px;
  position: relative;
}
.pricing-section-one .pr-table-wrapper .pr-feature li:before {
  content: url(../images/icon/39.svg);
  position: absolute;
  left: 0;
  top: 2px;
}
.pricing-section-one .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.58);
}
/*----------------- Fancy Text Block Fifteen --------------*/
.fancy-text-block-fifteen {
  position: relative;
  padding-bottom: 170px;
}
.fancy-text-block-fifteen:before {
  content: "";
  width: 100%;
  height: 82%;
  background: #fafaff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.fancy-text-block-fifteen .shape-one {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff6cc4;
  right: 13%;
  top: 28%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-fifteen .shape-two {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f5a623;
  right: 13%;
  bottom: 28%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-fifteen .shape-three {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #b183ff;
  left: 11%;
  bottom: 16%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-fifteen .shape-four {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6aee90;
  left: 11%;
  top: 42%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-fifteen .bg-wrapper {
  background: #5e43f0;
  border-radius: 10px;
  padding: 85px 60px 0 15px;
}
.fancy-text-block-fifteen .bg-wrapper .main-img {
  margin: 0 auto;
}
.fancy-text-block-fifteen .text-wrapper .more-text {
  font-family: "gilroy-semibold";
  font-size: 20px;
  color: #fff;
  padding: 28px 0 20px;
}
.fancy-text-block-fifteen .text-wrapper .more-text span {
  text-decoration: underline;
}
.fancy-text-block-fifteen .text-wrapper p {
  font-size: 40px;
  line-height: 1.5em;
  color: #fff;
}
.fancy-text-block-fifteen .text-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  padding-top: 37px;
}
.fancy-text-block-fifteen .text-wrapper h6 span {
  font-weight: normal;
  font-size: 18px;
}
.fancy-text-block-fifteen .contact-banner p {
  font-size: 42px;
  line-height: 1.42em;
  color: #000;
}
.fancy-text-block-fifteen .contact-banner .theme-btn-four {
  line-height: 55px;
  width: 220px;
  font-size: 22px;
  text-align: center;
  display: block;
}
/*-------------------- Fancy Hero Four ---------------------*/
.fancy-hero-four {
  background: #ecf6ff;
  position: relative;
  padding: 185px 0 390px;
  z-index: 5;
  text-align: center;
}
.fancy-hero-four.bg-event {
  background: #fbf3ec;
}
.fancy-hero-four.bg-doc {
  background: #f7faff;
}
.fancy-hero-four.space-fix {
  padding: 220px 0 320px;
}
.fancy-hero-four h6 {
  font-size: 18px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: rgba(42, 42, 42, 0.52);
  padding-bottom: 25px;
}
.fancy-hero-four h2 {
  font-family: "gilroy-black";
  font-size: 80px;
  line-height: 1.1em;
}
.fancy-hero-four .sub-heading {
  font-size: 24px;
  line-height: 1.75em;
  padding: 55px 0 15px;
  color: #2a2a2a;
}
.fancy-hero-four .shape-one {
  width: 6px;
  height: 6px;
  background: #f77a56;
  top: 29%;
  right: 27%;
  animation: jumpThree 5s infinite linear;
}
.fancy-hero-four .shape-two {
  width: 12px;
  height: 12px;
  background: #ffdbd0;
  top: 59%;
  right: 12%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-hero-four .shape-three {
  width: 8px;
  height: 8px;
  background: #f77a56;
  top: 84%;
  right: 25%;
  animation: jumpThree 5s infinite linear;
}
.fancy-hero-four .shape-four {
  width: 10px;
  height: 10px;
  background: #ffdbd0;
  top: 79%;
  left: 21%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-hero-four .shape-five {
  width: 6px;
  height: 6px;
  background: #f77a56;
  top: 50%;
  left: 12%;
  animation: jumpThree 5s infinite linear;
}
.fancy-hero-four .shape-six {
  width: 11px;
  height: 11px;
  background: #ff7052;
  top: 27%;
  left: 21%;
  animation: jumpTwo 5s infinite linear;
}
/*--------------------- Pricing Section Two -----------------*/
.pricing-nav-two {
  margin: 40px 0 20px;
  border: none;
}
.pricing-nav-two .nav-item {
  margin: 0;
}
.pricing-nav-two .nav-item .nav-link,
.pricing-nav-two .react-tabs__tab {
  font-family: "Roboto", sans-serif;
  line-height: 50px;
  border: 2px solid var(--red-light);
  padding: 0 15px;
  width: 172px;
  text-align: center;
  color: var(--red-light);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.pricing-nav-two .nav-item:nth-child(1) .nav-link,
.pricing-nav-two:nth-child(1) .react-tabs__tab {
  border-radius: 3px 0 0 3px;
}
.pricing-nav-two .nav-item:nth-child(2) .nav-link,
.pricing-nav-two #react-tabs-2 {
  border-radius: 0 3px 3px 0;
}
.pricing-nav-two .nav-item .nav-link.active,
.pricing-nav-two .react-tabs__tab.react-tabs__tab--selected {
  background: var(--red-light);
  color: #fff;
}
.pricing-section-two .pricing-table-area {
  position: relative;
  margin-top: -280px;
  z-index: 6;
  border: 1px solid #f1f1f1;
  border-right: none;
}
.pricing-section-two .pricing-table-area .tab-content {
  background: #fff;
}
.pricing-section-two .pricing-table-area .pr-bg {
  border-right: 1px solid #f1f1f1;
}
.pricing-section-two .pr-table-wrapper {
  text-align: center;
  padding: 20px 0 40px;
  width: 100%;
}
.pricing-section-two .pr-table-wrapper .pack-name {
  font-family: "gilroy-semibold";
  font-size: 18px;
  text-transform: uppercase;
  color: #202020;
  letter-spacing: 2.1px;
}
.pricing-section-two .pr-table-wrapper .price {
  font-family: "gilroy-semibold";
  font-size: 72px;
  color: #000;
  padding: 44px 0 20px;
}
.pricing-section-two .pr-table-wrapper .price sup {
  font-size: 25px;
  top: -45px;
}
.pricing-section-two .pr-table-wrapper .bill-cycle {
  font-size: 18px;
  color: #000;
  margin-bottom: 22px;
}
.pricing-section-two .pr-table-wrapper .theme-btn-three {
  line-height: 42px;
  padding: 0 40px;
}
.pricing-section-two .pr-table-wrapper .pr-feature {
  text-align: left;
  border-top: 1px solid #e8e8e8;
  padding: 34px 0 0 26px;
  margin: 35px 0 0;
}
.pricing-section-two .pr-table-wrapper .pr-feature li {
  font-size: 17px;
  line-height: 39px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 26px;
  position: relative;
}
.pricing-section-two .pr-table-wrapper .pr-feature li:before {
  content: url(../images/icon/43.svg);
  position: absolute;
  left: 0;
  top: 2px;
}
.pricing-section-two .pricing-table-area .shape-one {
  left: -7%;
  top: -7%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-two .pricing-table-area .shape-two {
  right: -7%;
  bottom: -9%;
  animation: rotatedTwo 50s infinite linear;
}
/*------------------- FAQ Section -------------------*/
.accordion-style-three .card {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #000000 !important;
}
.accordion-style-three .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-three .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 35px 35px 35px 0;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid transparent;
}
.accordion-style-three .card:first-child .card-header button {
  border-top: 1px solid #000000;
}
.accordion-style-three .card .card-header button:before {
  content: "\f107";
  font-family: "font-awesome";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.accordion-style-three .card .card-body {
  border-bottom: 1px solid #000000;
  padding: 0 50px 20px 0;
}
.accordion-style-three .card .card-body p {
  padding-bottom: 15px;
}
.accordion-style-three .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}
.accordion-style-three .card:last-child .card-body {
  border: none;
  padding-bottom: 0;
}
.more-faq-meta h3 {
  font-size: 36px;
}
.more-faq-meta a {
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  border: 1px solid rgba(255, 122, 81, 0.4);
  background: rgba(255, 104, 40, 0.07);
  padding: 0 45px;
  border-radius: 22px;
  color: var(--red-light);
  transition: all 0.3s ease-in-out;
}
.more-faq-meta a:hover {
  background: var(--red-light);
  color: #fff;
}
/*-------------- Contact Style Two --------------*/
.contact-style-two .contact-info-wrapper {
  position: relative;
  z-index: 5;
  margin-top: -195px;
}
.contact-style-two .contact-info-wrapper .shape-one {
  left: -7%;
  top: -18%;
  animation: jumpTwo 5s infinite linear;
}
.contact-style-two .contact-info-wrapper .shape-two {
  right: -8%;
  bottom: -26%;
  animation: rotatedTwo 50s infinite linear;
}
.contact-style-two .contact-info-wrapper .address-info {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);
  padding: 55px 15px 22px;
  text-align: center;
  margin-bottom: 35px;
  height: 100%;
  width: 100%;
}
.contact-style-two .contact-info-wrapper .address-info .icon {
  height: 82px;
}
.contact-style-two .contact-info-wrapper .address-info .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.contact-style-two .contact-info-wrapper .address-info .title {
  font-size: 20px;
  color: #8e8e8e;
  padding: 17px 0 22px;
  position: relative;
  display: inline-block;
}
.contact-style-two .contact-info-wrapper .address-info .title:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #ffb840;
  border-radius: 2px;
  position: absolute;
  left: 0;
  bottom: 22px;
}
.contact-style-two .contact-info-wrapper .address-info p {
  font-size: 28px;
  line-height: 1.35em;
  color: #000;
}
.contact-style-two .contact-info-wrapper .address-info ul li a {
  font-size: 22px;
  margin: 5px 15px;
  color: rgba(0, 0, 0, 0.52);
  transition: all 0.2s ease-in-out;
}
.contact-style-two .contact-info-wrapper .address-info ul li a:hover {
  color: var(--red-light);
}

.form-style-classic .input-group-meta {
  height: 58px;
  position: relative;
}
.form-style-classic .input-group-meta.lg {
  height: 200px;
}
.form-style-classic .input-group-meta input {
  width: 100%;
  height: 100%;
  font-size: 23px;
  border: none;
  border-bottom: solid 2px #000;
  color: var(--heading);
  background: transparent;
  font-family: "Roboto", sans-serif;
}
.form-style-classic .input-group-meta textarea {
  width: 100%;
  height: 100%;
  font-size: 23px;
  color: var(--heading);
  border: none;
  border-bottom: solid 2px #000;
  resize: none;
  padding: 20px 0;
  font-family: "Roboto", sans-serif;
}
.form-style-classic .input-group-meta label {
  font-size: 14px;
  font-weight: normal;
  color: #bfbfbf;
  position: absolute;
  left: 0;
  top: -20px;
  z-index: 1;
}
.form-style-classic [class*="theme-btn"] {
  border-radius: 5px;
}
/*---------------------- Faqs -------------------*/
.faqs-inner-page {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 170px 0 185px;
  position: relative;
  z-index: 5;
}
.faqs-header .icon {
  height: 55px;
  margin-right: 32px;
}
.faqs-header h3 {
  font-family: "gilroy-semibold";
  font-size: 36px;
}
.faqs-header .collection-preview {
  font-size: 20px;
  line-height: 1.6em;
  padding-bottom: 25px;
}
.faqs-header .avatar {
  margin-top: -7px;
}
.faqs-header .avatar img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 3px white;
  box-shadow: 0 0 0 3px white;
  position: relative;
}
.faqs-header .avatar img:nth-child(n + 2) {
  z-index: 1;
  margin-left: -10px;
}
.faqs-header .avatar_fallback {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--blue-dark);
  border: 3px solid #fff;
  line-height: 42px;
  color: #fff;
  font-size: 17px;
  z-index: 5;
  margin-left: -10px;
  text-align: center;
}
.faqs-header .collection-info {
  font-size: 16px;
  line-height: 1.5em;
  padding: 10px 0 0 0;
  color: #878787;
}
.faqs-header .collection-info span {
  color: var(--heading);
  font-weight: 500;
}
.all-faqs .faqs-all-qus {
  margin-bottom: 70px;
}
.all-faqs .faqs-all-qus .article-preview {
  background: #fff;
  padding: 45px 40px;
  margin-bottom: 18px;
  box-shadow: 0 15px 30px rgba(23, 59, 40, 0.05);
  h3 {
    transition: 0.3s;
  }
  &:hover {
    h3 {
      color: var(--blue-dark);
    }
  }
}

.faqs-all-qus .article-preview .avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 22px;
}
.faqs-all-qus .article-preview h3 {
  font-size: 24px;
  padding-bottom: 10px;
}
.faqs-all-qus .article-preview .avatar-info {
  font-size: 16px;
  line-height: 1.63em;
  color: #606060;
}
.faqs-all-qus .article-preview .avatar-info span {
  font-weight: 500;
}
.faqs-all-qus .article-preview .article-details {
  border-top: 1px solid #d8d8d8;
  margin-top: 46px;
  padding: 38px 0 48px;
  color: rgba(0, 0, 0, 0.8);
}
.faqs-all-qus .article-preview .article-details .list-meta {
  padding: 31px 0 32px 43px;
}
.faqs-all-qus .article-preview .article-details .list-meta li {
  padding-left: 25px;
  position: relative;
  margin-bottom: 20px;
}
.faqs-all-qus .article-preview .article-details .list-meta li:before {
  content: url(../images/icon/49.svg);
  position: absolute;
  left: 0;
  top: 0;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper {
  border: 2px solid #979797;
  border-radius: 5px;
  text-align: center;
  padding: 50px 15px;
  margin-top: 68px;
  position: relative;
  z-index: 1;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper h4 {
  font-size: 24px;
  padding-bottom: 24px;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper button {
  margin: 0 11px;
  transition: all 0.2s ease-in-out;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper button:hover {
  transform: translateY(-3px);
}
.faqs-inner-page .more-faq-ask h3 {
  font-size: 36px;
}
.faqs-inner-page .shape-one {
  left: 0;
  bottom: -140px;
  animation: jumpTwo 5s infinite linear;
}
.faqs-inner-page .shape-two {
  width: 30px;
  height: 30px;
  background: #ffdfdf;
  border-radius: 50%;
  top: -15px;
  left: 40%;
}
.faqs-inner-page .shape-three {
  width: 7px;
  height: 7px;
  background: #51fcff;
  border-radius: 50%;
  top: 190px;
  right: 9%;
}
.faqs-inner-page .shape-four {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  border-radius: 50%;
  top: 211px;
  left: 12%;
}
/*------------------ Login/Sign Up ----------------------*/
.user-data-page .illustration-wrapper {
  float: left;
  width: 48%;
  padding: 40px 0;
  min-height: 100vh;
  background: #f8fafe;
  text-align: center;
}
.user-data-page .illustration-wrapper h3 {
  font-size: 36px;
  line-height: 1.27em;
  padding: 40px 0;
}
.user-data-page .illustration-wrapper h3 a {
  text-decoration: underline;
}
.user-data-page .illustration-wrapper .illustration-holder {
  width: 100%;
}
.user-data-page .illustration-wrapper .illustration-holder .illustration {
  width: 100%;
}
.user-data-page .illustration-wrapper .illustration-holder .shape-one {
  bottom: 2%;
  left: 2%;
  z-index: 1;
  width: 60%;
  animation: jumpTwo 5s infinite linear;
}
.user-data-page .illustration-wrapper .illustration-holder .shape-two {
  bottom: 2%;
  left: 59%;
  z-index: 1;
  width: 40%;
  animation: jumpThree 5s infinite linear;
}
.user-data-page .form-wrapper {
  float: left;
  width: 47%;
  height: 100%;
  padding: 50px 4% 20px;
  position: relative;
}
.user-data-page .go-back-button {
  font-size: 15px;
}
.user-data-page .go-back-button:hover {
  color: #000;
  text-decoration: underline;
}
.user-data-page .form-wrapper h2 {
  font-family: "Open Sans";
  font-size: 58px;
  line-height: 1.17em;
}
.user-data-page .form-wrapper .header-info {
  font-size: 22px;
  color: var(--heading);
}
.user-data-page .form-wrapper .header-info a {
  color: var(--blue-dark);
  text-decoration: underline;
}
.user-data-page .form-wrapper .copyright-text {
  font-size: 16px;
}
.user-data-form .input-group-meta {
  height: 55px;
  position: relative;
}
.user-data-form .input-group-meta input {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  font-size: 18px;
  border: none;
  border-bottom: solid 2px #000;
  padding: 0 52px 0 0;
  color: var(--heading);
  background: transparent;
}
.user-data-form .input-group-meta input:focus {
  border-color: var(--blue-dark);
}
.user-data-form .input-group-meta label {
  font-size: 15px;
  font-weight: normal;
  color: #bfbfbf;
  position: absolute;
  left: 0;
  top: -21px;
}
.user-data-form .input-group-meta .placeholder_icon {
  position: absolute;
  line-height: 55px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  text-align: center;
  z-index: 1;
  color: rgba(0, 0, 0, 0.45);
  font-size: 17px;
}
.user-data-form .input-group-meta .valid-sign img {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.user-data-form .input-group-meta input:valid + .valid-sign img {
  opacity: 1;
}
.user-data-form .input-group-meta .placeholder_icon img {
  position: relative;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
}
.user-data-form .input-group-meta .placeholder_icon span {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  position: relative;
}
.user-data-form .input-group-meta .placeholder_icon span:before {
  content: "";
  width: 2px;
  height: 26px;
  background: #000;
  position: absolute;
  top: 15px;
  left: 24px;
  transform: rotate(45deg);
  z-index: 5;
  transition: all 0.2s ease-in-out;
}
.user-data-form .input-group-meta .placeholder_icon span.eye-slash:before {
  opacity: 0;
}
.user-data-form .agreement-checkbox label {
  position: relative;
  font-size: 15px;
  color: var(--heading);
  cursor: pointer;
  padding-left: 22px;
  line-height: 18px;
  transition: all 0.1s ease-in-out;
}
.user-data-form .agreement-checkbox label a {
  color: var(--p-color);
  text-decoration: underline;
}
.user-data-form .agreement-checkbox input[type="checkbox"] {
  display: none;
}
.user-data-form .agreement-checkbox label:before {
  content: "";
  width: 13px;
  height: 13px;
  line-height: 11px;
  border-radius: 2px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  font-size: 8px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 2px;
  transition: all 0.1s ease-in-out;
}
.user-data-form
  .agreement-checkbox
  input[type="checkbox"]:checked
  + label:before {
  content: "\f00c";
  background: #000;
  color: #fff;
  border-color: #000;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 9px;
}
.user-data-form .agreement-checkbox a {
  position: relative;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.55);
}
.user-data-form .agreement-checkbox a:hover {
  text-decoration: underline;
  color: var(--p-color);
}
.user-data-form .theme-btn-one {
  width: 100%;
}
.user-data-page .full-height {
  min-height: 100vh;
  padding: 20px 0;
  flex-direction: column;
  text-align: center;
}
.full-height .user-data-form .button-solid-one {
  width: 200px;
  margin: 0 auto 30px;
}
/*------------------- Blog Pages ----------------*/
.blog-page-bg {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 180px 0 150px;
  position: relative;
  z-index: 5;
}
.blog-page-white-bg {
  padding: 140px 0 0;
}
.blog-page-bg .shape-one {
  width: 30px;
  height: 30px;
  background: #b183ff;
  border-radius: 50%;
  top: -15px;
  left: 40%;
  z-index: 1;
}
.blog-page-bg .shape-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  border-radius: 50%;
  top: 190px;
  right: 9%;
}
.blog-page-bg .shape-three {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  border-radius: 50%;
  top: 211px;
  left: 12%;
}
.feature-blog-one.width-lg .post-meta {
  padding: 35px 40px 80px;
}
.feature-blog-one.width-lg .post-meta .read-more {
  left: 40px;
  right: 40px;
}
.feature-blog-one.width-lg .post-meta p {
  font-size: 17px;
  line-height: 1.76em;
  padding-top: 35px;
}
.blog-sidebar-one {
  padding-left: 20px;
}
.blog-sidebar-one .sidebar-search-form form {
  height: 60px;
  background: #fff;
  box-shadow: 5px 10px 25px rgba(15, 24, 40, 0.04);
  position: relative;
}
.blog-v3 .blog-sidebar-one .sidebar-search-form form {
  box-shadow: none;
  border: 1.5px solid #000;
  border-radius: 4px;
}
.blog-sidebar-one .sidebar-search-form form input {
  font-size: 17px;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 45px 0 20px;
  background: transparent;
}
.blog-sidebar-one .sidebar-search-form form button {
  width: 40px;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: transparent;
}
.blog-sidebar-one .sidebar-title {
  font-family: "Open Sans";
  font-size: 30px;
  padding-bottom: 15px;
}
.blog-sidebar-one .sidebar-categories ul li a {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 60px;
  color: #000;
  border-bottom: 1px solid #ececec;
  display: block;
  transition: all 0.3s ease-in-out;
}
.blog-sidebar-one .sidebar-categories ul li a span {
  float: right;
}
.blog-sidebar-one .sidebar-categories ul li:first-child a {
  border-top: 1px solid #ececec;
}
.blog-sidebar-one .sidebar-categories ul li a:hover {
  color: var(--blue-dark);
}
.blog-v3 .blog-sidebar-one .sidebar-categories ul li a:hover {
  color: var(--red-light);
}
.blog-sidebar-one .sidebar-recent-news .title {
  font-family: "Open Sans";
  font-size: 24px;
  line-height: 1.25em;
  color: #000;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}
.blog-sidebar-one .sidebar-recent-news ul li a:hover .title {
  color: var(--blue-dark);
}
.blog-v3 .blog-sidebar-one .sidebar-recent-news ul li a:hover .title {
  color: var(--red-light);
}
.blog-sidebar-one .sidebar-recent-news .date {
  color: #a2a2a2;
}
.blog-sidebar-one .sidebar-recent-news ul li a {
  border-bottom: 1px solid #ececec;
  padding: 17px 0;
}
.blog-sidebar-one .sidebar-recent-news ul li:first-child a {
  border-top: 1px solid #ececec;
}
.blog-sidebar-one .sidebar-keyword ul {
  margin: 0 -5px;
}
.blog-sidebar-one .sidebar-keyword ul li {
  float: left;
  padding: 0 5px;
  margin-bottom: 18px;
}
.blog-sidebar-one .sidebar-keyword ul li a {
  font-weight: 500;
  font-size: 14px;
  background: #fff;
  line-height: 35px;
  border-radius: 4px;
  padding: 0 25px;
  color: #000;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.blog-v3 .blog-sidebar-one .sidebar-keyword ul li a {
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 4px;
}
.blog-sidebar-one .sidebar-keyword ul li a:hover {
  background: var(--blue-dark);
  color: #fff;
}
.blog-v3 .blog-sidebar-one .sidebar-keyword ul li a:hover {
  background: var(--red-light);
  border-color: var(--red-light);
}
.blog-sidebar-one .sidebar-keyword .sidebar-title {
  border-bottom: 1px solid #ececec;
  margin-bottom: 15px;
}
.feature-blog-three {
  padding-right: 40px;
}
.feature-blog-three .post-meta .image-meta {
  border-radius: 5px 5px 0 0;
}
.feature-blog-three .post-meta .post {
  border: 1px solid #e4e4e4;
  margin-top: -8px;
  padding: 40px 38px 33px;
  border-radius: 8px;
}
.feature-blog-three .post-meta .post .date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.63);
}
.feature-blog-three .post-meta .post .title {
  font-family: "Open Sans";
  font-size: 30px;
  line-height: 1.26em;
  color: #000;
  display: block;
  margin: 5px 0 25px;
  transition: all 0.3s ease-in-out;
}
.feature-blog-three .post-meta:hover .post .title {
  color: var(--red-light);
}
.feature-blog-three .post-meta .post p {
  font-size: 17px;
  line-height: 1.64em;
}
.feature-blog-three .post-meta .post .read-more {
  font-size: 16px;
  font-weight: 500;
  color: #2a2a2a;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
}
.feature-blog-three .post-meta .post .read-more:hover {
  color: var(--red-light);
}
.feature-blog-three .post-meta {
  margin-bottom: 60px;
}
.blog-v4 .wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
.blog-v4 .post-meta {
  margin-bottom: 80px;
}
.blog-v4 .post-meta .post {
  padding: 45px 0 0;
}
.blog-v4 .post-meta .post .date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.63);
}
.blog-v4 .post-meta .post .title {
  font-family: "gilroy-black";
  font-size: 48px;
  line-height: 1.04em;
  color: #000;
  display: block;
  margin: 5px 0 45px;
  transition: all 0.3s ease-in-out;
}
.blog-v4 .post-meta .post p {
  font-size: 20px;
  line-height: 1.7em;
}
.blog-v4 .post-meta .post .read-more {
  font-family: "Open Sans";
  font-size: 18px;
  color: #2a2a2a;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
}
.blog-v4 .post-meta .post .read-more span {
  text-decoration: underline;
}
.blog-v4 .post-meta.text-post {
  border: 2px solid #000;
  border-radius: 5px;
}
.blog-v4 .post-meta.text-post .post {
  padding: 45px 40px;
}
.blog-details-post-v1 .post-meta .mark-text {
  font-family: "Open Sans";
  font-size: 23px;
  color: #000;
  line-height: 1.41em;
  padding-top: 35px;
}
.blog-details-post-v1 .post-meta h4 {
  font-family: "gilroy-black";
  font-size: 36px;
  padding-top: 60px;
}
.blog-details-post-v1 .share-area {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  padding: 25px 0;
  margin-top: 40px;
  width: 100%;
}
.blog-details-post-v1 .share-option li {
  font-size: 15px;
  color: var(--heading);
  font-style: italic;
  margin-left: 10px;
}
.blog-details-post-v1 .share-option li:first-child {
  margin-left: 0;
}
.blog-details-post-v1 .share-option li a {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 20px;
}
.blog-details-post-v1 .tag-feature li:first-child {
  font-family: "Open Sans";
  color: #000;
  font-size: 15px;
}
.blog-details-post-v1 .tag-feature li a {
  font-size: 15px;
  color: #73737b;
  &:hover {
    color: var(--blue-dark);
  }
}
.feature-blog-one.width-lg.blog-details-post-v1 .post-meta {
  padding-bottom: 0;
}
.blog-details-post-v1 .comment-area {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  margin-bottom: 50px;
  padding: 50px 40px 30px;
}
.blog-details-post-v1 .comment-area .title {
  font-family: "gilroy-black";
  font-size: 36px;
  padding-bottom: 40px;
}
.blog-details-post-v1 .comment-area .single-comment {
  border-top: 1px solid #e5e5e5;
  padding: 35px 0;
  position: relative;
}
.blog-details-post-v1 .comment-area .user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.blog-details-post-v1 .comment-area .comment {
  padding-left: 20px;
  position: relative;
}
.blog-details-post-v1 .comment-area .name {
  font-family: "gilroy-semibold";
  font-size: 20px;
}
.blog-details-post-v1 .comment-area .time {
  font-size: 16px;
  color: #adadad;
  padding: 5px 0 15px;
}
.blog-details-post-v1 .comment-area p {
  font-size: 17px;
  line-height: 1.58em;
}
.blog-details-post-v1 .comment-area .reply {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 29px;
  border-radius: 15px;
  background: var(--blue-dark);
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.blog-details-post-v1 .comment-form-section {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08%);
  margin-bottom: 50px;
  padding: 50px 40px 60px;
}
.blog-details-post-v1 .comment-form-section .title {
  font-family: "gilroy-black";
  font-size: 36px;
  padding-bottom: 15px;
}
.blog-details-post-v1 .comment-form-section p {
  font-size: 18px;
  color: #2a2a2a;
}
.blog-details-post-v1 .comment-form-section p a {
  font-weight: 500;
  color: #000;
  text-decoration: underline;
}
.blog-details-post-v1 .comment-form-section .form-style-light {
  padding: 0;
  box-shadow: none;
  margin-top: 50px;
}
.blog-details-post-v1 .comment-form-section .form-style-light [class*="col-"] {
  display: block;
}
.blog-details-post-v1 .blog-quote {
  font-size: 24px;
  line-height: 40px;
  padding: 40px 50px;
  margin: 35px 0 0;
  position: relative;
  z-index: 2;
  background: #f8fafc;
  font-style: normal;
  border-left: 5px solid var(--blue-dark);
}
/*=======================================================================
                              Documentation                
=========================================================================*/
.theme-menu-three .logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-three .right-widget {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-three .navbar {
  position: static;
}
.theme-menu-three .user-login-button li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 46px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-menu-three .user-login-button li a:hover {
  color: var(--blue-light);
}
.theme-menu-three .user-login-button .signIn-action img {
  margin-right: 12px;
  margin-top: -3px;
}
.theme-menu-three .user-login-button li .signUp-action {
  border: 2px solid #000;
  background: #000;
  padding: 0 20px;
  color: #fff;
  margin-left: 35px;
}
.theme-menu-three .user-login-button li .signUp-action img {
  margin-left: 12px;
}
.theme-menu-three .user-login-button li .signUp-action:hover {
  background: var(--blue-light);
  border-color: var(--blue-light);
  color: #fff;
}
/*-------------------- Theme Hero Banner/Three -----------------*/
.hero-banner-three {
  padding: 40px 0 0;
  position: relative;
}
.hero-banner-three:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 83%;
  background: url(../images/shape/67.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  left: 0;
  bottom: 78px;
  z-index: -1;
}
.hero-banner-three h1 {
  font-size: 100px;
  line-height: 1.05em;
  text-align: center;
}
.hero-banner-three .sub-text {
  font-size: 28px;
  line-height: 1.39em;
  font-weight: 300;
  color: #2a2a2a;
  text-align: center;
  padding: 25px 0 45px;
}
.hero-banner-three .sing-in-call {
  font-size: 16px;
  padding-top: 25px;
  color: #2c2c2c;
  text-align: center;
}
.hero-banner-three .sing-in-call a {
  color: var(--blue-light);
  transition: all 0.25s ease-in-out;
}
.hero-banner-three .sing-in-call a:hover {
  text-decoration: underline;
}
.hero-banner-three .illustration {
  margin: 95px auto 0;
}
.search-filter-form form {
  max-width: 750px;
  margin: 0 auto;
  height: 78px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 30px 50px rgba(7, 21, 36, 0.06);
}
.search-filter-form input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 260px 0 35px;
  font-size: 16px;
  border-radius: 5px;
}
.search-filter-form button {
  width: 85px;
  right: 7px;
  bottom: 7px;
  top: 7px;
  background: #111111;
  position: absolute;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.search-filter-form button img {
  margin: 0 auto;
}
.search-filter-form button:hover {
  background: var(--blue-light);
}
.search-filter-form select {
  position: absolute;
  width: 135px;
  height: 35px;
  position: absolute;
  right: 120px;
  top: 21px;
  border: none;
  border-radius: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.4) !important;
  outline: none !important;
  box-shadow: none !important;
}
.hero-banner-three .shape-one {
  top: 5%;
  right: 15%;
  animation: rotatedTwo 25s infinite linear;
}
.hero-banner-three .shape-two {
  top: 11%;
  right: 7%;
}
.hero-banner-three .shape-three {
  top: 24%;
  right: 20%;
}
.hero-banner-three .shape-four {
  top: 28%;
  right: 11%;
  animation: jumpTwo 5s infinite linear;
}
.hero-banner-three .shape-five {
  top: 40%;
  right: 8%;
}
.hero-banner-three .shape-six {
  top: 6%;
  left: 9%;
}
.hero-banner-three .shape-seven {
  top: 19%;
  left: 7%;
}
.hero-banner-three .shape-eight {
  top: 24%;
  left: 16%;
  animation: jumpThree 5s infinite linear;
}
.hero-banner-three .shape-nine {
  top: 35%;
  left: 21%;
}
.hero-banner-three .shape-ten {
  top: 42%;
  left: 11%;
  animation: rotatedTwo 50s infinite linear;
}
/*------------------ Fancy Feature Eight --------------*/
.block-style-ten {
  border: 2px solid #eef3f7;
  border-radius: 10px;
  text-align: center;
  padding: 75px 80px 45px;
  margin-top: 40px;
  transition: all 0.3s ease-in-out;
}
.block-style-ten:hover {
  border-color: #000;
}
.block-style-ten .icon {
  height: 80px;
}
.block-style-ten .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.block-style-ten .title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.63);
  padding: 20px 0;
}
.block-style-ten p {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
  padding-bottom: 27px;
}
/*------------------ Fancy Text block Sixteen ---------------*/
.fancy-text-block-sixteen {
  position: relative;
  padding-bottom: 150px;
  z-index: 5;
}
.fancy-text-block-sixteen:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 82%;
  left: 0;
  bottom: 0;
  background: #f7faff;
  z-index: -1;
}
.fancy-text-block-sixteen .img-slick-slider {
  box-shadow: 0 30px 100px rgba(8, 17, 40, 0.05);
  margin-bottom: 90px;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -84px;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li {
  float: left;
  width: 33.333333%;
  height: 4px;
  background: rgba(216, 216, 216, 0.35);
  position: relative;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li button {
  text-indent: -50000px;
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background: #ffd084;
  border-radius: 4px;
  width: 75%;
  opacity: 0;
  transform: scale(0, 1);
  transform-origin: 0 100%;
  transition: all 0.3s ease-in-out;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li.slick-active button {
  opacity: 1;
  transform: scale(1, 1);
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li:last-child button {
  left: auto;
  right: 0;
}
.block-style-eleven .num {
  font-size: 30px;
  color: #202020;
}
.block-style-eleven .title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  padding: 17px 0 10px;
}
.block-style-eleven p {
  font-size: 25px;
  color: #000;
  line-height: 1.35em;
}
/*---------------------- Fancy Feature Eight --------------*/
.block-style-twelve .text-wrapper h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 2px;
}
.block-style-twelve .text-wrapper .title {
  font-size: 42px;
  line-height: 1.28em;
  padding: 20px 0 30px;
}
.block-style-twelve .text-wrapper p {
  font-size: 22px;
  line-height: 1.59em;
}
.block-style-twelve {
  padding: 140px 0;
  position: relative;
}
.block-style-twelve:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 13px;
  left: 0;
  bottom: -7px;
  background: url(../images/shape/line-shape-9.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.block-style-twelve:last-child {
  padding-bottom: 0;
}
.block-style-twelve:last-child:before {
  display: none;
}
/*-------------------- Client Feedback Slider Two ---------------------*/
.client-feedback-slider-two {
  background: #f7faff;
  position: relative;
  padding: 90px 0 120px;
  z-index: 5;
}
.client-feedback-slider-two .slider-content {
  max-width: 1710px;
  padding: 0 15px;
  margin: 0 auto;
}
.client-feedback-slider-two .bg-wrapper {
  background: #fff;
  padding: 50px 40px 45px;
  position: relative;
  margin: 0 15px 70px;
  transition: all 0.3s ease-in-out;
}
.client-feedback-slider-two .slick-center .bg-wrapper {
  box-shadow: 0px 40px 40px rgba(2, 7, 7, 0.03);
}
.client-feedback-slider-two .bg-wrapper:before {
  content: url(../images/icon/58.svg);
  position: absolute;
  right: 40px;
  bottom: 45px;
}
.client-feedback-slider-two .bg-wrapper .logo {
  height: 40px;
}
.client-feedback-slider-two .bg-wrapper p {
  font-size: 22px;
  line-height: 1.72em;
  color: #2c2c2c;
  padding: 35px 0 40px;
}
.client-feedback-slider-two .bg-wrapper .name {
  font-size: 22px;
  color: #000;
}
.client-feedback-slider-two .bg-wrapper .desig {
  font-size: 18px;
  color: #b7b7b7;
}
.client-feedback-slider-two .slick-dots {
  text-align: center;
}
.client-feedback-slider-two .slick-dots li {
  display: inline-block;
}

.client-feedback-slider-two .shape-one {
  top: -15px;
  right: 17%;
}
.client-feedback-slider-two .shape-two {
  top: 14%;
  right: 7%;
  animation: rotatedTwo 15s infinite linear;
}
.client-feedback-slider-two .shape-three {
  bottom: 11%;
  right: 0;
}
.client-feedback-slider-two .shape-four {
  bottom: -13px;
  right: 22%;
}
.client-feedback-slider-two .shape-five {
  bottom: -15px;
  left: 22%;
}
.client-feedback-slider-two .shape-six {
  bottom: 13%;
  left: 8%;
  animation: rotatedTwo 15s infinite linear;
}
.client-feedback-slider-two .shape-seven {
  top: 10%;
  left: 0;
}
.client-feedback-slider-two .shape-eight {
  top: -13px;
  left: 19%;
}
/*------------------- Useable Tools -------------*/
.useable-tools-section-three {
  position: relative;
}
.useable-tools-section-three .logo-wrapper {
  position: absolute;
  right: -7%;
  top: 50%;
  transform: translateY(-50%);
  max-width: 63%;
}
.useable-tools-section-three .logo-wrapper .logo {
  border-radius: 50%;
  margin: 12px 4%;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(1) {
  width: 165px;
  height: 165px;
  background: #ffe3e3;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(2) {
  width: 110px;
  height: 110px;
  background: #e8f8ff;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(3) {
  width: 165px;
  height: 165px;
  background: #eaffe7;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(4) {
  width: 140px;
  height: 140px;
  background: #f3f1ff;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(5) {
  width: 125px;
  height: 125px;
  background: #f0f5ef;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(6) {
  width: 185px;
  height: 185px;
  background: #fff9e5;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(7) {
  width: 125px;
  height: 125px;
  background: #e5fffe;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .sub-text {
  font-size: 22px;
  line-height: 1.69em;
  color: #000;
  padding: 50px 50px 40px 0;
}
.useable-tools-section-three .all-button {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.useable-tools-section-three .all-button i {
  margin-left: 5px;
}
.useable-tools-section-three .all-button:hover {
  color: var(--blue-light);
}
/*------------------ Fancy Short Banner Four ----------------*/
.fancy-short-banner-four {
  margin-top: -135px;
  position: relative;
  z-index: 5;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}
.fancy-short-banner-four .bg-wrapper {
  background: #1b1b1b;
  border-radius: 3px;
  padding: 65px 50px;
}
.fancy-short-banner-four .title h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: #c0c0c0;
  letter-spacing: 1px;
  padding-bottom: 13px;
}
.fancy-short-banner-four .title h2 {
  font-family: "Open Sans";
  font-size: 52px;
  line-height: 1.15em;
  color: #fff;
}
.fancy-short-banner-four .form-wrapper form {
  position: relative;
  height: 70px;
  border-radius: 5px;
  border: 1.5px solid #fff;
}
.fancy-short-banner-four .form-wrapper form input {
  font-style: italic;
  color: #fff;
  font-size: 18px;
  width: 100%;
  border: none;
  height: 100%;
  padding: 0 150px 0 25px;
  background: transparent;
}
.fancy-short-banner-four .form-wrapper form button {
  position: absolute;
  right: 7px;
  bottom: 7px;
  top: 7px;
  width: 160px;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-four .form-wrapper form button:hover {
  background: var(--blue-light);
  color: #fff;
}
.fancy-short-banner-four .form-wrapper p {
  font-size: 16px;
  color: #999999;
  padding-top: 10px;
}
.fancy-short-banner-four .form-wrapper p a {
  color: #fff;
}
.fancy-short-banner-four .form-wrapper p a:hover {
  text-decoration: underline;
}
/*------------------ Footer Style Three ----------------*/
.theme-footer-three {
  position: relative;
}
.theme-footer-three:before {
  content: url(../images/shape/92.svg);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.theme-footer-three .top-footer [class*="col-"] {
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}
.theme-footer-three .top-footer .footer-title {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  padding-bottom: 18px;
}
.theme-footer-three .top-footer .footer-list ul li a {
  font-size: 16px;
  line-height: 40px;
  color: #727272;
  transition: all 0.3s ease-in-out;
}
.theme-footer-three .top-footer .footer-list ul li a:hover {
  color: var(--blue-light);
}
.theme-footer-three .top-footer .address-list p {
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 15px;
}
.theme-footer-three .top-footer .address-list ul.info li a {
  color: #1c1c1c;
  margin-bottom: 10px;
}
.theme-footer-three .top-footer .address-list ul.info li a:hover {
  text-decoration: underline;
}
.theme-footer-three .top-footer .address-list .social-icon a {
  font-size: 22px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.55);
}
.theme-footer-three .top-footer .address-list .social-icon a:hover {
  color: #000;
}
.theme-footer-three .bottom-footer-content ul li {
  display: inline-block;
}
.theme-footer-three .bottom-footer-content ul li a {
  font-size: 16px;
  color: #727272;
  margin-left: 20px;
}
.theme-footer-three .bottom-footer-content ul li a:hover {
  color: #000;
}
.theme-footer-three .bottom-footer-content p {
  font-size: 15px;
  color: #212121;
  padding-left: 35px;
}
.theme-footer-three .bottom-footer-content {
  padding: 26px 0 30px;
}
/*-------------------- Fancy Hero Five ---------------------*/
.fancy-hero-five {
  background: #f7faff;
  padding: 220px 0 130px;
  position: relative;
  z-index: 5;
}
.fancy-hero-five .page-title {
  font-size: 20px;
  color: #b1b1b1;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.fancy-hero-five .heading {
  font-family: "gilroy-black";
  font-size: 80px;
  line-height: 1.08em;
}
.fancy-hero-five span {
  position: relative;
  z-index: 1;
}
.fancy-hero-five span img {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.fancy-hero-five .sub-heading {
  font-size: 24px;
  line-height: 1.58em;
  color: #2a2a2a;
  padding-top: 50px;
  padding-right: 50px;
}
.fancy-hero-five .sub-heading.space-xs {
  padding: 15px 0 0;
}
.fancy-hero-five .img-meta {
  bottom: -250px;
  right: 33px;
  position: absolute;
}
.fancy-hero-five .shape-one {
  right: 0;
  top: 24%;
}
.fancy-hero-five .shape-two {
  left: 0;
  top: 28%;
}
/*------------------- Fancy Text block Seventeen ----------------*/
.fancy-text-block-seventeen .text-meta {
  font-size: 24px;
  line-height: 1.66em;
  color: #2a2a2a;
  padding-bottom: 40px;
}
/*------------------- Fancy Text block Eighteen ----------------*/
.fancy-text-block-eighteen {
  background: linear-gradient(180deg, #ffffff, #edf2f9);
  padding-bottom: 425px;
}
.fancy-text-block-eighteen .illustration {
  margin: 0 auto;
}
/*------------------ Team Section Three --------------*/
.team-section-three {
  position: relative;
  z-index: 1;
  margin-top: -295px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.teamSliderOne {
  max-width: 1680px;
  margin-right: -127px;
  margin-left: auto;
}
.teamSliderOne .team-member {
  padding: 50px 15px;
}
.team-block-one {
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.team-block-one:hover {
  transform: translateY(-5px);
}
.team-block-one .info {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 32px 45px;
}
.team-block-one .info .desig {
  color: rgba(0, 0, 0, 0.63);
}
.team-block-one .info .name {
  font-size: 28px;
  font-weight: 500;
  color: #2a2a2a;
  padding-top: 5px;
}
.team-block-one .img-meta {
  overflow: hidden;
}
.team-block-one .img-meta img {
  width: 100%;
  border-radius: 0 0px 10px 10px;
}
.teamSliderOne .team-member .info {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 32px 45px;
}
.teamSliderOne .team-member .info .desig {
  color: rgba(0, 0, 0, 0.63);
}
.teamSliderOne .team-member .info .name {
  font-size: 28px;
  font-weight: 500;
  color: #2a2a2a;
  padding-top: 5px;
}
.teamSliderOne .team-member .img-meta {
  overflow: hidden;
}
.teamSliderOne .team-member .img-meta img {
  width: 100%;
  border-radius: 0 0px 10px 10px;
}
.team-section-three .slider-arrows li {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  line-height: 50px;
  color: #2a2a2a;
  font-size: 28px;
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.team-section-three .slider-arrows li:hover {
  background: var(--blue-light);
  color: #fff;
}

/*---------------------- Map Area One ----------------*/
.map-area-one {
  height: 700px;
  position: relative;
  .embed-responsive {
    height: 100%;
  }
  iframe {
    filter: brightness(0.3);
  }
}
.map-area-one .map-canvas {
  height: 100%;
}
.map-area-one .si-content {
  background: #fff;
  padding: 35px 30px 35px 50px;
  border-bottom: 5px solid #33d4b8;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 440px;
  height: 240px;
  z-index: 5;
}
.map-area-one .si-content h3 {
  font-family: "gilroy-black";
  font-size: 42px;
}
.map-area-one .si-content p {
  font-size: 18px;
  line-height: 25px;
  color: #2a2a2a;
  padding: 5px 0 25px;
}
.map-area-one .si-content span {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.63);
  display: block;
  line-height: 25px;
}
/*----------------- Documentation ------------------*/
.doc-container.full-width {
  padding: 0 45px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.doc-container.top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  margin-top: 150px;
}
.doc-container.top-border .doc-main-body,
.doc-container.top-border .doc-sidebar,
.doc-container.top-border .doc-sideNav {
  padding-top: 50px;
}
.doc-sidebar {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  height: 100vh;
  position: sticky;
  top: 90px;
}
.doc-sidebar .search-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.doc-sidebar .search-form form {
  position: relative;
  height: 50px;
}
.doc-sidebar .search-form form input {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 5px;
  font-size: 16px;
  color: #2a2a2a;
  padding: 0 15px 0 50px;
}
.doc-sidebar .search-form form button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
}
.doc-sidebar .search-form form button img {
  margin: 0 auto;
}
#doc-sidebar-nav {
  max-height: 100vh;
  overflow-y: auto;
  padding-right: 45px;
}
.doc-sidebar .list-item > li {
  border-bottom: 2px solid #000;
  padding: 18px 0;
}
.doc-sidebar .list-item > li:first-child {
  padding-top: 0;
}
.doc-sidebar .list-item li .sub-menu {
  padding-bottom: 15px;
}
.doc-sidebar .list-item li h4 {
  font-family: "gilroy-semibold";
  display: block;
  position: relative;
  line-height: 44px;
  font-size: 24px;
  color: #2a2a2a;
  cursor: pointer;
  margin-bottom: 6px;
}
.doc-sidebar .list-item li h4 .expander {
  position: absolute;
  background: transparent;
  line-height: 50px;
  right: 0;
  top: 0;
  color: inherit;
  font-size: 12px;
}
.doc-sidebar .list-item li .sub-menu a,
.doc-sidebar-menu .card .sub-menu a {
  font-size: 16px;
  line-height: 36px;
  color: #2a2a2a;
  display: block;
  padding: 0 15px;
  transition: all 0.3s ease-in-out;
}
.doc-sidebar .list-item li .sub-menu a.active,
.doc-sidebar-menu .card .sub-menu .active {
  background: #000;
  color: #fff;
  line-height: 32px;
  text-decoration: none;
}
.doc-sidebar .list-item li .sub-menu a.sec-menu {
  position: relative;
}
.doc-sidebar .list-item li .sub-menu a.sec-menu:before {
  content: "";
  position: absolute;
  font-family: "font-awesome";
  right: 10px;
  top: 0;
  line-height: 32px;
  color: #000;
}
.doc-sidebar .list-item li .sub-menu .main-side-nav {
  margin-top: 10px;
}
.doc-sidebar .list-item li .sub-menu .main-side-nav a {
  margin-left: 15px;
}
.doc-sidebar .list-item li .sub-menu a:hover,
.doc-sidebar-menu .card .sub-menu a:hover {
  text-decoration: underline;
}
.doc-sidebar ul .sub-menu {
  display: none;
  transition: all 0.3s ease-in-out;
}
.doc-sidebar ul .sub-menu.show {
  display: block;
}
.doc-sidebar ul .sub-menu.open {
  display: block;
}

.doc-container .doc-main-body {
  padding-left: 50px;
  padding-bottom: 65px;
}
.doc-container.full-width .doc-main-body {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  padding-right: 50px;
}
.doc-container .doc-main-body h2 {
  font-family: "gilroy-black";
  font-size: 42px;
  padding-bottom: 25px;
}
.doc-container .doc-main-body h3 {
  font-family: "gilroy-semibold";
  font-size: 28px;
  padding-bottom: 30px;
}
.doc-container .doc-main-body h3:not(:first-child) {
  padding-top: 20px;
}
.doc-container .doc-main-body p {
  color: #2a2a2a;
  padding-bottom: 30px;
}
.doc-container .doc-main-body p code {
  background: #f0f3f9;
  border-radius: 4px;
}
.doc-container .doc-main-body .mark-blue {
  background: #f4fafa;
  border-left: 4px solid #9cdfc9;
  border-radius: 0 8px 8px 0;
  padding: 22px 40px;
  margin-bottom: 45px;
}
.doc-container .doc-main-body .mark-blue pre {
  font-size: 16px;
  color: #414141;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.doc-container .doc-main-body .mark-blue code {
  color: #e83e8c;
}
.doc-container .doc-main-body .mark-blue pre .s1 {
  color: #78d0b6;
}
.doc-container .doc-main-body .mark-red {
  background: #fcfaf3;
  border-left: 4px solid #fae6bf;
  border-radius: 0 8px 8px 0;
  padding: 22px 40px;
  margin-bottom: 45px;
}
.doc-container .doc-main-body .mark-red pre {
  font-size: 16px;
  color: #575858;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.doc-container .doc-main-body .mark-red pre .s1 {
  color: #78d0b6;
  background: #fff;
}
.doc-container .doc-main-body .bg-black {
  background: #2d2d2d;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 30px 45px 30px;
  max-height: 740px;
  white-space: pre-wrap;
  color: inherit;
  font-size: 16px;
}
.doc-container .doc-main-body .bg-black code {
  white-space: pre-wrap;
}
.doc-container .doc-main-body .bg-black .s1 {
  color: #5e5e5e;
}
.doc-container .doc-main-body .bg-black .s2 {
  color: #cacaca;
}
.doc-container .doc-main-body .bg-black .s3 {
  color: #21b787;
}
.doc-container .doc-main-body .list-item-one li {
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;
}
.doc-container .doc-main-body .list-item-one li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  left: 0;
  top: 11px;
}
.doc-container .doc-main-body .img-meta {
  margin: 40px auto;
}
.doc-pagination ul li a {
  width: 35px;
  height: 35px;
  line-height: 31px;
  border: 2px solid #000;
  text-align: center;
  border-radius: 50%;
  font-size: 28px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.doc-pagination ul li a:hover {
  background: #000;
  color: #fff;
}
.doc-pagination ul li span {
  font-family: "gilroy-semibold";
  font-size: 22px;
  color: #000;
}
.doc-sideNav .wrapper {
  padding-left: 40px;
}
.doc-sideNav .nav-link {
  display: block;
  font-size: 16px;
  line-height: 36px;
  color: #767676;
  padding: 0 15px;
  transition: all 0.3s ease-in-out;
}
.doc-sideNav .nav-link.active {
  line-height: 32px;
  background: #000;
  color: #fff;
}
.doc-sideNav {
  top: 100px;
  position: sticky;
  height: 100%;
}
/*=======================================================================
                              Event               
=========================================================================*/
.theme-main-menu.sticky-menu.fixed.theme-menu-four {
  background: #fbf3ec;
}
.theme-menu-four .logo img {
  max-width: 120px;
}
.theme-menu-four .navbar-nav .nav-item .nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 0 24px;
}
.theme-menu-four .right-button-group .signIn-action {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: var(--heading);
  margin-right: 40px;
  transition: all 0.3s ease-in-out;
}
.theme-menu-four .right-button-group .signIn-action:hover {
  color: var(--vin-red);
}
.theme-menu-four .right-button-group {
  margin-left: 100px;
}
/*---------------------- Theme Hero Banner/Four ---------------*/
.hero-banner-four {
  position: relative;
  background: #fbf3ec;
  padding: 250px 0 110px;
  z-index: 1;
}
.hero-banner-four:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 103px;
  left: 0;
  bottom: -100px;
  background: url(../images/shape/99.svg) no-repeat center bottom;
  background-size: cover;
}
.hero-banner-four .shape-four {
  top: 1%;
  left: 15%;
}
.hero-banner-four .shape-five {
  top: 11%;
  right: 31%;
}
.hero-banner-four .shape-six {
  bottom: 5%;
  left: 0;
}
.hero-banner-four .shape-seven {
  bottom: 8%;
  right: 0;
}
.hero-banner-four h1 {
  font-family: "Roboto Slab", serif;
  font-size: 85px;
  line-height: 1.14em;
}
.hero-banner-four h1 span {
  position: relative;
  z-index: 5;
}
.hero-banner-four h1 span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 25px;
  background: rgba(241, 193, 83, 0.4);
  left: 0;
  bottom: 12px;
  z-index: -1;
}
.hero-banner-four .sub-text {
  font-size: 28px;
  color: #565657;
  line-height: 1.4em;
  padding: 50px 0 65px;
}
.hero-banner-four .review-text {
  text-align: right;
  font-size: 20px;
  color: #000;
  padding-top: 30px;
}
.hero-banner-four .review-text span {
  font-size: 1.6em;
  color: var(--vin-red);
  text-decoration: underline;
}
.hero-banner-four .illustration {
  max-width: 660px;
}
.hero-banner-four .illustration-holder .shape-one {
  width: 14%;
  top: 9%;
  right: 8%;
  z-index: 1;
  animation: jump10p 5s infinite linear;
}
.hero-banner-four .illustration-holder .shape-two {
  width: 14%;
  top: 0;
  right: 30%;
  z-index: 1;
  animation: jump10pRsv 5s infinite linear;
}
.hero-banner-four .illustration-holder .shape-three {
  width: 15%;
  top: 9%;
  right: 51%;
  z-index: 1;
  animation: jump10p 5s infinite linear;
}
/*-------------Fancy Feature Eight -----------*/
.fancy-feature-eight .bg-wrapper {
  position: relative;
  z-index: 5;
  background: #fff;
  padding: 35px 0 42px;
}
.fancy-feature-eight .bg-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 24px;
  background: url(../images/shape/97.svg) no-repeat center top;
  left: 0;
  top: -20px;
}
.fancy-feature-eight .bg-wrapper:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 13px;
  background: url(../images/shape/98.svg) no-repeat center bottom;
  left: 0;
  bottom: -10px;
}
.block-style-thirteen {
  position: relative;
  text-align: center;
  padding: 32px 40px 20px;
}
.block-style-thirteen.style-border {
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}
.block-style-thirteen .icon {
  height: 70px;
}
.block-style-thirteen .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.block-style-thirteen .title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.63);
  padding: 15px 0 20px;
}
.block-style-thirteen p {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
}
/*------------------ Fancy Text block Nineteen ----------------*/
.fancy-text-block-nineteen .text-wrapper .client-info {
  font-size: 24px;
  padding-bottom: 20px;
}
.fancy-text-block-nineteen .text-wrapper .client-info span {
  color: #000;
  text-decoration: underline;
}
.fancy-text-block-nineteen .text-wrapper p {
  font-size: 24px;
  color: #0e0e0e;
  line-height: 1.58em;
  padding-top: 40px;
}
.fancy-text-block-nineteen .text-wrapper .name {
  font-size: 24px;
  color: #000;
  position: relative;
  padding-left: 28px;
  margin-top: 50px;
}
.fancy-text-block-nineteen .text-wrapper .name:before {
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 13px;
}
/*------------------ Fancy Feature Nine --------------*/
.fancy-feature-nine {
  position: relative;
  background: #dff1ee;
  padding: 140px 0 135px;
  z-index: 5;
}
.fancy-feature-nine:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 52px;
  background: url(../images/shape/105.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -50px;
}
.fancy-feature-nine:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 75px;
  background: url(../images/shape/106.svg) no-repeat center bottom;
  background-size: cover;
  left: 0;
  bottom: -70px;
}
.fancy-feature-nine .shape-one {
  top: 7%;
  right: 13%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-nine .shape-two {
  top: 45%;
  right: 5%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-nine .shape-three {
  top: 88%;
  right: 10%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-nine .shape-four {
  top: 89%;
  left: 11%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-nine .shape-five {
  top: 43%;
  left: 4%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-nine .shape-six {
  top: 9%;
  left: 15%;
  animation: jumpTwo 5s infinite linear;
}
.block-style-fourteen {
  position: relative;
  padding: 0 25px;
  margin-top: 55px;
  text-align: center;
}
.block-style-fourteen .illustration {
  height: 220px;
}
.block-style-fourteen .illustration img {
  margin: 0 auto;
  max-height: 100%;
}
.block-style-fourteen .title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.55);
  padding: 20px 0 15px;
}
.block-style-fourteen p {
  font-size: 23px;
  line-height: 1.52em;
  color: #000;
}
.block-style-fourteen.arrow-shape:before,
.block-style-fourteen.arrow-shape:after {
  content: url(../images/shape/104.svg);
  position: absolute;
  top: 26%;
  z-index: 1;
}
.block-style-fourteen.arrow-shape:before {
  left: -20%;
}
.block-style-fourteen.arrow-shape:after {
  right: -20%;
}
/*--------------------- Pricing Section Three ------------------*/
.pricing-section-three {
  background: #fdecd8;
  position: relative;
  z-index: 1;
  padding: 105px 0 170px;
}
.pricing-section-three:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 85px;
  background: url(../images/shape/115.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -84px;
}
.pricing-section-three:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 33px;
  background: url(../images/shape/116.svg) no-repeat center bottom;
  background-size: cover;
  left: 0;
  bottom: -32px;
}
.pricing-section-three .shape-one {
  top: 7%;
  right: 13%;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-three .shape-two {
  top: 45%;
  right: 5%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-three .shape-three {
  top: 88%;
  right: 10%;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-three .shape-four {
  top: 89%;
  left: 11%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-three .shape-five {
  top: 43%;
  left: 4%;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-three .shape-six {
  top: 9%;
  left: 15%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-three .pricing-table-area-three {
  position: relative;
}
.pricing-section-three .pricing-table-area-three:before {
  content: url(../images/shape/117.svg);
  position: absolute;
  left: -5%;
  top: -5%;
  z-index: -1;
}
.pricing-table-area-three .pr-table-wrapper {
  background: #fff;
  padding: 40px 20px 40px;
  text-align: center;
  margin-top: 45px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-three .pr-table-wrapper:hover {
  transform: translateY(-10px);
}
.pricing-table-area-three .pr-table-wrapper.skew-right:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 0;
  width: 100%;
  height: 25px;
  background: #fff;
  transform: skewY(2.3deg);
}
.pricing-table-area-three .pr-table-wrapper.skew-left:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 0;
  width: 100%;
  height: 25px;
  background: #fff;
  transform: skewY(-2.3deg);
}
.pricing-table-area-three .pr-table-wrapper .pack-name {
  font-size: 32px;
  color: #000;
}
.pricing-table-area-three .pr-table-wrapper .pack-name span {
  position: relative;
  z-index: 1;
}
.pricing-table-area-three .pr-table-wrapper .pack-name span:before {
  content: "";
  width: 109%;
  height: 15px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  z-index: -1;
}
.pricing-table-area-three .pr-table-wrapper .pack-name.pc1 span:before {
  background: #d4fdf1;
}
.pricing-table-area-three .pr-table-wrapper .pack-name.pc2 span:before {
  background: #dbf7ff;
}
.pricing-table-area-three .pr-table-wrapper .pack-name.pc3 span:before {
  background: #ffe3e3;
}

.pricing-table-area-three .pr-table-wrapper .price {
  font-size: 65px;
  color: #000;
  padding: 24px 0 0;
  line-height: initial;
}
.pricing-table-area-three .pr-table-wrapper .user-condition {
  font-size: 24px;
  color: #000;
}
.pricing-table-area-three .pr-table-wrapper .line {
  width: 100%;
  margin: 25px auto 38px;
}
.pricing-table-area-three .pr-table-wrapper ul {
  text-align: left;
  padding-left: 18px;
}
.pricing-table-area-three .pr-table-wrapper ul li {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 42px;
  color: #464646;
  position: relative;
  padding-left: 30px;
}
.pricing-table-area-three .pr-table-wrapper ul li.disable {
  color: #b9b9b9;
}
.pricing-table-area-three .pr-table-wrapper ul li:before {
  content: url(../images/icon/63.svg);
  position: absolute;
  left: 0;
  top: 1px;
}
.pricing-table-area-three .pr-table-wrapper ul li.disable:before {
  content: url(../images/icon/64.svg);
  top: 5px;
  left: -8px;
}
.pricing-table-area-three .pr-table-wrapper .subscribe-btn {
  display: block;
  width: 95%;
  margin: 37px auto 0;
  line-height: 46px;
  border: 2px solid var(--purple-blue);
  text-align: center;
  color: var(--purple-blue);
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-three .pr-table-wrapper .subscribe-btn:hover {
  background: var(--purple-blue);
  color: #fff;
}
.pricing-section-three-inner-page {
  background: #fbf3ec;
  position: relative;
  z-index: 1;
  padding: 215px 0 110px;
}
.pricing-section-three-inner-page .shape-one {
  left: 0;
  top: 19%;
}
.pricing-section-three-inner-page .shape-two {
  right: 0;
  top: 19%;
}
.pricing-section-three-inner-page:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 103px;
  left: 0;
  bottom: -100px;
  background: url(../images/shape/99.svg) no-repeat center bottom;
  background-size: cover;
}
/*-------------------- Client Feedback Slider Three ---------------------*/
.client-feedback-slider-three {
  position: relative;
  z-index: 5;
}
.client-feedback-slider-three .shape_1 {
  left: 18%;
  top: -3%;
}
.client-feedback-slider-three .shape_2 {
  left: 9%;
  top: 38%;
}
.client-feedback-slider-three .shape_3 {
  left: 13%;
  top: 85%;
}
.client-feedback-slider-three .shape_4 {
  right: 18%;
  top: -4%;
}
.client-feedback-slider-three .shape_5 {
  right: 4%;
  top: 33%;
}
.client-feedback-slider-three .shape_6 {
  right: 19%;
  top: 87%;
}
.clientSliderThree {
  text-align: center;
}
.clientSliderThree p {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  line-height: 1.71em;
  color: rgba(0, 0, 0, 0.8);
  padding: 25px 0 45px;
}
.clientSliderThree .name {
  font-weight: 500;
  font-size: 24px;
  color: #000;
}
.clientSliderThree .desig {
  color: #a8afae;
}
.client-feedback-slider-three .slider-arrow li {
  cursor: pointer;
  margin: 0 10px;
  font-size: 40px;
  color: #5d5d5d;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}
.client-feedback-slider-three .slider-arrow li:first-child i {
  transform: rotate(180deg);
  display: inline-block;
}
.client-feedback-slider-three .slider-arrow li:hover {
  transform: scale(1);
}
/*------------------- Faq Section Three ----------------*/
.faq-section-four {
  background: #dff1ee;
  position: relative;
  z-index: 1;
  padding: 78px 0 150px;
}
.faq-section-four.bg-white {
  background: #fff;
}
.faq-section-four.bg-white:before,
.faq-section-four.bg-white:after {
  display: none;
}
.faq-section-four:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  background: url(../images/shape/118.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -98px;
}
.faq-section-four:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 33px;
  background: url(../images/shape/119.svg) no-repeat center bottom;
  background-size: cover;
  left: 0;
  bottom: -32px;
}
.faq-section-four .shape-one {
  left: 0;
  top: 21%;
}
.faq-section-four .shape-two {
  right: 0;
  top: 75%;
}
.accordion-style-four .card {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 10px;
}
.faq-section-four.bg-white .accordion-style-four .card {
  background: #f7f7f7;
}
.accordion-style-four .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-four .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 27px 35px 27px 45px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.accordion-style-four .card .card-header button:before {
  content: "\f107";
  font-family: "font-awesome";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 767px) {
    right: 12px;
  }
}
.accordion-style-four .card .card-body {
  padding: 0 50px 20px 45px;
}
.accordion-style-four .card .card-body p {
  padding-bottom: 15px;
}
.accordion-style-four .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}
/*------------------ Fancy Short Banner Five ---------------*/
.fancy-short-banner-five.with-bg {
  position: relative;
  background: #dff1ee;
  z-index: 1;
}
.fancy-short-banner-five.with-bg .shape-one {
  right: 5%;
  top: 20%;
}
.fancy-short-banner-five.with-bg .shape-two {
  left: 5%;
  top: 20%;
}
.fancy-short-banner-five .sub-heading {
  font-size: 28px;
  line-height: 1.32em;
  color: #000;
  text-align: center;
  padding: 40px 0 55px;
}
.fancy-short-banner-five form {
  height: 70px;
  position: relative;
  margin-bottom: 17px;
}
.fancy-short-banner-five form input {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  padding: 0 170px 0 40px;
  color: #000;
}
.fancy-short-banner-five form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}
.fancy-short-banner-five form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
.fancy-short-banner-five form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}
.fancy-short-banner-five form button {
  position: absolute;
  width: 160px;
  right: 8px;
  top: 8px;
  bottom: 8px;
  background: var(--purple-blue);
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-five form button:hover {
  background: var(--vin-red);
}
.fancy-short-banner-five .info-text {
  text-align: center;
  font-size: 16px;
  color: #999999;
}
.fancy-short-banner-five .info-text a {
  color: #000;
}
.fancy-short-banner-five .info-text a:hover {
  text-decoration: underline;
}
/*-------------------- Footer Style Four --------------------*/
.theme-footer-four {
  background: #fbf3ec;
  padding: 80px 0 70px;
  position: relative;
  z-index: 5;
}
.theme-footer-four:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 52px;
  background: url(../images/shape/122.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -50px;
}
.theme-footer-four .footer-about-widget ul li a {
  font-size: 20px;
  color: #343434;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
}
.theme-footer-four .footer-about-widget ul li a:hover {
  text-decoration: underline;
  color: var(--purple-blue);
}
.theme-footer-four .footer-title {
  font-size: 28px;
  color: #0e0e0e;
  position: relative;
  margin: 5px 0 21px;
}
.theme-footer-four .footer-list ul li a {
  font-family: "Roboto", sans-serif;
  color: #343434;
  line-height: 40px;
  transition: all 0.2s ease-in-out;
}
.theme-footer-four .footer-list ul li a:hover {
  color: var(--purple-blue);
  text-decoration: underline;
}
.theme-footer-four [class*="col-"] {
  margin-bottom: 35px;
}
.theme-footer-four .bottom-footer-content p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: -70px;
}
/*----------------- Fancy Text block Twenty -------------------*/
.fancy-text-block-twenty {
  position: relative;
  padding: 215px 0 100px;
}
.fancy-text-block-twenty:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 36%;
  background: url(../images/shape/123.svg) no-repeat center bottom;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
}
.fancy-text-block-twenty .shape-one {
  left: 0;
  top: 19%;
}
.fancy-text-block-twenty .shape-two {
  right: 0;
  top: 19%;
}
.fancy-text-block-twenty .title {
  text-align: center;
  font-size: 72px;
  padding-bottom: 130px;
}
.fancy-video-box-one .main-img {
  width: 100%;
}
.fancy-video-box-one {
  position: relative;
}
.fancy-video-box-one .video-button {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding-left: 8px;
}
.fancy-text-block-twenty .client-info {
  font-size: 24px;
  padding-bottom: 20px;
}
.fancy-text-block-twenty .client-info span {
  color: #000;
  text-decoration: underline;
}
.fancy-text-block-twenty .nav-tabs {
  border: none;
}
.fancy-text-block-twenty .nav-tabs li {
  margin: 0 82px 0 0;
}
.fancy-text-block-twenty .nav-tabs li:last-child {
  margin-right: 0;
}
.fancy-text-block-twenty .nav-tabs li {
  font-size: 22px;
  padding: 0;
  border: none;
  cursor: pointer;
}
.fancy-text-block-twenty .nav-tabs li.react-tabs__tab--selected {
  color: #000;
  text-decoration: underline;
}
.fancy-text-block-twenty .tab-content p {
  font-size: 20px;
  line-height: 1.9em;
  color: #000;
  padding-top: 35px;
}
/*----------------- Team Section Four --------------*/
.team-section-four {
  background: #dff1ee;
  position: relative;
  padding: 100px 0 60px;
  z-index: 1;
  &.custom-bg {
    padding: 180px 0 120px;
    &::after {
      display: none;
    }
  }
}
.team-section-four:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 70px;
  background: url(../images/shape/127.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -58px;
}
.team-section-four:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 65px;
  background: url(../images/shape/128.svg) no-repeat center bottom;
  background-size: cover;
  left: 0;
  bottom: -63px;
}
.team-section-four .shape-one {
  right: 0;
  top: 5%;
}
.team-section-four .shape-two {
  left: 0;
  bottom: -3%;
}
.team-section-four .team-wrapper {
  position: relative;
  z-index: 5;
}
.team-section-four .team-member {
  margin-bottom: 60px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 30px;
  display: block;
}
.team-section-four .team-member .img-holder {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  margin-bottom: 30px;
}
.team-section-four .team-member .img-holder img {
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.team-section-four .team-member:hover .img-holder img {
  transform: scale3d(1.1, 1.1, 1);
}
.team-section-four .team-member .name {
  font-size: 24px;
}
.team-section-four .team-member .position {
  font-size: 16px;
  color: rgba(42, 42, 42, 0.54);
}
.team-section-four .team-wrapper .shape-one {
  top: -6%;
  left: -5%;
}
/*-------------------- Feature Blog Four -----------------*/
.feature-blog-four {
  background: #fdecd8;
  position: relative;
  z-index: 1;
  padding: 100px 0 100px;
  &.blog-page-bg {
    background: linear-gradient(45deg, #fffbf2, #edfffd);
    padding: 180px 0 150px;
    position: relative;
    z-index: 5;
    &::before,
    &::after {
      display: none;
    }

    .feature-article-meta:hover .post-meta a {
      color: var(--blue-dark);
    }
  }
}
.feature-blog-four:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 85px;
  background: url(../images/shape/115.svg) no-repeat center top;
  background-size: cover;
  left: 0;
  top: -84px;
}
.feature-blog-four:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 33px;
  background: url(../images/shape/116.svg) no-repeat center bottom;
  background-size: cover;
  left: 0;
  bottom: -32px;
}
.feature-blog-four .feature-article-meta {
  margin-bottom: 35px;
}
.feature-blog-four .feature-article-meta .img-meta {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}
.feature-blog-four .feature-article-meta .img-meta img {
  transition: all 0.5s ease-in-out;
  width: 100%;
}
.feature-blog-four .feature-article-meta:hover .img-meta img {
  transform: scale3d(1.1, 1.1, 1);
}
.feature-blog-four .feature-article-meta .post-meta a {
  font-family: "Roboto Slab", serif;
  font-size: 30px;
  line-height: 1.3em;
  transition: all 0.3s ease-in-out;
}
.feature-blog-four .feature-article-meta:hover .post-meta a {
  color: var(--vin-red);
}
.feature-blog-four .feature-article-meta .post-meta .author_info {
  font-size: 20px;
  color: var(--heading);
  margin-top: 25px;
}
.feature-blog-four .feature-article-meta .post-meta .author_info span {
  color: #b7b7b7;
}
/*=======================================================================
                              Product landing               
=========================================================================*/
.theme-menu-five .logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-five .right-widget {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-five .right-widget .demo-button {
  width: 210px;
  font-size: 17px;
  line-height: 56px;
  border: 2px solid #000;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  color: #000;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.theme-menu-five .right-widget .demo-button:hover {
  background: #eef3f9;
  border-color: #eef3f9;
}
.theme-menu-five .right-widget .demo-button img {
  width: 20px;
  display: none;
  margin: 0 auto;
}
.theme-menu-five .navbar-nav .nav-item .nav-link {
  font-size: 20px;
  margin: 0 23px;
}
.theme-menu-five .navbar-nav .nav-item .nav-link.active {
  text-decoration: underline;
}

/*---------------------Theme Hero Banner/Five ---------------*/
.hero-banner-five {
  position: relative;
  text-align: center;
  padding: 56px 0 0;
}
.hero-banner-five .hero-heading {
  font-size: 82px;
  line-height: 1.219em;
}
.hero-banner-five .hero-heading span {
  position: relative;
  color: #ff2759;
  text-decoration: underline;
  text-decoration-thickness: 4px;
}
.hero-banner-five .hero-sub-heading {
  font-size: 24px;
  padding: 20px 0 62px;
  color: #000;
}

.hero-banner-five .sing-in-call {
  font-size: 16px;
  padding-top: 20px;
  color: #878787;
}
.hero-banner-five .sing-in-call span {
  color: #000;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.hero-banner-five .sing-in-call span:hover {
  text-decoration: underline;
}
.hero-banner-five .button-group a {
  width: 200px;
  height: 60px;
  padding: 0 5px 0 25px;
  margin: 10px 12px;
  border: 2px solid #111111;
  border-radius: 6px;
  color: #000;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.hero-banner-five .button-group a:hover {
  transform: translateY(-5px);
  box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.1);
}
.hero-banner-five .button-group a .icon {
  margin-right: 15px;
}
.hero-banner-five .button-group a span {
  font-size: 11px;
  color: #737373;
  display: block;
  margin-bottom: -11px;
  margin-top: -5px;
}
.hero-banner-five .button-group a strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}
.hero-banner-five .button-group a.ios-button {
  background: #111111;
  color: #fff;
}
.hero-banner-five .button-group a.ios-button span {
  color: rgba(255, 255, 255, 0.7);
}
.hero-banner-five .img-gallery {
  border-bottom: 1px solid #eaeaea;
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
.hero-banner-five .img-gallery .screen-container {
  border: 4px solid #000;
  border-radius: 45px 45px 0 0;
  border-bottom: none;
  position: relative;
}
.hero-banner-five .img-gallery .screen-container .main-screen {
  width: 100%;
  border-radius: 40px 40px 0 0;
}
.hero-banner-five .img-gallery .screen-one {
  width: 19%;
  top: 26%;
  right: -10%;
  z-index: 1;
  box-shadow: 0 35px 70px rgba(12, 21, 44, 0.08);
  animation: jumpTwo 5s infinite linear;
}
.hero-banner-five .img-gallery .screen-two {
  width: 31%;
  bottom: -12%;
  left: -16%;
  z-index: 1;
  box-shadow: -10px 40px 80px rgba(25, 42, 70, 0.05);
  animation: jumpThree 5s infinite linear;
}
.hero-banner-five .shape-one {
  left: 0;
  top: 25%;
}
.hero-banner-five .shape-two {
  right: 0;
  bottom: 9%;
}
.hero-banner-five .shape-three {
  right: 10%;
  top: 27%;
}
.hero-banner-five .shape-four {
  left: 11%;
  top: 56%;
}
.partner-slider-two p {
  font-size: 20px;
  color: #000;
  padding-bottom: 45px;
}
.partner-slider-two p span {
  font-weight: 500;
}
.partner-slider-two .img-meta {
  height: 80px;
}
/*------------------ Fancy Feature Ten --------------*/
.fancy-feature-ten {
  padding-left: 15px;
  padding-right: 15px;
}
.fancy-feature-ten .bg-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  background: #f3f8ff;
  border-radius: 40px;
  padding: 130px 0 150px;
  position: relative;
  z-index: 1;
}
.fancy-feature-ten .shape-one {
  bottom: -37px;
  right: 10%;
}
.fancy-feature-ten .sub-text {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
}
.block-style-fifteen {
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 35px 30px 40px;
  color: #000;
  z-index: 1;
}
.block-style-fifteen:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid #000;
  z-index: -1;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.block-style-fifteen:hover:before {
  opacity: 1;
}
.block-style-fifteen .icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.block-style-fifteen h3 {
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;
  padding: 27px 0 22px;
}
/*----------------- Fancy Text block Twenty One -------------------*/
.fancy-text-block-twentyOne .container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.fancy-text-block-twentyOne .text-wrapper .client-info {
  font-size: 24px;
  padding: 36px 0 28px;
}
.fancy-text-block-twentyOne .text-wrapper .client-info span {
  color: #000;
  text-decoration: underline;
}
.fancy-text-block-twentyOne .text-wrapper p {
  font-size: 48px;
  color: #000000;
  line-height: 1.59em;
  padding-bottom: 40px;
}
.fancy-text-block-twentyOne .text-wrapper p span {
  color: #ff2759;
  text-decoration: underline;
}
.fancy-text-block-twentyOne .text-wrapper .name {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  font-style: italic;
  position: relative;
  padding-left: 30px;
  margin-top: 32px;
}
.fancy-text-block-twentyOne .text-wrapper .name:before {
  content: "";
  width: 22px;
  height: 2px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 13px;
}
.fancy-text-block-twentyOne .img-meta {
  position: relative;
}
.fancy-text-block-twentyOne .img-meta .shape-one {
  bottom: -123px;
  left: 6px;
}
/*-------------------- Counter Style Two --------------*/
.counter-style-two {
  padding: 0 15px;
}
.counter-style-two .border-bottom {
  max-width: 1600px;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 120px;
}
.counter-box-four {
  text-align: center;
  margin-top: 40px;
}
.counter-box-four .number {
  font-family: "Recoleta";
  font-size: 60px;
  color: #000;
}
.counter-box-four p {
  color: #000;
}
/*-------------------- Fancy Feature Eleven ----------------*/
.fancy-feature-eleven {
  position: relative;
  z-index: 1;
}
.fancy-feature-eleven .inner-container {
  max-width: 1630px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}
.fancy-feature-eleven .shape-one {
  top: 3%;
  left: 7%;
}
.fancy-feature-eleven .shape-two {
  bottom: -60px;
  left: 37%;
  z-index: 1;
}
.block-style-sixteen {
  margin-top: 120px;
  position: relative;
  border-radius: 40px;
  padding: 125px 0 120px;
  overflow: hidden;
}
.block-style-sixteen .icon {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}
.block-style-sixteen .title {
  font-weight: 500;
  font-size: 48px;
  line-height: 1.31em;
  padding: 25px 0 40px;
}
.block-style-sixteen p {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
  padding-bottom: 75px;
}
.block-style-sixteen .screen-one {
  right: 70px;
  width: 48%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.block-style-sixteen .screen-two {
  left: 10px;
  width: 48%;
  bottom: 0;
  z-index: 1;
}
.block-style-sixteen .screen-three {
  right: 0;
  width: 48%;
  bottom: 0;
  z-index: 1;
}
/*----------------- Pricing Section Four ----------------*/
.pricing-nav-three {
  margin: 60px 0 100px;
  border: none;
}
.pricing-nav-three .nav-item {
  margin: 0;
}
.pricing-nav-three .nav-item .nav-link,
.pricing-nav-three .react-tabs__tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 61px;
  border: 2px solid #000;
  padding: 0 15px;
  width: 150px;
  text-align: center;
  color: #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.pricing-nav-three .nav-item:nth-child(1) .nav-link,
.pricing-nav-three .react-tabs__tab:first-child {
  border-right: none;
  border-radius: 10px 0 0 10px;
}
.pricing-nav-three .nav-item:nth-child(2) .nav-link,
.pricing-nav-three .react-tabs__tab:last-child {
  border-left: none;
  border-radius: 0 10px 10px 0;
}
.pricing-nav-three .nav-item .nav-link.active,
.pricing-nav-three .react-tabs__tab.react-tabs__tab--selected {
  background: #000;
  color: #fff;
}
.pricing-table-area-four {
  max-width: 1350px;
  padding: 0 15px;
  margin: 0 auto;
}
.pricing-table-area-four .row {
  margin: 0 -30px;
}
.pricing-table-area-four .row [class*="col-"] {
  padding: 0 30px;
}
.pricing-table-area-four .pr-table-wrapper {
  border: 1px solid #ececec;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  padding: 25px 35px 35px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.pricing-table-area-four .pr-table-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  border: 2px solid #000;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-four .pr-table-wrapper.most-popular:after {
  content: url(../images/shape/populer-tag.svg);
  position: absolute;
  top: -97px;
  right: -87px;
}
.pricing-table-area-four .pr-table-wrapper .pack-name {
  font-weight: 500;
  font-size: 28px;
  color: #000;
}
.pricing-table-area-four .pr-table-wrapper .pack-details {
  color: #878787;
  padding: 5px 0 45px;
}
.pricing-table-area-four .pr-table-wrapper .top-banner {
  padding: 9px 5px 15px 22px;
  color: #000;
  text-align: left;
}
.pricing-table-area-four .pr-table-wrapper .price {
  font-size: 40px;
  font-weight: 500;
  padding: 25px 20px 0 0;
}
.pricing-table-area-four .pr-table-wrapper .price sup {
  font-size: 20px;
  top: -22px;
}
.pricing-table-area-four .pr-table-wrapper .top-banner span {
  font-size: 22px;
}
.pricing-table-area-four .pr-table-wrapper .top-banner em {
  font-size: 14px;
  font-style: normal;
  display: block;
  margin-top: -7px;
}
.pricing-table-area-four .pr-table-wrapper .pr-feature {
  text-align: left;
  padding: 38px 0 60px 20px;
}
.pricing-table-area-four .pr-table-wrapper .pr-feature li {
  position: relative;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 30px;
}
.pricing-table-area-four .pr-table-wrapper .pr-feature li:before {
  content: url(../images/icon/76.svg);
  position: absolute;
  top: 0;
  left: 0;
}
.pricing-table-area-four .pr-table-wrapper .trial-button {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 52px;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-four .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 20px;
}
.pricing-table-area-four .pr-table-wrapper:hover:before,
.pricing-table-area-four .pr-table-wrapper.active:before {
  opacity: 1;
}
.pricing-table-area-four .pr-table-wrapper .trial-button:hover,
.pricing-table-area-four .pr-table-wrapper.active .trial-button {
  background: #000;
  color: #fff;
}
/*--------------------- Client Feedback Slider Four ---------------*/
.client-feedback-slider-four {
  padding: 0 15px;
}
.client-feedback-slider-four .shape-one {
  top: -69px;
  left: 11%;
}
.client-feedback-slider-four .shape-two {
  bottom: -42px;
  right: 9%;
}
.client-feedback-slider-four .inner-container {
  max-width: 1600px;
  margin: 0 auto;
  background: #e7f2f2;
  border-radius: 40px;
  position: relative;
  padding: 140px 0;
  z-index: 1;
}
.clientSliderFour {
  max-width: 1435px;
  margin: 100px auto 0;
}

.clientSliderFour .feedback-wrapper {
  background: #fff;
  border-radius: 12px;
  margin: 0 20px;
  padding: 45px 50px 50px 40px;
}
.clientSliderFour .feedback-wrapper p {
  font-size: 20px;
  line-height: 1.75em;
  color: #000;
  padding: 20px 0 45px;
}
.clientSliderFour .feedback-wrapper .name {
  font-weight: 500;
  font-size: 18px;
}
.clientSliderFour .feedback-wrapper .name span {
  font-weight: normal;
  font-size: 16px;
  color: #8a8a8a;
}
.clientSliderFour .feedback-wrapper ul li {
  font-size: 15px;
  color: #ffb043;
  margin-right: 9px;
}
.clientSliderFour .feedback-wrapper ul li [class*="star-o"] {
  color: #000;
}
.clientSliderFour .slick-dots {
  text-align: center;
  margin-top: 60px;
}
.clientSliderFour .slick-dots li {
  display: inline-block;
}

/*------------------ Fancy Short Banner Six ----------------*/
.fancy-short-banner-six {
  position: relative;
  text-align: center;
}
.fancy-short-banner-six .shape-one {
  left: 0;
  top: 22%;
}
.fancy-short-banner-six p {
  font-size: 24px;
  color: #000;
  padding: 30px 0 75px;
}
/*---------------- Footer Style Five -----------------*/
.theme-footer-five {
  padding: 0 15px;
}
.theme-footer-five .inner-container {
  border: 2px solid #151515;
  border-radius: 28px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 60px 0 15px;
}
.theme-footer-five .inner-container [class*="col-"] {
  margin-bottom: 40px;
}
.theme-footer-five .title {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  margin-bottom: 30px;
  text-align: center;
}
.theme-footer-five .social-icon li a {
  width: 36px;
  height: 36px;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  line-height: 34px;
  text-align: center;
  color: #000000;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
}
.theme-footer-five .social-icon li a:hover {
  background: #ff2759;
  border-color: #ff2759;
  color: #fff;
}
.theme-footer-five .email {
  color: #000;
}
.theme-footer-five .email:hover {
  text-decoration: underline;
}
.theme-footer-five .copyright {
  text-align: center;
  font-size: 16px;
  padding: 35px 0;
}
/*---------------- Modal Contact Form One ------------------*/
.modal-backdrop {
  z-index: 10000;
}
.modal {
  z-index: 10001;
}
.modal-contact-popup-one .modal-dialog {
  max-width: 1330px;
  padding: 0 15px;
}
.modal-contact-popup-one .main-body {
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: none;
  -ms-flex-direction: row;
  flex-direction: row;
}
.dark-style.modal-contact-popup-one .main-body {
  background: #2e2b3a;
}
.modal-contact-popup-one .main-body .close {
  position: absolute;
  right: 30px;
  top: 27px;
  z-index: 5;
}
.dark-style.modal-contact-popup-one .main-body .close {
  filter: invert(0.8);
}
.modal-contact-popup-one .main-body .left-side {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  background: #fde3c5;
  text-align: center;
  padding: 50px 0 0;
}
.dark-style.modal-contact-popup-one .main-body .left-side {
  background: linear-gradient(
    319.09deg,
    #f2a530 15.78%,
    #ed5879 48.89%,
    #b439ff 81.59%
  );
}
.dark-style.modal-contact-popup-one .main-body .left-side blockquote,
.dark-style.modal-contact-popup-one .main-body .left-side .bio {
  color: #fff;
}
.modal-contact-popup-one .main-body .left-side blockquote {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.31em;
  color: #000;
  padding: 0 15px 15px;
}
.modal-contact-popup-one .main-body .left-side .bio {
  font-size: 20px;
  color: #000;
  display: block;
  padding-bottom: 15px;
}
.modal-contact-popup-one .main-body .left-side .illustration {
  width: 100%;
}
.modal-contact-popup-one .main-body .right-side {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 50px 5% 50px 5%;
  position: relative;
  z-index: 1;
}
.modal-contact-popup-one .main-body .right-side .form-title {
  font-size: 40px;
  font-weight: 500;
  color: #000;
  padding-bottom: 30px;
}
.dark-style.modal-contact-popup-one .main-body .right-side .form-title {
  color: #fff;
}
.modal-contact-popup-one .main-body .input-group-meta {
  position: relative;
}
.modal-contact-popup-one .main-body .input-group-meta label {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 8px;
  font-weight: normal;
  display: block;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta label {
  color: rgba(255, 255, 255, 0.52);
}
.modal-contact-popup-one .main-body .input-group-meta input {
  width: 100%;
  height: 60px;
  border: 2px solid #000;
  border-radius: 6px;
  padding: 0 20px;
  color: #000;
  font-size: 17px;
  background: transparent;
}
.modal-contact-popup-one .main-body .input-group-meta textarea {
  max-width: 100%;
  width: 100%;
  height: 150px;
  border: 2px solid #000;
  border-radius: 6px;
  padding: 10px 20px;
  color: #000;
  font-size: 17px;
  background: transparent;
  display: block;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta input,
.dark-style.modal-contact-popup-one .main-body .input-group-meta textarea {
  border-color: #fff;
  color: #fff;
}
.modal-contact-popup-one
  .main-body
  .input-group-meta
  ::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}
.modal-contact-popup-one .main-body .input-group-meta :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
.modal-contact-popup-one .main-body .input-group-meta ::placeholder {
  color: #000;
}

.dark-style.modal-contact-popup-one
  .main-body
  .input-group-meta
  ::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}
.dark-style.modal-contact-popup-one
  .main-body
  .input-group-meta
  :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta ::placeholder {
  color: #fff;
}
.dark-style.modal-contact-popup-one .theme-btn-seven {
  background: rgba(255, 255, 255, 0.25);
}

.dark-style .modal-contact-popup-one .right-side:before {
  content: "";
  position: absolute;
  width: 568px;
  height: 568px;
  border-radius: 50%;
  top: -14%;
  left: 18%;
  background: rgba(89, 156, 255, 0.12);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -1;
}
.dark-style .modal-contact-popup-one .right-side:after {
  content: "";
  position: absolute;
  width: 568px;
  height: 568px;
  border-radius: 50%;
  top: 7%;
  left: 7%;
  background: rgba(255, 4, 64, 0.16);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
/*=======================================================================
                              Product landing Dark            
=========================================================================*/
/*-------------------- Menu ---------------*/
.dark-style .theme-main-menu {
  background: transparent;
}
.dark-style .navbar .navbar-toggler {
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
}
.dark-style .theme-main-menu.sticky-menu.fixed {
  background: #17192b;
}

.dark-style .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
.dark-style .navbar-nav .nav-item.active .nav-link {
  color: rgba(255, 255, 255, 1);
}
.dark-style .theme-menu-five .navbar-nav .nav-item .nav-link.active {
  color: #fff;
}
.dark-style .theme-menu-five .right-widget .demo-button {
  color: #fff;
  border-color: #fff;
}
.dark-style .theme-menu-five .right-widget .demo-button:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
  border-radius: 8px;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.dark-style .theme-menu-five .right-widget .demo-button:hover {
  background: transparent;
  border-color: transparent;
}
.dark-style .theme-menu-five .right-widget .demo-button:hover:before {
  opacity: 1;
}
/*----------------------- Theme Hero Banner / Six ---------------*/
.hero-banner-six {
  position: relative;
  padding: 125px 0;
}
.hero-banner-six .hero-heading {
  font-size: 100px;
  line-height: 115%;
  color: #ffffff;
}
.hero-banner-six .hero-heading span {
  color: #ff275b;
  text-decoration: underline;
  text-decoration-thickness: 5px;
}
.hero-banner-six .hero-sub-heading {
  font-size: 22px;
  padding: 45px 0 90px;
}
.hero-banner-six .button-group .ios-button {
  width: 200px;
  height: 65px;
  padding: 0 5px 0 25px;
  border-radius: 6px;
  color: #fff;
  text-align: left;
  transition: all 0.3s ease-in-out;
  margin-right: 45px;
}
.hero-banner-six .button-group .ios-button .icon {
  margin-right: 15px;
}
.hero-banner-six .button-group .ios-button span {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-bottom: -11px;
  margin-top: -5px;
}
.hero-banner-six .button-group .ios-button strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}
.hero-banner-six .button-group .video-button span {
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  padding-left: 14px;
  transition: all 0.3s ease;
}
.hero-banner-six .button-group .video-button span:hover {
  color: #ff275b;
}
.hero-banner-six .illustration-content {
  position: absolute;
  right: 0;
  top: 52%;
  transform: translateY(-50%);
  padding-right: 2%;
  width: 45%;
  max-width: 861px;
}
.hero-banner-six .illustration-content .main-img {
  animation: rotated 10s infinite linear;
}
.hero-banner-six .illustration-content .screen-one {
  position: absolute;
  width: 83%;
  top: 10%;
  right: 17%;
  z-index: 1;
  animation: jumpTwo 10s infinite linear;
}
.hero-banner-six .illustration-content .screen-two {
  position: absolute;
  width: 35%;
  top: 16%;
  right: 0;
  z-index: 2;
  animation: jumpThree 10s infinite linear;
}
.hero-banner-six .illustration-content .screen-two:before {
  content: "";
  position: absolute;
  top: 0;
  left: 8%;
  width: 89%;
  height: 88%;
  transform: rotate(12deg);
  background: rgba(17, 14, 14, 0.8);
  filter: blur(100px);
  z-index: -1;
}
.hero-banner-six .illustration-content .shape-one {
  top: 4%;
  left: 17%;
  z-index: 1;
  animation: rotated 3s infinite linear;
}
.hero-banner-six .illustration-content .shape-two {
  top: -7%;
  left: 44%;
  z-index: 1;
}
.hero-banner-six .illustration-content .shape-three {
  top: 4%;
  right: 11%;
  z-index: 1;
}
.hero-banner-six .illustration-content .shape-four {
  bottom: -1%;
  left: 29%;
  z-index: 1;
}
.hero-banner-six .illustration-content .shape-five {
  bottom: -3%;
  left: 13%;
  z-index: 1;
}
.hero-banner-six .illustration-content .shape-six {
  bottom: 4%;
  left: 45%;
  z-index: 1;
  width: 18%;
}
.hero-banner-six .illustration-content .oval-one {
  position: absolute;
  width: 875px;
  height: 875px;
  border-radius: 50%;
  left: -34%;
  top: -21%;
  background: rgba(255, 255, 255, 0.1);
  filter: blur(300px);
}
.hero-banner-six .illustration-content .oval-two {
  position: absolute;
  width: 868px;
  height: 868px;
  border-radius: 50%;
  top: -38%;
  left: 23%;
  background: rgba(89, 156, 255, 0.12);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
.hero-banner-six .illustration-content .oval-three {
  position: absolute;
  width: 868px;
  height: 868px;
  border-radius: 50%;
  top: -5%;
  left: 2%;
  background: rgba(255, 4, 64, 0.2);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -3;
}
/*------------------ Fancy Feature Twelve --------------*/
.fancy-feature-twelve {
  position: relative;
  z-index: 2;
}
.fancy-feature-twelve .shape-one {
  top: 3%;
  left: 10%;
}
.fancy-feature-twelve .row {
  margin: 0 -30px;
}
.fancy-feature-twelve .row [class*="col-"] {
  padding: 0 30px;
}
.block-style-seventeen {
  padding: 32px 50px 40px 44px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 17px;
  position: relative;
  z-index: 1;
}
.block-style-seventeen:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    111.21deg,
    #b526a6 -2.4%,
    #e14e66 46.51%,
    #e38039 93.12%
  );
  border-radius: 17px;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.block-style-seventeen:hover:before {
  opacity: 1;
}
.block-style-seventeen .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}
.block-style-seventeen h3 {
  font-weight: 500;
  font-size: 24px;
  padding: 26px 0 20px;
}
.block-style-seventeen .static-text {
  transition: all 0.3s ease-in-out;
}
.block-style-seventeen .hover-text {
  font-size: 24px;
  line-height: 1.5em;
  color: #fff;
  position: absolute;
  left: 44px;
  top: 112px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.block-style-seventeen:hover .static-text {
  opacity: 0;
}
.block-style-seventeen:hover .hover-text {
  opacity: 1;
}
.block-style-seventeen .js-tilt-glare {
  border-radius: 17px;
}
/*------------------ Fancy Feature Thirteen --------------*/
.fancy-feature-thirteen {
  position: relative;
  z-index: 1;
}
.fancy-feature-thirteen:before {
  content: "";
  position: absolute;
  width: 890px;
  height: 890px;
  border-radius: 50%;
  top: -27%;
  left: -9%;
  background: rgba(89, 156, 255, 0.1);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -1;
}
.fancy-feature-thirteen:after {
  content: "";
  position: absolute;
  width: 890px;
  height: 890px;
  border-radius: 50%;
  top: 5%;
  left: -8%;
  background: rgba(255, 4, 64, 0.13);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
.fancy-feature-thirteen .carousel-indicators {
  position: relative;
  margin: 0;
  border-top: 5px solid rgba(255, 255, 255, 0.1);
}
.fancy-feature-thirteen .carousel-indicators li {
  width: 30%;
  margin: 0;
  padding: 0;
  height: auto;
  background: transparent;
  opacity: 1;
  border: none;
  text-indent: 0;
  position: relative;
  padding-top: 50px;
}
.fancy-feature-thirteen .carousel-indicators li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: #ffd35b;
  left: 0;
  top: -5px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.fancy-feature-thirteen .item_content .icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  margin: 6px 30px 0 0;
}
.fancy-feature-thirteen .item_content .icon img {
  transition: all 0.3s ease-in-out;
  opacity: 0.4;
}
.fancy-feature-thirteen .item_content {
  padding-top: 50px;
  p {
    font-size: 24px;
    line-height: 1.41em;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease-in-out;
  }
}
.fancy-feature-thirteen
  .slick-slider.slick-nav-custom
  .slick-slide.slick-active.slick-current {
  border-top-color: #ffd35b;
}
.fancy-feature-thirteen .carousel-indicators li.active:before {
  opacity: 1;
}
.fancy-feature-thirteen .slick-slide.slick-active.slick-current {
  .item_content {
    p {
      color: rgba(255, 255, 255, 1);
    }
  }
  .icon img {
    opacity: 1;
    transform: rotate(90deg);
  }
}
.fancy-feature-thirteen .carousel-indicators li.active p {
  color: rgba(255, 255, 255, 1);
}
.fancy-feature-thirteen .carousel-inner {
  background: rgba(243, 247, 248, 0.1);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.15);
  border-radius: 40px;
  padding: 70px 75px;
  margin-top: 85px;
  position: relative;
  overflow: visible;
}
#productScreenSlider .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}
#productScreenSlider .carousel-item.active,
#productScreenSlider .carousel-item-next.carousel-item-left,
#productScreenSlider .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
#productScreenSlider .active.carousel-item-left,
#productScreenSlider .active.carousel-item-right {
  opacity: 0;
}
#productScreenSlider .carousel-item-next,
#productScreenSlider .carousel-item-prev,
#productScreenSlider .carousel-item.active,
#productScreenSlider .active.carousel-item-left,
#productScreenSlider .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}
.fancy-feature-thirteen .shape-one {
  top: -57px;
  right: -60px;
  z-index: 1;
}
.fancy-feature-thirteen .shape-two {
  bottom: -116px;
  left: -137px;
  z-index: 1;
}
/*------------------ Fancy Feature Fourteen --------------*/
.fancy-feature-fourteen .logo-meta {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  height: 170px;
  margin-bottom: 40px;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}
.fancy-feature-fourteen .logo-meta.lg {
  height: 248px;
}
.fancy-feature-fourteen .js-tilt-glare {
  border-radius: 20px;
}
.fancy-feature-fourteen .right-side {
  position: relative;
  z-index: 1;
}
.fancy-feature-fourteen .right-side .shape-one {
  bottom: -29px;
  right: -31px;
  z-index: 1;
}
.fancy-feature-fourteen .right-side:before {
  content: "";
  position: absolute;
  width: 590px;
  height: 590px;
  border-radius: 50%;
  top: -8%;
  left: -7%;
  background: rgba(89, 156, 255, 0.13);
  filter: blur(300px);
  transform: rotate(75deg);
  z-index: -1;
}
.fancy-feature-fourteen .right-side:after {
  content: "";
  position: absolute;
  width: 590px;
  height: 590px;
  border-radius: 50%;
  top: 16%;
  left: 21%;
  background: rgba(255, 4, 64, 0.15);
  filter: blur(300px);
  transform: rotate(75deg);
  z-index: -2;
}
/*------------------ Fancy Portfolio One --------------*/
.fancy-portfolio-one {
  position: relative;
  z-index: 1;
}
.fancy-portfolio-one .img-meta {
  border-radius: 20px;
  overflow: hidden;
  margin: 0 20px;
}
.fancy-portfolio-one .img-meta img {
  margin: 0 auto;
}
.portfolioSliderOne {
  max-width: 1800px;
  margin: 0 auto;
}
.fancy-portfolio-one .slider-arrows > div {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.fancy-portfolio-one .slider-arrows > div:hover {
  background: #ff2759;
}
.fancy-portfolio-one .slider-arrows {
  position: absolute;
  top: 28px;
  right: 16%;
}
.fancy-portfolio-one:before {
  content: "";
  position: absolute;
  width: 890px;
  height: 890px;
  border-radius: 50%;
  top: -27%;
  left: 1%;
  background: rgba(89, 156, 255, 0.1);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -1;
}
.fancy-portfolio-one:after {
  content: "";
  position: absolute;
  width: 890px;
  height: 890px;
  border-radius: 50%;
  top: 5%;
  left: -8%;
  background: rgba(255, 4, 64, 0.15);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
/*-------------- Client Feedback Slider Five ----------------*/
.client-feedback-slider-five {
  position: relative;
  padding: 240px 0;
  z-index: 2;
}
.client-feedback-slider-five:before {
  content: "";
  position: absolute;
  width: 1016px;
  height: 1016px;
  border-radius: 50%;
  top: 0%;
  left: 32%;
  background: rgba(89, 156, 255, 0.12);
  filter: blur(300px);
  transform: rotate(-180deg);
  z-index: -1;
}
.client-feedback-slider-five:after {
  content: "";
  position: absolute;
  width: 1016px;
  height: 1016px;
  border-radius: 50%;
  top: 19%;
  left: 15%;
  background: rgba(255, 4, 64, 0.12);
  filter: blur(300px);
  transform: rotate(-180deg);
  z-index: -2;
}
.clientSliderFive {
  text-align: center;
}
.clientSliderFive p {
  font-size: 28px;
  line-height: 1.85em;
  padding-bottom: 84px;
  color: rgba(255, 255, 255, 0.7);
}
.clientSliderFive .name {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.client-feedback-slider-five .slider-arrow li {
  width: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin: 0 7px;
  font-size: 26px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  transform: scale(0.6);
}
.client-feedback-slider-five .slider-arrow li:first-child i {
  transform: rotate(180deg);
  display: inline-block;
}
.client-feedback-slider-five .slider-arrow li:hover {
  opacity: 1;
  transform: scale(1);
}
.client-feedback-slider-five .circle-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.client-feedback-slider-five .circle-area .main-img {
  margin: 0 auto;
}
.client-feedback-slider-five .shape-one {
  top: 10%;
  left: -22%;
}
.client-feedback-slider-five .shape-two {
  top: -26%;
  right: -23%;
}
.client-feedback-slider-five .shape-three {
  top: 97%;
  right: 12%;
}
/*------------------ Pricing Section Five ---------------*/
.pricing-section-five {
  position: relative;
  z-index: 1;
}
.pricing-section-five:before {
  content: "";
  position: absolute;
  width: 860px;
  height: 860px;
  border-radius: 50%;
  top: -32%;
  left: 9%;
  background: rgba(89, 156, 255, 0.12);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -1;
}
.pricing-section-five:after {
  content: "";
  position: absolute;
  width: 860px;
  height: 860px;
  border-radius: 50%;
  bottom: -19%;
  left: 7%;
  background: rgba(255, 4, 64, 0.15);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
.pricing-table-area-five .row {
  margin: 0 -20px;
}
.pricing-table-area-five .row [class*="col-"] {
  padding: 0 20px;
}
.pricing-table-area-five .pr-table-wrapper {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  position: relative;
  padding: 58px 15px 55px 47px;
}
.pricing-table-area-five .pr-table-wrapper .pack-name {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  padding: 39px 0 37px;
}
.pricing-table-area-five .pr-table-wrapper .pr-feature li {
  line-height: 42px;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 30px;
  position: relative;
}
.pricing-table-area-five .pr-table-wrapper .pr-feature li:before {
  content: url(../images/icon/87.svg);
  position: absolute;
  left: 0;
  top: 0;
}
.pricing-table-area-five .pr-table-wrapper .price {
  color: #fff;
  font-size: 28px;
  padding: 45px 0 7px;
}
.pricing-table-area-five .pr-table-wrapper .trial-text {
  color: rgba(255, 255, 255, 0.54);
}
.pricing-table-area-five .pr-table-wrapper .trial-button {
  width: 170px;
  line-height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 35px;
  text-align: center;
}
.pricing-table-area-five .pr-table-wrapper .trial-button:before {
  border-radius: 24px;
}
.pricing-table-area-five .pr-table-wrapper.active {
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
}
.pricing-table-area-five .pr-table-wrapper.active .trial-button {
  background: rgba(255, 255, 255, 0.1);
}
.pricing-table-area-five .shape-one {
  z-index: 1;
  right: -47px;
  top: -76px;
  animation: rotated 35s infinite linear;
}
.pricing-table-area-five .shape-two {
  z-index: 1;
  left: -107px;
  bottom: -95px;
  animation: rotated 20s infinite linear;
}
.pricing-table-area-five .pr-table-wrapper .popular-badge {
  top: -90px;
  left: -77px;
  position: absolute;
  z-index: 1;
  animation: jumpTwo 10s infinite linear;
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.pricing-table-area-five .js-tilt-glare .js-tilt-glare-inner {
  border-radius: 15px;
  background-image: none !important;
}
.pricing-table-area-five .pr-table-wrapper.active .popular-badge {
  display: none;
}

/*--------------- Fancy Short Banner Seven --------------*/
.fancy-short-banner-seven {
  text-align: center;
  position: relative;
  z-index: 2;
}
.fancy-short-banner-seven .ios-button {
  width: 200px;
  height: 65px;
  padding: 0 5px 0 25px;
  border-radius: 6px;
  color: #fff;
  text-align: left;
  transition: all 0.3s ease-in-out;
  margin: 45px auto 0;
}
.fancy-short-banner-seven .ios-button:after {
  content: url(../images/shape/161.svg);
  position: absolute;
  right: -107px;
  bottom: -28px;
}
.fancy-short-banner-seven .ios-button .icon {
  margin-right: 15px;
}
.fancy-short-banner-seven .ios-button span {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-bottom: -11px;
  margin-top: -5px;
}
.fancy-short-banner-seven .ios-button strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}
.fancy-short-banner-seven .video-button span {
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  padding-left: 14px;
  transition: all 0.3s ease;
}
.fancy-short-banner-seven .screen-meta {
  position: relative;
  margin-top: 95px;
  z-index: 1;
}
.fancy-short-banner-seven .screen-meta:before {
  content: "";
  position: absolute;
  background: linear-gradient(
    180deg,
    #0f1123 0%,
    rgba(15, 17, 35, 0.89) 24.08%,
    rgba(14, 16, 31, 0) 81.75%
  );
  top: -50px;
  left: 0;
  right: 0;
  bottom: -100px;
  z-index: 2;
}
.fancy-short-banner-seven .shape-one {
  left: 15%;
  top: 36%;
  z-index: 3;
  animation: jumpTwo 3s infinite linear;
}
.fancy-short-banner-seven .shape-two {
  right: 17%;
  top: 36%;
  z-index: 3;
  animation: jumpThree 3s infinite linear;
}
.fancy-short-banner-seven .shape-three {
  left: 29%;
  bottom: -14%;
  z-index: 3;
  animation: jumpTwo 3s infinite linear;
}
.fancy-short-banner-seven .shape-four {
  left: 10%;
  top: -7%;
  z-index: 3;
}
.fancy-short-banner-seven .shape-five {
  right: -6%;
  bottom: -35%;
  z-index: 3;
}
.fancy-short-banner-seven .js-tilt-glare-inner {
  background: transparent;
  background-image: none !important;
}
/*------------------- Footer Style Six ---------------*/
.theme-footer-six {
  background: url(../images/media/img_77.png) no-repeat center;
  background-size: cover;
  position: relative;
  z-index: 1;
  margin-top: 200px;
}
.theme-footer-six .shape-one {
  left: 9%;
  bottom: -200px;
  animation: jumpThree 10s infinite linear;
}
.theme-footer-six .inner-container {
  background: linear-gradient(
    180deg,
    #0f1123 0%,
    rgba(15, 17, 35, 0.83) 48.7%,
    #0f1123 100%
  );
  padding: 100px 0 10px;
  position: relative;
  z-index: 5;
}
.theme-footer-six .inner-container:before {
  content: "";
  position: absolute;
  width: 995px;
  height: 995px;
  border-radius: 50%;
  top: -94%;
  left: 24%;
  background: rgba(89, 156, 255, 0.12);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -1;
}
.theme-footer-six .inner-container:after {
  content: "";
  position: absolute;
  width: 860px;
  height: 860px;
  border-radius: 50%;
  bottom: -153%;
  left: 18%;
  background: rgba(255, 4, 64, 0.15);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
.theme-footer-six .inner-container [class*="col-"] {
  margin-bottom: 40px;
}
.theme-footer-six .title {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
}
.theme-footer-six .social-icon li a {
  width: 36px;
  height: 36px;
  border: 1px solid #fff;
  border-radius: 50%;
  line-height: 34px;
  text-align: center;
  color: #fff;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
}
.theme-footer-six .social-icon li a:hover {
  background: #ff2759;
  border-color: #ff2759;
  color: #fff;
}
.theme-footer-six .email {
  color: rgba(255, 255, 255, 0.8);
}
.theme-footer-six .email:hover {
  text-decoration: underline;
}
.theme-footer-six .copyright {
  text-align: center;
  font-size: 16px;
  padding: 45px 0 0;
}
/*=======================================================================
                              Product landing / Note Taking            
=========================================================================*/
/*-------------------- Menu ---------------*/
.theme-menu-five .right-widget .signIn-action {
  font-size: 20px;
  font-weight: 500;
  line-height: 46px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-menu-five .right-widget .signIn-action:hover {
  text-decoration: underline;
}
.theme-menu-five .right-widget .signIn-action img {
  margin-right: 12px;
  margin-top: -3px;
}
.theme-menu-five .right-widget .download-btn {
  margin-left: 40px;
}
.theme-menu-five .right-widget .download-btn button {
  width: 190px;
  line-height: 60px;
  border-radius: 9px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: #ffeaa0;
  padding-left: 12px;
}
.theme-menu-five .right-widget .download-btn button:after {
  content: url(../images/icon/89.svg);
  border: none;
  margin: 0 0 0 11px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}
.theme-menu-five .right-widget .download-btn.style-two button {
  background: #fff;
  border: 2px solid #000;
  line-height: 56px;
}
.theme-menu-five .right-widget .download-btn.style-two .dropdown-menu {
  background: #fff;
  border: 2px solid #000;
}
.theme-menu-five .right-widget .download-btn.show button:after {
  transform: rotate(180deg);
}
.theme-menu-five .right-widget .download-btn .dropdown-menu {
  width: 100%;
  background: #fff5d1;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
}
.theme-menu-five .right-widget .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
}
.theme-menu-five .right-widget .download-btn .dropdown-menu a:hover,
.theme-menu-five .right-widget .download-btn .dropdown-menu a:focus {
  background: rgba(255, 255, 255, 0.5);
}
.theme-menu-five .right-widget .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
.theme-menu-five .right-widget .signup-btn {
  color: #000;
  position: relative;
  margin-left: 38px;
}
.theme-menu-five .right-widget .signup-btn:before {
  content: "";
  position: absolute;
  height: 13px;
  width: 2px;
  background: #000;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-five .right-widget .signup-btn span {
  font-weight: 500;
}
.theme-menu-five .right-widget .signup-btn:hover {
  text-decoration: underline;
}
/*----------------------- Theme Hero Banner / Seven ---------------*/
.hero-banner-seven {
  position: relative;
  z-index: 1;
  margin: 75px 0 0;
}
.hero-banner-seven .illustration-container {
  position: absolute;
  top: 22px;
  right: 70px;
  max-width: 46%;
}
.hero-banner-seven .illustration-container img {
  animation: jumpTwo 6s infinite linear;
}
.hero-banner-seven .hero-heading {
  font-weight: 500;
  font-size: 100px;
  line-height: 1.15em;
  padding-right: 50px;
}
.hero-banner-seven .hero-heading span {
  position: relative;
}
.hero-banner-seven .hero-heading span:before {
  content: "";
  width: 100%;
  height: 15px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 30px;
  z-index: -1;
}
.hero-banner-seven .hero-sub-heading {
  font-size: 24px;
  line-height: 1.5em;
  color: #2f2f2f;
  padding: 36px 0 58px 0;
}
.hero-banner-seven form {
  max-width: 550px;
  height: 80px;
  position: relative;
}
.hero-banner-seven form input {
  border: none;
  background: #f4f4f4;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 0 190px 0 30px;
}
.hero-banner-seven form button {
  position: absolute;
  right: 10px;
  top: 8px;
  bottom: 8px;
  background: #242424;
  width: 170px;
  border-radius: 7px;
  text-align: center;
  color: #fff;
  font-size: 17px;
  transition: all 0.3s ease-in-out;
}
.hero-banner-seven form button:hover {
  background: #ffeaa0;
  color: #212121;
}
.hero-banner-seven form ::-webkit-input-placeholder {
  /* Edge */
  color: #0b0b0b;
}
.hero-banner-seven form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0b0b0b;
}
.hero-banner-seven form ::placeholder {
  color: #0b0b0b;
}
.hero-banner-seven .term-text {
  font-size: 16px;
  color: #878787;
  margin-top: 22px;
}
.hero-banner-seven .download-btn {
  width: 190px;
  margin-top: 25px;
  display: none;
}
.hero-banner-seven .download-btn button {
  width: 100%;
  line-height: 60px;
  border-radius: 9px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: #ffeaa0;
  padding-left: 12px;
}
.hero-banner-seven .download-btn button:after {
  content: url(../images/icon/89.svg);
  border: none;
  margin: 0 0 0 11px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}
.hero-banner-seven .download-btn.show button:after {
  transform: rotate(180deg);
}
.hero-banner-seven .download-btn .dropdown-menu {
  width: 100%;
  background: #fff5d1;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
}
.hero-banner-seven .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
}
.hero-banner-seven .download-btn .dropdown-menu a:hover,
.hero-banner-seven .download-btn .dropdown-menu a:focus {
  background: rgba(255, 255, 255, 0.5);
}
.hero-banner-seven .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
/*---------------------- Fancy Feature Fifteen ------------------*/
.fancy-feature-fifteen {
  margin: 0 15px;
}
.fancy-feature-fifteen .bg-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  background: #f6f9fc;
  padding: 70px 0;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}
.fancy-feature-fifteen .bg-wrapper:before {
  content: "";
  position: absolute;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  background: #75f1d5;
  top: -59px;
  right: -48px;
  z-index: 1;
  animation: jumpTwo 6s infinite linear;
}
.fancy-feature-fifteen .bg-wrapper .shape-one {
  left: -33px;
  top: 22%;
  animation: jumpTwo 6s infinite linear;
}
.fancy-feature-fifteen .bg-wrapper .shape-two {
  right: -33px;
  top: 58%;
  animation: jumpThree 6s infinite linear;
}
.fancy-feature-fifteen .carousel-indicators {
  position: relative;
  margin: 0;
}
.fancy-feature-fifteen li {
  margin: 0;
  padding: 0;
  height: auto;
  background: transparent;
  opacity: 0.75;
  border: none;
  text-indent: 0;
  position: relative;
  width: auto;
  margin-bottom: 10px;
}
.fancy-feature-fifteen li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000;
  left: 0;
  bottom: -3px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.fancy-feature-fifteen li p {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding-left: 10px;
  transition: all 0.3s ease-in-out;
}
.fancy-feature-fifteen .react-tabs__tab-list {
  max-width: 66%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    max-width: 100%;
    justify-content: center;
    li {
      margin: 10px;
    }
  }
}
.fancy-feature-fifteen li.react-tabs__tab--selected,
.fancy-feature-fifteen li.react-tabs__tab--selected:before {
  opacity: 1;
}

/*--------------------- Fancy Feature Sixteen ------------------*/
.block-style-eighteen .text-wrapper h6 {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.63);
  padding-bottom: 15px;
}
.block-style-eighteen .text-wrapper .title {
  font-size: 68px;
  font-weight: 500;
  line-height: 1.05em;
}
.block-style-eighteen .text-wrapper .title span {
  position: relative;
  display: inline-block;
}
.block-style-eighteen .text-wrapper .title span:before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}
.block-style-eighteen .text-wrapper p {
  font-size: 20px;
  line-height: 1.75em;
  color: #4f4f4f;
  padding: 45px 0 40px;
}
.block-style-eighteen .text-wrapper .learn-more {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.block-style-eighteen .text-wrapper .learn-more img {
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}
.block-style-eighteen .text-wrapper .learn-more:hover img {
  transform: translateX(3px);
}
.block-style-eighteen .screen-holder-one {
  width: 460px;
  height: 470px;
  border-radius: 24px;
  background: #faf7f0;
  position: relative;
  z-index: 1;
  margin-left: auto;
}
.block-style-eighteen .screen-holder-one .round-bg {
  background: #fff;
  border-radius: 50%;
  &.big_g {
    @media screen and (max-width: 575px) {
      width: 150px !important;
      height: 150px !important;
    }
  }
}
.block-style-eighteen .screen-holder-one .shapes {
  z-index: 1;
}
.block-style-eighteen .screen-holder-one .logo-one {
  top: -33px;
  right: 32%;
  animation: jumpTwo 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-two {
  top: 20%;
  left: -42px;
  animation: jumpThree 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-three {
  top: 74%;
  left: -37px;
  animation: jumpTwo 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-four {
  bottom: -54px;
  left: 52%;
  animation: jumpThree 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-five {
  top: 9%;
  right: -43px;
  animation: jumpThree 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .shape-one {
  top: 42%;
  right: -43px;
}
.block-style-eighteen .screen-holder-two {
  position: relative;
  display: inline-block;
}
.block-style-eighteen .screen-holder-two .screen-one {
  z-index: 1;
  bottom: -9%;
  right: -22%;
  width: 44.8%;
  animation: jumpThree 5s infinite linear;
}
.block-style-eighteen .screen-holder-three {
  width: 530px;
  height: 513px;
  background: #f0f6fb;
  position: relative;
  display: inline-block;
  border-radius: 25px;
  margin-left: auto;
}
.block-style-eighteen .screen-holder-three img {
  animation: jumpTwo 5s infinite linear;
}
/*----------------- Fancy Text block Twenty Two -------------------*/
.fancy-text-block-twentyTwo .text-wrapper {
  padding-left: 40px;
}
.fancy-text-block-twentyTwo .text-wrapper p {
  font-size: 20px;
  line-height: 1.75em;
  color: #292929;
  padding: 40px 0 67px;
}
.fancy-text-block-twentyTwo .illustration-holder img {
  animation: jumpTwo 6s infinite linear;
}
/*----------------- Fancy Feature Seventeen -------------------*/
.fancy-feature-seventeen {
  padding: 0 15px;
}
.fancy-feature-seventeen .bg-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  background: #fcf7ea;
  border-radius: 25px;
  padding: 90px 0 40px;
}
.fancy-feature-seventeen .block-meta {
  margin-bottom: 70px;
  text-align: center;
  padding: 0 30px;
}
.fancy-feature-seventeen .block-meta .icon {
  height: 35px;
}
.fancy-feature-seventeen .block-meta .icon img {
  margin: 0 auto;
}
.fancy-feature-seventeen .block-meta h4 {
  font-size: 24px;
  font-weight: 500;
  padding: 28px 0 14px;
}
.fancy-feature-seventeen .block-meta p {
  line-height: 32px;
  color: #292929;
}
/*------------------- Pricing Section Six ------------------*/
.pricing-nav-four {
  margin: 70px auto 35px;
  border: 2px solid #000;
  padding: 6px;
  border-radius: 10px;
  width: 310px;
}
.pricing-nav-four .nav-item {
  margin: 0;
}
.pricing-nav-four .nav-item .nav-link,
.pricing-nav-four .react-tabs__tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  width: 147px;
  text-align: center;
  color: #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.pricing-nav-four .nav-item .nav-link.active,
.pricing-nav-four .react-tabs__tab.react-tabs__tab--selected {
  background: #000;
  color: #fff;
}
.pricing-table-area-six {
  max-width: 1335px;
  padding: 0 15px;
  margin: 0 auto;
}
.pricing-table-area-six .row {
  margin: 0 -30px;
}
.pricing-table-area-six .row [class*="col-"] {
  padding: 0 30px;
}
.pricing-table-area-six .pr-table-wrapper {
  border: 1px solid #ececec;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  padding: 35px 35px 35px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.pricing-table-area-six .pr-table-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  border: 2px solid #000;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-six .pr-table-wrapper .pack-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 0 13px;
  display: inline-block;
  color: #000;
}
.pricing-table-area-six .pr-table-wrapper .pack-details {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 27px;
}
.pricing-table-area-six .pr-table-wrapper .price {
  font-size: 58px;
  font-weight: 500;
  color: #000;
  line-height: initial;
  padding: 13px 0 6px;
}
.pricing-table-area-six .pr-table-wrapper .pr-feature {
  text-align: left;
  padding: 28px 0 42px 8px;
}
.pricing-table-area-six .pr-table-wrapper .pr-feature li {
  position: relative;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 30px;
}
.pricing-table-area-six .pr-table-wrapper .pr-feature li:before {
  content: url(../images/icon/76.svg);
  position: absolute;
  top: 0;
  left: 0;
}
.pricing-table-area-six .pr-table-wrapper .trial-button {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 52px;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-six .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 20px;
}
.pricing-table-area-six .pr-table-wrapper:hover:before,
.pricing-table-area-six .pr-table-wrapper.active:before {
  opacity: 1;
}
.pricing-table-area-six .pr-table-wrapper .trial-button:hover,
.pricing-table-area-six .pr-table-wrapper.active .trial-button {
  background: #ffeaa0;
  border-color: #ffeaa0;
}
/*-------------- Client Feedback Slider Six ----------------*/
.clientSliderSix {
  max-width: 1740px;
  margin: 30px auto 0;
}
.clientSliderSix .item {
  padding: 80px 25px;
}
.clientSliderSix .feedback-wrapper {
  border: 2px solid #e8edf0;
  border-radius: 10px;
  background: #fff;
  padding: 42px 44px 48px 57px;
  position: relative;
  z-index: 1;

  transition: all 0.3s ease-in-out;
}
.clientSliderSix .feedback-wrapper .ribbon {
  height: 8px;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.clientSliderSix .slick-center .feedback-wrapper {
  border-color: #fff;
  box-shadow: 0px 35px 60px rgba(14, 29, 45, 0.04);
}
.clientSliderSix .feedback-wrapper:before {
  content: url(../images/icon/101.svg);
  position: absolute;
  bottom: 41px;
  right: 57px;
}
.clientSliderSix .feedback-wrapper p {
  font-size: 20px;
  line-height: 38px;
  color: #000;
  padding-bottom: 45px;
}
.clientSliderSix .feedback-wrapper .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}
.clientSliderSix .feedback-wrapper .name {
  font-weight: 500;
  font-size: 18px;
}
.clientSliderSix .feedback-wrapper .name span {
  font-weight: normal;
  font-size: 16px;
  color: #8a8a8a;
}
.clientSliderSix .slick-dots {
  text-align: center;
}
.clientSliderSix .slick-dots li {
  display: inline-block;
}

.clientSliderSix.style-two .feedback-wrapper .ribbon {
  top: auto;
  bottom: -1px;
  height: 5px;
  transform: scale(0, 1);
  border-radius: 0 0 10px 10px;
  transition: all 0.3s ease-in-out;
}
.clientSliderSix.style-two .slick-center .feedback-wrapper .ribbon {
  transform: scale(1, 1);
}
/*----------------- Fancy Short Banner Eight ---------------*/
.fancy-short-banner-eight {
  background: #fcf7ea;
  padding: 90px 0 100px;
  position: relative;
  z-index: 1;
}
.fancy-short-banner-eight .download-btn {
  width: 280px;
  margin: 80px auto 0;
}
.fancy-short-banner-eight .download-btn button {
  width: 100%;
  line-height: 70px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  background: #000;
  text-align: center;
}
.fancy-short-banner-eight .download-btn button:after {
  content: url(../images/icon/102.svg);
  border: none;
  position: absolute;
  right: 22px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}
.fancy-short-banner-eight .download-btn.show button:after {
  transform: rotate(180deg);
}
.fancy-short-banner-eight .download-btn .dropdown-menu {
  width: 100%;
  background: #fff5d1;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
  padding: 5px 0 0px;
}
.fancy-short-banner-eight .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  background: transparent;
}
.fancy-short-banner-eight .download-btn .dropdown-menu a:hover,
.fancy-short-banner-eight .download-btn .dropdown-menu a:focus {
  background: rgba(255, 255, 255, 0.5);
}
.fancy-short-banner-eight .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
.fancy-short-banner-eight .shape-one {
  top: 18%;
  left: 10%;
  animation: jumpThree 4s infinite linear;
}
.fancy-short-banner-eight .shape-two {
  top: 54%;
  right: 9%;
  animation: jumpTwo 4s infinite linear;
}
/*-------------- Footer Style Seven -------------*/
.theme-footer-seven .title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 3px;
  padding-bottom: 25px;
}
.theme-footer-seven .footer-list a {
  line-height: 40px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-footer-seven .footer-list a:hover {
  text-decoration: underline;
}
.theme-footer-seven .newsletter p {
  font-size: 19px;
  color: #000;
  padding: 10px 0 40px;
  span {
    text-decoration: underline;
  }
}
.theme-footer-seven .inner-btn-black .newsletter {
  button {
    background: #262626;
    color: #fff;
    &:hover {
      background: #eb5e2a;
    }
  }
}
.theme-footer-seven .newsletter p span {
  text-decoration: underline;
}
.theme-footer-seven .newsletter form {
  height: 70px;
  position: relative;
  margin-bottom: 12px;
}
.theme-footer-seven .newsletter form input {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 8px;
  padding: 0 125px 0 20px;
  font-size: 16px;
  color: #000;
}
.theme-footer-seven .newsletter form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}
.theme-footer-seven .newsletter form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
.theme-footer-seven .newsletter form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}
.theme-footer-seven .newsletter form button {
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  top: 8px;
  right: 8px;
  bottom: 8px;
  width: 115px;
  border-radius: 8px;
  background: #ffeaa0;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-footer-seven .newsletter form button.dark-btn {
  background: #262626;
  color: #fff;
}
.theme-footer-seven .newsletter form button.dark-btn:hover {
  background: #eb5e2a;
}
.theme-footer-seven .newsletter .info {
  font-size: 14px;
  color: #878787;
}
.theme-footer-seven .bottom-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 35px 0 15px;
  margin-top: 45px;
}
.theme-footer-seven .bottom-footer .footer-nav a {
  font-size: 16px;
  color: #000;
  margin-right: 25px;
}
.theme-footer-seven .bottom-footer .footer-nav a:hover {
  text-decoration: underline;
}
.theme-footer-seven .bottom-footer .copyright {
  font-size: 16px;
  color: #373737;
  a {
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
}
.theme-footer-seven .bottom-footer .social-icon a {
  font-size: 20px;
  margin-left: 15px;
  color: #000;
  transition: 0.3s;
  &:hover {
    transform: translateY(-3px);
  }
}
/*=======================================================================
                    Product landing / Online Video Editor           
=========================================================================*/
/*---------------- Theme Hero Banner ---------------*/
.hero-banner-eight {
  position: relative;
  z-index: 5;
  text-align: center;
  margin-top: 62px;
}
.hero-banner-eight .hero-heading {
  font-size: 90px;
  font-weight: 500;
  line-height: 1.11em;
}
.hero-banner-eight .hero-sub-heading {
  font-size: 23px;
  color: #000;
  padding: 35px 0 55px;
}
.hero-banner-eight .video-wrapper {
  max-width: 1050px;
  height: 600px;
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.04);
  margin: 75px auto 0;
}
.hero-banner-eight .video-wrapper video {
  display: block;
}
.hero-banner-eight .shape-right {
  top: -1%;
  right: 0;
  width: 25.5%;
  animation: fade-in 1.5s infinite linear;
}
.hero-banner-eight .shape-left {
  top: 0;
  left: 0;
  width: 29.4%;
  animation: fade-in-rev 1.5s infinite linear;
}
/*----------------- Fancy Feature Eighteen -------------------*/
.fancy-feature-eighteen {
  background: #f3f8ff;
  padding: 120px 15px 150px;
  position: relative;
  z-index: 1;
}
.fancy-feature-eighteen .react-tabs__tab-panel {
  background: #fff;
}
.fancy-feature-eighteen .react-tabs__tab-list {
  margin: 90px 0 70px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fancy-feature-eighteen .react-tabs__tab-panel {
  box-shadow: 0 40px 100px rgba(14, 33, 44, 0.05);
  overflow: hidden;
  border-radius: 20px;
}

.fancy-feature-eighteen .react-tabs__tab-list .react-tabs__tab {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fancy-feature-eighteen .nav-link {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 15px;
  padding: 10px;
  border: none;
  background: transparent;
  margin: 10px 22px;
}
.fancy-feature-eighteen .react-tabs__tab .hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0) translate(-50%, -50%);
}
.fancy-feature-eighteen .react-tabs__tab--selected .current {
  transform: scale(0);
}
.fancy-feature-eighteen .react-tabs__tab--selected .hover {
  transform: scale(1) translate(-50%, -50%);
}
.fancy-feature-eighteen .react-tabs__tab--selected .nav-link {
  background: #fff;
  box-shadow: 0 15px 30px rgba(9, 16, 29, 0.03);
}
.fancy-feature-eighteen .tab-content {
  max-width: 1450px;
  margin: 0 auto;
  background: transparent;
  min-height: 490px;
}
.fancy-feature-eighteen .tab-content .text-wrapper {
  padding: 40px 80px;
}
.fancy-feature-eighteen .tab-content .text-wrapper h4 {
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 40px;
}
.fancy-feature-eighteen .tab-content .text-wrapper p {
  font-size: 24px;
  line-height: 1.83em;
  color: #000;
}
.fancy-feature-eighteen .shape-right {
  bottom: 4%;
  right: 0;
  width: 13.35%;
  animation: fade-in 1.5s infinite linear;
}
.fancy-feature-eighteen .shape-left {
  bottom: 8%;
  left: 0;
  width: 13%;
  animation: fade-in-rev 1.5s infinite linear;
}
.fancy-feature-eighteen .tab-content .img-holder {
  position: relative;
}
.fancy-feature-eighteen .tab-content .img-holder .ripple-box {
  width: 385px;
  height: 160px;
  border: 3px solid #fff;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fancy-feature-eighteen .tab-content .img-holder .ripple-box:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 40px;
  background: #fff;
  top: -40px;
  left: -3px;
}
.fancy-feature-eighteen .tab-content .img-holder .ripple-box:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background: #fff;
  top: -3px;
  left: -40px;
}
/*----------------- Fancy Feature Nineteen -------------------*/
.block-style-nineteen {
  background: #fff;
  border-radius: 10px;
  padding: 37px 50px;
  margin-top: 35px;
  border-left-width: 5px;
  border-left-style: solid;
  box-shadow: 0 20px 60px rgba(10, 30, 81, 0.04);
}
.block-style-nineteen .text {
  padding-left: 30px;
}
.block-style-nineteen .text h4 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 17px;
}
.block-style-nineteen .text p {
  font-size: 16px;
  line-height: 1.875em;
}
/*----------------- Fancy Feature Twenty -------------------*/
.block-style-twenty .text-wrapper h6 {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 35px;
  color: rgba(0, 0, 0, 0.63);
}
.block-style-twenty .text-wrapper .title {
  font-size: 60px;
  line-height: 1.2em;
  font-weight: 700;
}
.block-style-twenty .text-wrapper p {
  font-size: 20px;
  line-height: 1.8em;
  color: #4f4f4f;
  padding: 44px 0 44px;
}
.block-style-twenty .text-wrapper .video-button {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  color: #6b6df6;
  cursor: pointer;
}
.block-style-twenty .text-wrapper .video-button .fa {
  display: inline-block;
  font-size: 26px;
  margin-right: 15px;
}
.block-style-twenty .text-wrapper .video-button:hover span {
  text-decoration: underline;
}
/*----------------- Fancy Feature Twenty One -------------------*/
.fancy-feature-twentyOne {
  background: #fcf6ee;
  padding: 140px 0;
}
.block-style-twentyOne {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  margin-top: 35px;
  transition: all 0.3s ease-in-out;
}
.block-style-twentyOne:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(147, 134, 118, 0.2);
}
.block-style-twentyOne .video-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 15px;
  color: #fff;
  font-size: 24px;
  padding: 15px 20px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.block-style-twentyOne .video-button .icon {
  width: 38px;
  height: 38px;
  background: #fff;
  border-radius: 50%;
  margin-top: 15px;
  cursor: pointer;
}
.block-style-twentyOne:hover .video-button {
  opacity: 1;
}
/*----------------- Fancy Short Banner Nine ---------------*/
.fancy-short-banner-nine {
  background: #4b0e55;
  padding: 110px 0;
  position: relative;
  z-index: 1;
}
.fancy-short-banner-nine .title-style-seven h2,
.fancy-short-banner-nine .title-style-seven p {
  color: #fff;
}
.fancy-short-banner-nine .download-btn {
  width: 250px;
  margin: 50px auto 0;
}
.fancy-short-banner-nine .download-btn button {
  width: 100%;
  line-height: 65px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: #fff;
  text-align: center;
}
.fancy-short-banner-nine .download-btn button:after {
  content: url(../images/icon/89.svg);
  border: none;
  position: absolute;
  right: 30px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}
.fancy-short-banner-nine .download-btn.show button:after {
  transform: rotate(180deg);
}
.fancy-short-banner-nine .download-btn .dropdown-menu {
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
  padding: 5px 0 0px;
}
.fancy-short-banner-nine .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  background: transparent;
}

.fancy-short-banner-nine .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
.fancy-short-banner-nine .shape-one {
  bottom: 0;
  right: 0;
  width: 18%;
}
.fancy-short-banner-nine .shape-two {
  bottom: 0;
  left: 0;
  width: 17.4%;
}

/*----------------- Coming Soon ---------------*/
.full-height-layout {
  min-height: 100vh;
  padding: 40px 15px;
  position: relative;
  z-index: 5;
}
.coming-soon-content {
  max-width: 860px;
  margin: 0 auto;
  text-align: center;
}
.coming-soon-content h6 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: rgba(0, 0, 0, 0.63);
}
.coming-soon-content h1 {
  font-size: 72px;
  line-height: 1.18em;
  font-weight: 700;
  color: #000;
  padding: 35px 0 60px;
}
.coming-soon-content p {
  font-size: 24px;
  line-height: 1.79em;
  color: #000;
  padding-bottom: 55px;
}
.coming-soon-content form {
  max-width: 670px;
  height: 75px;
  position: relative;
  margin: 0 auto;
}
.coming-soon-content form input {
  display: block;
  width: 100%;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 0 220px 0 20px;
  height: 100%;
}
.coming-soon-content form button {
  width: 200px;
  background: #151515;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
  bottom: 6px;
  transition: all 0.3s ease-in-out;
}
.coming-soon-content form button:hover {
  background: var(--blue-dark);
}
.coming-soon-content .social-icon a {
  width: 34px;
  height: 34px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  color: #333333;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
}
.coming-soon-content .social-icon a:hover {
  background: #232323;
  color: #fff;
}
.coming-soon-content .social-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}
.coming-soon-content .shape-one {
  left: 0;
  top: 5%;
  animation: fade-in-rev 1.5s infinite linear;
}
.coming-soon-content .shape-two {
  left: 20%;
  top: 9%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-three {
  left: 11%;
  top: 35%;
  animation: jumpTwo 3.5s infinite linear;
}
.coming-soon-content .shape-four {
  left: 10%;
  top: 70%;
  animation: fade-in 1.5s infinite linear;
}
.coming-soon-content .shape-five {
  left: 22%;
  top: 90%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-six {
  right: 22%;
  top: 9%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-seven {
  right: 12%;
  top: 28%;
  animation: fade-in-rev 1.5s infinite linear;
}
.coming-soon-content .shape-eight {
  right: 12%;
  top: 60%;
  animation: jumpTwo 3.5s infinite linear;
}
.coming-soon-content .shape-nine {
  right: 25%;
  top: 85%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-ten {
  right: 5%;
  bottom: 0;
  animation: fade-in 1.5s infinite linear;
}

/*=======================================================================
                              Product landing / Schedule Appointment           
=========================================================================*/
/*-------------------- Menu ---------------*/
.theme-menu-six .navbar-nav .nav-item .nav-link {
  font-size: 20px;
  margin: 0 25px;
  color: #fff;
}
.theme-menu-six .navbar-nav .nav-item .nav-link.active {
  text-decoration: underline;
}
.theme-menu-six .right-widget .signIn-action {
  font-size: 20px;
  line-height: 46px;
  color: #fff;
  margin: 0 40px 0 100px;
  transition: all 0.3s ease-in-out;
}
.theme-menu-six .right-widget .signIn-action:hover {
  text-decoration: underline;
}
.theme-menu-six .right-widget .signIn-action img {
  margin-right: 12px;
  margin-top: -3px;
}
.theme-menu-six .right-widget .signup-btn {
  width: 142px;
  line-height: 50px;
  font-size: 16px;
  background: #fff;
  border-radius: 30px;
  color: #000;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.theme-menu-six .right-widget .signup-btn:hover {
  background: #c751ff;
  color: #fff;
}
.theme-menu-six.fixed .navbar-nav .nav-item .nav-link,
.theme-menu-six.fixed .right-widget .signIn-action {
  color: #000;
}
.theme-menu-six.fixed .right-widget .signup-btn {
  background: #572ff6;
  color: #fff;
}
/*---------------------Theme Hero Banner/Nine ---------------*/
.hero-banner-nine {
  position: relative;
  z-index: 1;
  padding-top: 270px;
}
.hero-banner-nine .hero-heading {
  font-weight: 700;
  font-size: 72px;
  line-height: 1.25em;
}
.hero-banner-nine .hero-heading span {
  display: inline-block;
  position: relative;
}
.hero-banner-nine .hero-heading span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.hero-banner-nine .hero-sub-heading {
  font-size: 24px;
  line-height: 1.5em;
  color: #2f2f2f;
  padding: 36px 0 58px 0;
}
.hero-banner-nine form {
  max-width: 550px;
  height: 70px;
  position: relative;
}
.hero-banner-nine form input {
  border: none;
  background: #f4f4f4;
  border-radius: 54px;
  width: calc(100% - 100px);
  height: 100%;
  font-size: 16px;
  padding: 0 50px;
}
.hero-banner-nine form button {
  position: absolute;
  right: 0;
  top: 0;
  background: #572ff6;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 17px;
  transition: all 0.3s ease-in-out;
}
.hero-banner-nine form button:hover {
  background: #ff4a8b;
}
.hero-banner-nine form ::-webkit-input-placeholder {
  /* Edge */
  color: #0b0b0b;
}
.hero-banner-nine form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0b0b0b;
}
.hero-banner-nine form ::placeholder {
  color: #0b0b0b;
}
.hero-banner-nine .term-text {
  font-size: 16px;
  color: #a5a5a5;
  margin-top: 22px;
}
.hero-banner-nine .term-text a {
  color: #572ff6;
}
.hero-banner-nine .bg-shape {
  top: 0;
  right: 0;
}
.hero-banner-nine .illustration-container {
  position: absolute;
  right: 0;
  top: 17%;
  z-index: 1;
  width: 44%;
}
.hero-banner-nine .illustration-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 70px;
  right: -30px;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  z-index: -1;
}
.hero-banner-nine .illustration-container:after {
  content: "";
  position: absolute;
  top: -25px;
  left: 100px;
  right: -30px;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  z-index: -2;
}
.hero-banner-nine .img-meta.justify-content-center {
  justify-content: start !important;
}
/*----------------- Fancy Feature Twenty Two -------------------*/
.block-style-twentyTwo {
  text-align: center;
  background: #fff;
  padding: 50px 35px;
  margin-top: 45px;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  @media screen and (max-width: 991px) {
    box-shadow: 0px 30px 80px rgba(0, 57, 110, 0.05);
  }
}
.block-style-twentyTwo:hover {
  box-shadow: 0px 30px 80px rgba(0, 57, 110, 0.05);
}
.block-style-twentyTwo .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
}
.block-style-twentyTwo h4 {
  font-size: 24px;
  font-weight: 500;
  padding: 26px 0;
}
.block-style-twentyTwo .arrow-icon {
  margin-top: 30px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 991px) {
    opacity: 1;
  }
}
.block-style-twentyTwo:hover .arrow-icon {
  opacity: 1;
}
/*----------------- Fancy Feature Twenty Three -------------------*/
.block-style-twentyThree {
  margin-bottom: 150px;
}
.block-style-twentyThree .text-wrapper h6 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8a8a8a;
}
.block-style-twentyThree .text-wrapper .title {
  font-size: 42px;
  line-height: 1.3em;
  font-weight: 700;
  padding: 28px 0 24px;
}
.block-style-twentyThree .text-wrapper p {
  font-size: 20px;
  line-height: 1.85em;
}
.block-style-twentyThree .screen-container {
  position: relative;
  max-width: 508px;
  height: 415px;
}
.block-style-twentyThree .screen-container .oval-shape {
  width: 415px;
  height: 415px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.block-style-twentyThree .screen-container .oval-shape:nth-child(2) {
  left: auto;
  right: 0;
  z-index: -1;
}
.block-style-twentyThree .screen-container .oval-shape:nth-child(1) {
  mix-blend-mode: multiply;
  transform: rotate(30deg);
}
.block-style-twentyThree .screen-container .shape-one {
  z-index: 2;
  left: -15%;
  bottom: -18px;
  animation: jumpTwo 5s infinite linear;
}
.block-style-twentyThree .screen-container .shape-two {
  z-index: 2;
  right: -17%;
  bottom: -28px;
  animation: jumpThree 5s infinite linear;
}
.block-style-twentyThree .screen-container .shape-three {
  z-index: 2;
  left: -25%;
  bottom: -21%;
  max-width: 583px;
  animation: jumpTwo 5s infinite linear;
}
/*----------------- Fancy Feature Twenty Four -------------------*/
.fancy-feature-twentyFour .bg-wrapper {
  background: #f5fbff;
  position: relative;
  z-index: 1;
  padding: 170px 0 130px;
}
.fancy-feature-twentyFour .click-scroll-button {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  top: -25px;
  left: 50%;
  margin-left: -26px;
}
.fancy-feature-twentyFour .click-scroll-button img {
  width: 20px;
}
.fancy-feature-twentyFour .shape-one {
  top: -25px;
  right: 25%;
  animation: rotated 12s infinite linear;
}
.fancy-feature-twentyFour .shape-two {
  top: 47%;
  right: 8%;
  animation: rotated 12s infinite linear;
}
.fancy-feature-twentyFour .shape-three {
  bottom: -20px;
  right: 13%;
  animation: jumpTwo 3s infinite linear;
}
.fancy-feature-twentyFour .shape-four {
  bottom: 31%;
  left: 10%;
  animation: rotated 12s infinite linear;
}
.fancy-feature-twentyFour .shape-five {
  top: 13%;
  left: 10%;
  animation: jumpTwo 3s infinite linear;
}
.block-style-twentyFour {
  background: #fff;
  padding: 40px 43px 50px;
  box-shadow: 0 4px 0 #e9f3fa;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.block-style-twentyFour .icon {
  width: 70px;
  height: 70px;
  border-radius: 20px;
}
.block-style-twentyFour .text {
  width: calc(100% - 70px);
  padding-left: 32px;
}
.block-style-twentyFour .text h4 {
  font-size: 22px;
  font-weight: 500;
}
.block-style-twentyFour .text p {
  font-size: 16px;
  line-height: 28px;
  padding-top: 15px;
}
/*----------------- Partner Section One -------------------*/
.partner-section-one {
  position: relative;
  z-index: 1;
}
.partner-section-one .img-box a {
  display: block;
  height: 100%;
}
.partner-section-one .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.partner-section-one .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15px 30px 50px rgba(26, 34, 72, 0.08);
  width: 175px;
  height: 175px;
  margin: 0 auto 35px;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}
.partner-section-one.bg-transparent .img-box {
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}
.partner-section-one .img-box:hover {
  transform: scale(1.1);
}
.partner-section-one .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
}
.partner-section-one .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
}
.partner-section-one .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
}
.partner-section-one .img-box.bx-g {
  width: 197px;
  height: 197px;
  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}
.partner-section-one .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
}

.partner-section-one .shape-one {
  top: 9%;
  right: 14%;
  animation: rotated 12s infinite linear;
}
.partner-section-one .shape-two {
  top: 44%;
  right: 9%;
  animation: rotatedTwo 12s infinite linear;
}
.partner-section-one .shape-three {
  bottom: 19%;
  right: 12%;
  animation: jumpTwo 3s infinite linear;
}
.partner-section-one .shape-four {
  bottom: 0;
  right: 36%;
  animation: rotated 12s infinite linear;
}
.partner-section-one .shape-five {
  bottom: 0;
  left: 36%;
  animation: rotatedTwo 12s infinite linear;
}
.partner-section-one .shape-six {
  bottom: 17%;
  left: 18%;
  animation: rotated 12s infinite linear;
}
.partner-section-one .shape-seven {
  top: 43%;
  left: 9%;
  animation: jumpTwo 3s infinite linear;
}
.partner-section-one .shape-eight {
  top: 10%;
  left: 19%;
  animation: rotatedTwo 12s infinite linear;
}
/*----------------- Fancy Short Banner Ten -------------------*/
.fancy-short-banner-ten {
  background: #f5fbff;
  padding: 110px 0 110px;
  position: relative;
  z-index: 1;
}
.fancy-short-banner-ten h2 {
  font-size: 60px;
  font-weight: 700;
}
.fancy-short-banner-ten p {
  font-size: 32px;
  line-height: 1.53em;
  color: #040404;
  padding: 30px 0 62px;
}
.fancy-short-banner-ten .download-btn {
  width: 300px;
  margin: 0 auto;
}
.fancy-short-banner-ten .download-btn button {
  width: 100%;
  line-height: 65px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  text-align: center;
}
.fancy-short-banner-ten .download-btn button:after {
  content: url(../images/icon/89.svg);
  border: none;
  position: absolute;
  right: 30px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}
.fancy-short-banner-ten .download-btn.show button:after {
  transform: rotate(180deg);
}
.fancy-short-banner-ten .download-btn .dropdown-menu {
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: 0;
  padding: 5px 0 0px;
}
.fancy-short-banner-ten .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  background: transparent;
}

.fancy-short-banner-ten .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
.fancy-short-banner-ten .info-text {
  text-align: center;
  font-size: 15px;
  color: #6b6b6b;
  padding-top: 15px;
}
.fancy-short-banner-ten .shape-one {
  bottom: 0;
  right: 0;
  width: 16.8%;
}
.fancy-short-banner-ten .shape-two {
  top: 0;
  left: 0;
  width: 16.25%;
}
/*-------------------- Footer Style Eight --------------------*/
.theme-footer-eight .footer-about-widget ul li a {
  font-size: 18px;
  color: #343434;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
}
.theme-footer-eight .footer-about-widget ul li a.phone {
  font-size: 20px;
}
.theme-footer-eight .footer-about-widget ul li a:hover {
  text-decoration: underline;
}
.theme-footer-eight .footer-title {
  font-size: 24px;
  font-weight: 500;
  color: #0e0e0e;
  position: relative;
  margin: 5px 0 21px;
}
.theme-footer-eight .footer-list ul li a {
  color: #343434;
  line-height: 40px;
  transition: all 0.2s ease-in-out;
}
.theme-footer-eight .footer-list ul li a:hover {
  text-decoration: underline;
}
.theme-footer-eight .top-footer [class*="col-"] {
  margin-bottom: 35px;
}
.theme-footer-eight .bottom-footer p {
  font-size: 16px;
  color: #373737;
}
.theme-footer-eight .bottom-footer .social-icon a {
  font-size: 20px;
  margin-left: 15px;
  color: #323232;
}
/*----------------- Team Section Five --------------*/
.team-section-five {
  background: #f2fbfd;
  padding: 180px 0 150px;
}
/*----------------- Fancy Short Banner Eleven ---------------*/
.fancy-short-banner-eleven {
  position: relative;
  z-index: 1;
  text-align: center;
}
.fancy-short-banner-eleven h2 {
  font-size: 68px;
  line-height: 1.23em;
}
.fancy-short-banner-eleven.bg-color {
  padding: 95px 0 130px;
  background: linear-gradient(45deg, #fffbf2 0%, #edfffd 100%);
}
.fancy-short-banner-eleven [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-short-banner-eleven .bubble-one {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  top: -8px;
  right: 15%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-short-banner-eleven .bubble-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  bottom: 26%;
  right: 26%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-eleven .bubble-three {
  width: 25px;
  height: 25px;
  background: #b183ff;
  bottom: -12px;
  left: 12%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-short-banner-eleven .bubble-four {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  top: 32%;
  left: 14%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-short-banner-eleven .shape-one {
  left: 0;
  top: -127px;
  animation: jumpTwo 4s infinite linear;
}
.fancy-short-banner-eleven .shape-two {
  right: 0;
  bottom: -127px;
  animation: jumpThree 4s infinite linear;
}
/*----------------- Team Details ---------------*/
.team-details {
  background: #f2fbfd;
  padding: 160px 0;
  position: relative;
  z-index: 1;
}
.team-details .main-bg {
  background: #fff;
  box-shadow: 0px 40px 80px rgba(0, 29, 56, 0.03);
}
.team-details .main-bg .img-meta {
  width: 48%;
}
.team-details .main-bg .text-wrapper {
  width: 52%;
  padding: 40px 50px 40px 115px;
}
.team-details .main-bg .text-wrapper .name {
  font-size: 33px;
  color: #000;
}
.team-details .main-bg .text-wrapper .position {
  color: rgba(0, 0, 0, 0.63);
  padding: 10px 0 50px;
}
.team-details .main-bg .text-wrapper h6 {
  font-size: 18px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.team-details .main-bg .text-wrapper .social-icon a {
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 1;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}
.team-details .main-bg .text-wrapper .social-icon a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.team-details .main-bg .text-wrapper .social-icon a:hover {
  color: #fff;
  transform: translateY(-5px);
}
.team-details .main-bg .text-wrapper .social-icon a:hover:before {
  opacity: 1;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(1) a:before {
  background: #6f55ff;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(2) a:before {
  background: #1da1f2;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(3) a:before {
  background: #ea4c89;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(4) a:before {
  background: #d44638;
}
.team-details .shape-one {
  left: 0;
  top: -127px;
  animation: jumpTwo 4s infinite linear;
}
.team-details .shape-two {
  right: 0;
  bottom: -127px;
  animation: jumpThree 4s infinite linear;
}
.team-details .pager {
  position: absolute;
  width: calc(100% + 180px);
  left: -90px;
  top: 50%;
  margin-top: -27px;
}
.team-details .pager a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  color: #000;
  font-size: 22px;
  transition: all 0.3s ease-in-out;
}
.team-details .pager a:hover {
  background: #353535;
  color: #fff;
}
.team-details .pager a:first-child i {
  display: inline-block;
  transform: rotate(180deg);
}
/*----------------- Terms and Condition ---------------*/
.terms_and_policy {
  padding: 120px 0;
  border-bottom: 1px solid #ececec;
}
.terms_and_policy .nav-tabs {
  border: none;
  background: #f2fbfd;
  padding: 30px 10px 30px 45px;
}
.terms_and_policy .nav-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}
.terms_and_policy .nav-item .nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 60px;
  padding: 0;
  background: transparent;
  border: none;
  color: #222;
  transition: all 0.2s ease-in-out;
}
.terms_and_policy .nav-item .nav-link.active {
  color: var(--blue-dark);
}
.terms_and_policy .tab-content {
  padding: 0 0 0 50px;
}
.terms_and_policy .tab-content h2 {
  font-size: 55px;
  padding-bottom: 10px;
}
.terms_and_policy .tab-content .update-date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
}
.terms_and_policy .tab-content h3 {
  font-family: "gilroy-semibold";
  font-size: 28px;
  padding: 50px 0 25px;
}
.terms_and_policy .tab-content p {
  line-height: 34px;
  margin-bottom: 15px;
}
.terms_and_policy .tab-content p a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul {
  list-style-type: disc;
  padding-left: 18px;
}
.terms_and_policy .tab-content ul li {
  color: #000;
}
.terms_and_policy .tab-content ul li a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul li {
  padding-bottom: 10px;
}
/*----------------- 404 ---------------*/
.error-page {
  min-height: 100vh;
}
.error-page .img-holder {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.error-page .img-holder .illustration {
  min-height: 100vh;
  object-fit: cover;
  object-position: top center;
}
.error-page .img-holder .qus {
  top: 34%;
  left: 15%;
  width: 13%;
  z-index: 1;
  animation: fade-in 1s infinite linear;
}
.error-page .text-wrapper {
  width: 50%;
  padding: 50px 20px 50px 5%;
}
.error-page .text-wrapper h1 {
  font-size: 82px;
  line-height: 1.21em;
}
.error-page .text-wrapper p {
  font-size: 24px;
  line-height: 1.66em;
  font-weight: 300;
  color: #000;
  padding: 55px 15% 60px 0;
}
.error-page .text-wrapper .back-home {
  font-size: 17px;
  text-align: center;
  line-height: 55px;
  width: 200px;
  transition: all 0.3s ease-in-out;
  background: #000;
  padding: 0 20px;
  color: #fff;
}
.error-page .text-wrapper .back-home img {
  margin-left: 12px;
}
.error-page .text-wrapper .back-home:hover {
  background: var(--purple-blue);
  color: #fff;
}
.error-page .text-wrapper .logo {
  position: absolute;
  top: 50px;
  left: 5%;
}

.bg-2 {
  background: #f2fbfd;
}

/*=======================================================================
                              Product landing / Mobile App           
=========================================================================*/
/*---------------------Theme Hero Banner/Ten ---------------*/
.hero-banner-ten {
  position: relative;
  z-index: 1;
  margin: 70px 0 0;
  text-align: center;
}
.hero-banner-ten .hero-heading {
  font-size: 82px;
  line-height: 1.21em;
  font-weight: 500;
}
.hero-banner-ten .hero-sub-heading {
  font-size: 24px;
  padding: 45px 0 55px;
  color: #000;
}
.hero-banner-ten .button-group a {
  width: 200px;
  height: 60px;
  padding: 0 5px 0 25px;
  margin: 10px 12px;
  background: #f0f0f0;
  border-radius: 6px;
  color: #000;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.hero-banner-ten .button-group a:hover {
  transform: translateY(-5px);
  box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.1);
}
.hero-banner-ten .button-group a .icon {
  margin-right: 15px;
}
.hero-banner-ten .button-group a span {
  font-size: 11px;
  color: #737373;
  display: block;
  margin-bottom: -11px;
  margin-top: -5px;
}
.hero-banner-ten .button-group a strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}
.hero-banner-ten .button-group a.ios-button {
  background: #303030;
  color: #fff;
}
.hero-banner-ten .button-group a.ios-button span {
  color: rgba(255, 255, 255, 0.7);
}
.hero-banner-ten [class*="icon-box"] {
  position: absolute;
  z-index: -1;
  border-radius: 10px;
}
.hero-banner-ten [class*="icon-box"] img {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.hero-banner-ten .icon-box-one {
  width: 60px;
  height: 60px;
  background: #fff7db;
  top: -7%;
  left: 14%;
  animation: jumpTwo 4.5s infinite linear;
}
.hero-banner-ten .icon-box-two {
  width: 85px;
  height: 85px;
  background: #e7ffe9;
  top: 28%;
  left: 4%;
  animation: jumpTwo 4s infinite linear;
}
.hero-banner-ten .icon-box-three {
  width: 70px;
  height: 70px;
  background: #e8f7ff;
  top: 48%;
  left: 18%;
  animation: jumpThree 4.5s infinite linear;
}
.hero-banner-ten .icon-box-four {
  width: 70px;
  height: 70px;
  background: #f8e9ff;
  bottom: -10%;
  left: 9%;
  animation: jumpThree 4s infinite linear;
}
.hero-banner-ten .icon-box-five {
  width: 60px;
  height: 60px;
  background: #eaf0ff;
  top: -7%;
  right: 13%;
  animation: jumpTwo 4.5s infinite linear;
}
.hero-banner-ten .icon-box-six {
  width: 90px;
  height: 90px;
  background: #e1fff3;
  top: 29%;
  right: 4%;
  animation: jumpTwo 4s infinite linear;
}
.hero-banner-ten .icon-box-seven {
  width: 65px;
  height: 65px;
  background: #fff8ea;
  top: 48%;
  right: 18%;
  animation: jumpThree 4.5s infinite linear;
}
.hero-banner-ten .icon-box-eight {
  width: 75px;
  height: 75px;
  background: #ffebef;
  bottom: -12%;
  right: 10%;
  animation: jumpThree 4s infinite linear;
}
/*--------------------- App Screen Preview ---------------*/
.app-screen-preview-one {
  position: relative;
  z-index: 1;
  margin-top: 200px;
}
.app-preview-slider-one .img-holder img {
  transform: scale(0.8);
  margin: 0 auto;
  border-radius: 40px;
  transition: all 0.3s ease-in-out;
}
.app-preview-slider-one .slick-center .img-holder img {
  transform: scale(1);
}
.app-screen-preview-one .round-bg {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.app-preview-slider-one {
  margin: 0 -55px;
}
.app-screen-preview-one .shape-one {
  top: 9%;
  right: 9%;
}
.app-screen-preview-one .shape-two {
  bottom: 37%;
  right: 9%;
}
.app-screen-preview-one .shape-three {
  bottom: 10%;
  left: 9%;
}
.app-screen-preview-one .shape-four {
  top: 42%;
  left: 9%;
}
/*----------------- Fancy Feature Twenty Five -------------------*/
.block-style-twentyFive .text-wrapper h6 {
  font-size: 24px;
  color: #bababa;
  padding-bottom: 30px;
  @media (max-width: 991px) {
    font-size: 18px;
    padding-bottom: 15px;
  }
}
.block-style-twentyFive .text-wrapper span {
  text-decoration: underline;
}
.block-style-twentyFive .title {
  font-size: 58px;
  line-height: 1.29em;
  font-weight: 500;
  padding-bottom: 38px;
  @media (max-width: 991px) {
    font-size: 36px;
    padding-bottom: 25px;
  }
}
.block-style-twentyFive p {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 1.65em;
  }
}
.block-style-twentyFive ul li {
  line-height: 42px;
  color: #000;
  position: relative;
  padding-left: 30px;
}
.block-style-twentyFive ul li:before {
  content: url(../images/icon/76.svg);
  position: absolute;
  top: 0;
  left: 0;
}
.block-style-twentyFive ul {
  padding-top: 50px;
}
.block-style-twentyFive .screen-container {
  position: relative;
  display: inline-block;
}
.block-style-twentyFive .screen-container .block-content {
  position: absolute;
  top: 50%;
  left: -70px;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
}
.block-style-twentyFive .screen-container .feature-meta {
  padding: 75px 50px 65px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(6, 24, 67, 0.05);
  border-radius: 10px;
  text-align: left;
  margin: 40px 0;
  @media screen and (max-width) {
    box-shadow: 0px 20px 60px rgba(6, 24, 67, 0.05);
  }
}
.block-style-twentyFive .screen-container .feature-meta .icon {
  height: 42px;
}
.block-style-twentyFive .screen-container .feature-meta h4 {
  font-size: 26px;
  line-height: 1.35em;
  font-weight: 500;
  padding-top: 35px;
}
/*--------------------- App Screen Preview ---------------*/
.app-preview-slider-two {
  max-width: 1830px;
  padding: 0 15px;
  margin: 0 auto;
}
.app-preview-slider-two .img-holder img {
  transform: scale(0.8);
  border-radius: 20px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}
.app-preview-slider-two .slick-center .img-holder img {
  transform: scale(1);
}
.app-screen-preview-one .round-bg {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*--------------------- Pricing Section Seven ------------------*/
.pricing-table-area-seven .pr-table-wrapper {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 3px 4px rgba(8, 20, 32, 0.04);
  border-radius: 15px;
  position: relative;
  padding: 60px 15px 50px 58px;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-seven .pr-table-wrapper .icon {
  height: 25px;
}
.pricing-table-area-seven .pr-table-wrapper .pack-name {
  font-size: 32px;
  font-weight: 500;
  color: #000;
  padding: 37px 0 30px;
}
.pricing-table-area-seven .pr-table-wrapper .pr-feature li {
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 26px;
  position: relative;
}
.pricing-table-area-seven .pr-table-wrapper .pr-feature li:before {
  content: url(../images/icon/139.svg);
  position: absolute;
  left: 0;
  top: 0;
}
.pricing-table-area-seven .pr-table-wrapper .price {
  color: #000;
  font-size: 28px;
  padding: 50px 0 3px;
}
.pricing-table-area-seven .pr-table-wrapper .price span {
  font-size: 18px;
}
.pricing-table-area-seven .pr-table-wrapper .trial-text {
  color: rgba(0, 0, 0, 0.54);
}
.pricing-table-area-seven .pr-table-wrapper .trial-button {
  width: 154px;
  line-height: 43px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  margin-top: 28px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-seven .pr-table-wrapper .trial-button:hover,
.pricing-table-area-seven .pr-table-wrapper.active .trial-button {
  background: #373737;
  color: #fff;
}
.pricing-table-area-seven .pr-table-wrapper.active,
.pricing-table-area-seven .pr-table-wrapper:hover {
  box-shadow: 0px 25px 60px rgba(0, 13, 48, 0.0480786);
  border-radius: 16px;
  border-color: #fff;
}
/*-------------- Client Feedback Slider Seven ----------------*/
.client-feedback-slider-seven {
  position: relative;
  padding: 180px 0;
  z-index: 1;
}
.client-feedback-slider-seven .bg-image {
  position: absolute;
  max-height: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  object-position: top center;
  z-index: -1;
}
.client-feedback-slider-seven .clientSliderFive p {
  color: #4f4f4f;
}
.client-feedback-slider-seven .clientSliderFive .name {
  color: #000;
}
.client-feedback-slider-seven .slider-arrow li {
  width: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin: 0 7px;
  font-size: 26px;
  color: #5d5d5d;
  transition: all 0.3s ease-in-out;
  transform: scale(0.6);
}
.client-feedback-slider-seven .slider-arrow li:first-child i {
  transform: rotate(180deg);
  display: inline-block;
}
.client-feedback-slider-seven .slider-arrow li:hover {
  opacity: 1;
  transform: scale(1);
}
/*----------------- Fancy Short Banner Twelve ---------------*/
.fancy-short-banner-twelve {
  background: #fff4e9;
  padding: 125px 0 115px;
  position: relative;
  z-index: 1;
}
.fancy-short-banner-twelve .button-group a {
  width: 200px;
  height: 60px;
  padding: 0 5px 0 25px;
  margin: 10px 12px;
  background: transparent;
  border-radius: 6px;
  border: 2px solid #000;
  color: #000;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-twelve .button-group a:hover {
  transform: translateY(-5px);
  box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.05);
}
.fancy-short-banner-twelve .button-group a .icon {
  margin-right: 15px;
}
.fancy-short-banner-twelve .button-group a span {
  font-size: 11px;
  color: #737373;
  display: block;
  margin-bottom: -11px;
  margin-top: -5px;
}
.fancy-short-banner-twelve .button-group a strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}
.fancy-short-banner-twelve .button-group a.ios-button {
  background: #fff;
  border-color: #fff;
}
.fancy-short-banner-twelve .button-group a.ios-button span {
  color: #808080;
}
.fancy-short-banner-twelve .shape-one {
  bottom: 0;
  right: 0;
  height: 100%;
}
.fancy-short-banner-twelve .shape-two {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
